@import 'spinner/index';


.c-btn {
    @include kare-button-1-text();
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    padding: 0 $spacing-xs;
    transition: $global-transition;
    border-radius: 4px;
    outline: 0;
    appearance: none;
    border-color: transparent;
    user-select: none;
    text-decoration: none;
    height: 32px;

    span {
        display: flex;
    }

    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        background-color: $color-black-300;
    }

    svg {
        flex-shrink: 0;
    }

    svg.c-btn__icon-left {
        margin-right: $spacing-xs;
    }

    svg.c-btn__icon-right {
        margin-left: $spacing-xs;
    }
    color: $color-text-light;
    background-color: $color-primary;
    &:hover,
    &:active {
        color: $color-text-light;
        text-decoration: none;
    }
    &:hover {
        background-color: $color-purple-600;
    }
    &:active {
        box-shadow: 0 0 0 3px rgba(108,61,255,0.5);
    }
    &.c-btn--secondary {
        color: $color-purple-500;
        background-color: transparent;
        &:hover {
            background-color: $color-purple-100;
        }
        &:active {
            border: 4px solid $color-purple-100;
        }
    }

    &.c-btn--show-spinner {
        padding-right: $spacing-xs;
    }
    &.c-btn--outlined {
        color: $color-purple-500;
        border: 1px solid $color-purple-500;
        background-color: transparent;
        &:hover,
        &:active {
            background-color: $color-purple-100;
            border-color: $color-purple-600;
            color: $color-purple-600;
        }
        &.c-btn--secondary {
            color: $color-secondary;
            border-color: $color-secondary;
            &:hover,
            &:active {
                background-color: $color-purple-100;
                border-color: $color-secondary-light;
                color: $color-secondary-light;
            }
        }
    }
    &.c-btn--outlined-primary {
        color: $color-primary;
        border-color: $color-primary;
        background-color: transparent;
        &:hover,
        &:active {
            background-color: transparent;
            border-color: $color-primary-dark;
            color: $color-primary-dark;
        }
        &.disabled:hover,
        &.disabled:active,
        &.disabled {
            border-color: $color-black-300;
            color: $color-black-300;
        }
    }

    &.c-btn--inverted {
        background-color: transparent;
        color: $color-purple-600;
        margin-right: $spacing-s;

        &:hover,
        &:active {
            background-color: $color-purple-100;
        }
    }

    &.c-btn--secondary-text {
        color: $color-secondary;
        &:hover,
        &:active {
            color: $color-secondary-light;
        }
    }
    &.c-btn--no-padding {
        padding: 0;
    }
}
