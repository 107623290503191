
.response-viewer {
    display: flex;
    flex-flow: column;
    .response-viewer__footer {
        @include kare-button-2-text();
        color: $color-secondary;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        padding: $spacing-xs $spacing-m;
        div,
        a {
            display: flex;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                background-color: $color-purple-100;
            }
        }
        svg {
            @include square($spacing-s);
            margin-right: 5px;
        }
        .response-viewer__footer-choose-another-response,
        .response-viewer__footer-clone-response {
            margin-right: $spacing-m;
        }
        .response-viewer__footer-clone-response {
            color: $color-secondary;
            svg * {
                fill: $color-secondary;
            }
        }
        .response-viewer__footer-choose-another-response, .response-viewer__footer-edit {
            padding: $spacing-xs 10px;
            color: $color-purple-600;
            border: 1px solid $color-purple-600;
            border-radius: 4px;
        }
    }
    .response-viewer__quote {
        border-left: 4px $color-grey-700 solid;
        border-radius: 2px;
        padding-left: $spacing-xs;
    }
}
