/* ==========================================================================
   # PORTAL SIDEBAR
   ========================================================================== */

.knowledge-base__portal-sidebar {
    width: 300px;
    background-color: $color-background;
    height: 100%;
    border-right: 2px solid $color-black-1000;
    display: flex;
    flex-direction: column;
}
