.kare-quote-selection {
    background: rgba($color-secondary, 0.05);
    border-left: 5px solid $color-secondary;
    border-radius: 2px 0 0 2px;
    padding: $spacing-s;
    cursor: auto;
    order: 1;
    margin: $spacing-s;
    &--empty {
        margin-bottom: 0;
        .kare-quote-selection__content,
        a {
            margin-top: 0;
        }
    }
    &__header {
        position: relative;
        @include kare-caption();
        color: $color-grey-1000;
        display: flex;
        justify-content: space-between;
        align-content: center;
        span {
            display: flex;
            align-items: center;
            svg {
                margin-right: $spacing-xxs;
            }
        }
        & > svg {
            cursor: pointer;
            position: absolute;
            &:first-of-type {
                right: 30px;
            }
            &:last-of-type {
                right: $spacing-xs;
            }
        }
    }
    &__content {
        margin-top: $spacing-xs;
        max-width: 90%;
        &__document {
            color: $color-secondary;
            display: flex;
            align-items: center;
            margin-top: $spacing-s;
            svg {
                margin-right: $spacing-xxs;
                width: 20px;
            }
        }
    }
}
