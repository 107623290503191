@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.EmptyState__container {
    padding: $spacing-l 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-black-900;
    svg {
        width: $spacing-l;
        height: $spacing-l;
    }
    h1 {
        font-size: $font-size-s;
        color: $color-black-900;
    }
}
