$circle-size: 45px;
$circle-margin: 8px;

.nps-circle-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .nps-circle-wrapper {
        width: 100%;
        height: 100%;
        margin: $circle-margin;
        flex: 0 1 calc(16.66% - (#{$circle-margin} * 2));

        .nps-circle {
            position: relative;
            border: 1px solid #263238;
            background: transparent;
            border-radius: 50%;
            width: $circle-size;
            height: $circle-size;
            max-width: $circle-size;
            max-height: $circle-size;
            transition: all 0.3s ease;
            cursor: pointer;

            span {
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-size: 14px;
                font-weight: 600;
                color: #263238;
                transition: all 0.3s ease;
            }

            &.hover {
                background: #263238;
                transition: all 0.3s ease;
                border: 1px solid transparent;

                span {
                    color: #fff;
                    transition: all 0.3s ease;
                }
            }

            &.hover.danger {
                background: $color-red-1000;
            }

            &.hover.warning {
                background: $color-yellow-1000;
            }

            &.hover.success {
                background: $color-green-1000;
            }
        }
    }
}

.kare-block-nps {
    h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        margin-bottom: 15px;
    }

    span.label {
        font-family: $global-text-font-stack;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: normal;
        text-decoration: none;
        height: 20px;
        color: #676f73;
        line-height: 35px;
    }

    .nps-labels {
        display: flex;
        justify-content: space-between;
        .negative {
            flex: 1;
        }
    }
}
