@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.ActivityBarChart__container {
    .ActivityBarChart__chart,
    .ActivityBarChart__empty {
        height: 354px;
    }

    .ActivityBarChart__tabs {
        ul {
            display: flex;
            text-align: center;
        }

        ul li {
            display: inline-flex;
            flex-direction: column;
            flex: 1;
            border-top: 3px solid transparent;
            border-left: 1px solid transparent;
            border-right: 1px solid transparent;
            border-bottom: 1px solid $color-grey-lightest;
            padding: $spacing-m 0;
            opacity: 0.55;
            cursor: pointer;

            span {
                font-size: $font-size-s;
                font-weight: bold;
                margin-bottom: $spacing-xxs;
            }

            small {
                font-size: $font-size-xxl;
                font-weight: bold;
            }

            &:hover {
                opacity: 0.75;
            }

            &.selected {
                opacity: 1;
                border-top: 3px solid $color-success;
                border-right: 1px solid $color-grey-lightest;
                border-left: 1px solid $color-grey-lightest;
                border-bottom: 1px solid transparent;
                cursor: default;
            }

            &:first-of-type.selected {
                border-left: 1px solid transparent;
            }

            &:last-of-type.selected {
                border-right: 1px solid transparent;
            }
        }
    }

    .ActivityBarChart__header {
        ul {
            margin: 0;

            li {
                display: inline-flex;
                flex-direction: column;
                padding: $spacing-m $spacing-l $spacing-s;
                color: $color-black-900;

                span {
                    font-size: $font-size-xl;

                    svg {
                        margin-right: $spacing-xs;
                        width: $spacing-s;
                        height: $spacing-s;
                    }

                    span {
                        color: $color-grey;
                        font-size: 1rem;
                    }
                }

                &:first-of-type {
                    strong {
                        margin-left: 0;
                    }
                }

                strong {
                    font-size: $font-size-xxl;
                    font-weight: bold;
                    margin-top: $spacing-xxs;
                    margin-left: 24px;

                    span {
                        font-size: $font-size-xxl;

                        &:last-of-type {
                            font-size: $font-size-m;
                            margin-left: -5px;
                        }
                    }
                }

                small {
                    font-size: 12px;
                    letter-spacing: 0;
                    margin-left: 24px;
                    color: $color-grey;
                }
            }
        }
    }
}

.ActivityBarChart__subtitle {
    display: flex;
    align-items: center;
}
