.c-spinner-centered-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
.c-spinner {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.c-spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 6px;
    border-radius: 50%;
    animation: spinning 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.c-spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.c-spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.c-spinner div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
