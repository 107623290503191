.filter-modal {
    width: 100%;
    flex-wrap: wrap;

    .filter-header {
        flex: 0 1 100%;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            color: $color-black-1000;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .filter-body {
        flex: 0 1 100%;

        .filter-item {
            border-top: 1px solid $color-black-300;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .filter-switch {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .filter-label {
                flex: 6;
                font-size: 14px;
                color: $color-black-1000;
            }

            .filter-collapse {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    color: $color-black-300;
                }
            }
        }
    }
}
