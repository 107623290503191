@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.DistributionChart__container {
    .DistributionChart__underPlot {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: -$spacing-l;
        .DistributionChart__left,
        .DistributionChart__right {
            padding: $spacing-l;
            position: relative;
            cursor: pointer;
            svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 5px;
                width: 24px;
                height: 24px;
                color: $color-grey-light;
            }
            h1 {
                font-size: $font-size-m;
                font-weight: bold;
                line-height: $spacing-xs;
            }
            p {
                font-size: $spacing-s;
            }
        }
        .DistributionChart__left {
            h1 {
                color: $color-danger;
            }
        }
        .DistributionChart__right {
            h1 {
                color: $color-warning;
            }
        }
    }
}
