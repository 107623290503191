/* ==========================================================================
   #FLEX GRID
   ========================================================================== */

$_grid-gutter: 1em !default;
$_grid-widths: (
    5: 5 / 100,
    10: 1 / 10,
    15: 15 / 100,
    20: 1 / 5,
    25: 1 / 4,
    30: 3 / 10,
    33: 1 / 3,
    35: 35 / 100,
    40: 2 / 5,
    45: 45 / 100,
    50: 1 / 2,
    55: 55 / 100,
    60: 3 / 5,
    65: 65 / 100,
    66: 2 / 3,
    70: 7 / 10,
    75: 3 / 4,
    80: 4 / 5,
    85: 85 / 100,
    90: 9 / 10,
    95: 95 / 100,
    100: 1
);

.o-grid {
    display: flex;
}

.o-grid__cell {
    flex: 1;
    padding-left: $_grid-gutter; /* [1] */
    padding-right: $_grid-gutter; /* [1] */

    @each $width, $fraction in $_grid-widths {
        &--width-#{$width} {
            flex: 0 0 $fraction * 100%;
            max-width: $fraction * 100%;
        }

        &--offset-#{$width} {
            margin-left: $fraction * 100%;
        }
    }
}
