@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.BeaconsTable__cardTitle {
    border-bottom: none;
}

.BeaconsTable__urlCell {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.BeaconsTable__title {
    display: flex;
    align-items: center;
    svg {
        width: 16px;
        height: 16px;
        margin-right: $spacing-xs;
    }
    .BeaconsTable__svgResponse {
        color: $color-black-900;
    }
}

.BeaconsTable__titleContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
}

.BeaconsTable__dropdown {
    width: 400px;
}

.BeaconsTable__brokenNode {
    color: $color-danger;
}
