@import '../../message-factory/message-globals';

.message-disambiguation__container.console-activity--out.console-activity-message-container
    + .console-activity-message-container.console-activity-message.console-activity-message--out.console-activity--out {
    margin-top: 0;
    padding-top: 8px;
}
.message-disambiguation__container {
    display: flex;
    flex-flow: column;
    margin-left: $message-margin;
    justify-content: flex-end;
    @include kare-body();
    position: relative;
    padding-right: 8px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-right: -15px;
    border-right: 4px solid $color-secondary;
    > div {
        background-color: $color-background;
        border-radius: $message-border-radius;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    }
}

.message-disambiguation__body {
    padding: $spacing-xs $spacing-s;
}

.message-disambiguation__footer {
    padding: 0 0 $spacing-xs $spacing-s;
    border-bottom-left-radius: $message-border-radius;
    border-bottom-right-radius: $message-border-radius;
    &-item {
        padding-top: $spacing-xs;
        &-title {
            margin-right: $spacing-s;
            display: flex;
        }
        &-description {
            margin-right: $spacing-s;
            color: $color-grey-1000;
            word-break: break-all;
        }
        &:first-child {
            padding-top: 0;
        }
        &--is-clickable {
            cursor: pointer;
            color: $color-secondary;
        }
        svg {
            margin-right: $spacing-xs;
            flex-shrink: 0;
        }
    }
}
