.dashboard-queries__container {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: 60px 0 40px 0;
}

.dashboard-queries__counters {
    display: flex;
    justify-content: space-between;
}
.dashboard-queries__responses,
.dashboard-queries__feedback {
    border: $border-light;
    border-radius: 2px;
    display: flex;
}
.dashboard-queries__icon {
    @include square(45px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboard-queries__counter {
    display: flex;
    align-items: center;
    color: $color-text-dark;
    height: 65px;
    width: 130px;
    border-right: $border-light;
    &:last-of-type {
        border-right: 0;
    }
}
.dashboard-queries__values {
    display: flex;
    flex-direction: column;
    strong {
        @include kare-h2();
    }
    span {
        @include kare-button-2-text();
        color: $color-black-700;
    }
}
