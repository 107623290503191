.kare-side-bar__account-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    > header {
        height: $spacing-xl + $spacing-s;
        border-bottom: $border-light;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 $spacing-s 0 $spacing-s;
        .kare-side-bar__account-menu-logo {
            margin-right: $spacing-xs;
            width: $spacing-m;
        }
        .kare-side-bar__account-menu-details {
            margin-left: $spacing-xs;
            h1 {
                @include kare-h7();
            }
            p {
                @include kare-caption();
            }
        }
    }
    > div {
        width: 100%;
    }
}
