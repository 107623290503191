@import 'zendesk-form/index';
@import 'webcrawler-list/index';

.knowledge-management {
    &__integrations {
        display: flex;
        justify-content: flex-start;
    }

    .settings-section {
        margin-right: 20px;
    }
}
