html {
    background-color: $color-background;
    height: 100%;
    font-size: 62.5%;
    @media screen and (max-width: 1000px) {
        font-size: 55% !important;
    }
}

body {
    height: 100%;
    @include kare-body();
    #app {
        height: 100%;
    }
}

h1 {
    @include kare-h1();
}
h2 {
    @include kare-h2();
}
h3 {
    @include kare-h3();
}
h4 {
    @include kare-h4();
}
h5 {
    @include kare-h5();
}
h6 {
    @include kare-h6();
}

code {
    @include kare-code();
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
    margin-bottom: $spacing-xxs;
}

a {
    color: $color-text-link;
    text-decoration: none;
    &:hover,
    &:active {
        text-decoration: underline;
        color: $color-text-link;
    }
}

section.submit-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &.submit-button-container--two-buttons {
        justify-content: space-between;
    }
}

.o-panel {
    padding: $spacing-s $spacing-s * 2;
    flex-grow: 1;
    position: relative;
    -webkit-overflow-scrolling: touch; /* [1] */
    -ms-overflow-style: none;
    max-width: 100%;
    &.flex {
        display: flex;
        flex-direction: column;
        > * {
            flex-shrink: 0; // Safari flexbox fix
        }
    }
}

li {
    margin-left: 0;
}

.u-margin-bottom {
    margin-bottom: $spacing-s;
}
.u-margin-top {
    margin-top: $spacing-s;
}

.u-margin-bottom-large {
    margin-bottom: $spacing-m;
}

.kare-single-column-container {
    width: 240px;
    margin-top: $spacing-m;
}

.u-bold {
    font-weight: 700;
}

// Quick fix for icons within links followed by a span.
// This adds a bit of spacing between the text and the icon.
// TODO(rupertrutland): Refactor this out into a separate component
// so we have better control over this.
a svg + span {
    margin-left: $spacing-s;
}

.kare-empty-state {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    flex-grow: 1;
    > * {
        margin-bottom: $spacing-xs;
    }
    h1 {
        text-align: center;
        width: 100%;
    }
    > p {
        width: 80%;
        align-self: center;
    }
    > svg,
    > img {
        align-self: center;
    }
    > .c-btn {
        margin-top: $spacing-s;
        margin-bottom: $spacing-s;
        align-self: center;
    }
    &.kare-empty-state--icon-primary svg {
        color: $color-primary;
    }
    &.kare-empty-state--icon-secondary svg {
        color: $color-secondary;
    }
}

textarea {
    width: 100%;
    max-width: 100%;
    height: $spacing-l * 3;
    border: 1px solid $color-black-900;
    resize: none;
    padding: $spacing-s;
    border-radius: 2px;
}
