

.add-block-widget {
    svg {
        cursor: pointer;
    }

    position: relative;

    .add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $spacing-m;

        > svg {
            color: $color-dataviz-green;
            cursor: pointer;
        }

        .side-line {
            background-color: $color-black-500;
            height: 1px;
            flex-grow: 2;
        }
    }

    &.add-block-widget--actions-visible {
        svg {
            color: $color-primary;
        }
    }
}

.add-block-widget-actions {
    background-color: $color-background;
    flex-direction: column;

    > div {
        min-width: 160px;
        cursor: pointer;
        height: 30px;
        padding: 0 $spacing-xs 0 0;
        display: flex;
        align-items: center;

        &:hover {
            background-color: $color-surface-dark;
        }

        > svg {
            margin-right: $spacing-xs;
            color: $color-black-700;
        }
    }

    > header {
        min-width: 160px;
        @include kare-button-2-text();
        height: $spacing-m;
        padding: 0 $spacing-xs;
        display: flex;
        align-items: center;
    }

    & > .add-block-widget-subsection ~ .add-block-widget-subsection {
        margin-top: 15px;
    }

    .add-block-widget-subsection {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-top: 5px;
        margin-bottom: 5px;
        color: #92989b;
        font-size: 10px;
        font-weight: bold;
        line-height: 14px;
    }
}

.kare-block-wrapper + .add-block-widget,
.kare-cx-block-wrapper + .add-block-widget {
    width: calc(100% - 36px);
}
