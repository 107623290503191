.settings__user-form {
    h1 {
        @include kare-h4();
        display: flex;

        > svg {
            margin-left: auto;
            cursor: pointer;
        }
    }
    .settings__user-form-remove-button {
        padding-left: $spacing-xs;
    }
}

.checkbox-field-input:disabled + label {
    color: $color-black-300;
    cursor: not-allowed;
}
