.console-activity-message small.message-answer__edit {
    font-weight: bold;
    font-size: 10px;
    svg {
        margin-right: 4px;
    }
}
.console-activity-message.message-user-choice.message-user-choice__response {
    .console-activity-message-timestamp {
        left: unset;
        right: 0;
    }
}
.activity-message-answer-container {
    margin-bottom: $spacing-s;
    overflow: hidden;

    blockquote {
        $quote-border-width: 5px;
        position: relative;
        padding-left: $quote-border-width + $spacing-xs;
        &:before {
            content: '';
            display: block;
            left: 0;
            position: absolute;
            height: 100%;
            width: $quote-border-width;
            background-color: $color-black-700;
            border-radius: 2px;
        }
    }
    span {
        max-width: 100%;
        overflow-x: auto;
        margin-bottom: $spacing-xs;
    }
    .activity-message-answer-attachment {
        clear: left;
        a {
            color: $color-purple-500;
            text-decoration: none;
            display: flex;
            align-items: center;
            font-weight: bold;
            svg {
                margin-right: $spacing-xxs;
            }
        }
    }
}
