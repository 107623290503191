/* ==========================================================================
   # RESPONSE PORTAL MAIN SECTION
   ========================================================================== */

.knowledge-base__portal-main {
    width: $knowled-base__portal-main-width;
    max-width: 100%;
    background-color: $color-background;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99999999;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 96vh;
    .c-spinner {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
