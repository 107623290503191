@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';
@import '/src/app/styles/kss/fonts';
@import '/src/app/styles/kss/mixins';
@import '/src/app/styles/kss/headers';

.c-field-tags__form-less {
    position: relative;
    .react-tags__suggestions {
        top: 40px;
    }
}

/* ==========================================================================
  # TAG FIELD
  # These styles have been copied over from react-tag-autocomplete
  # and adjusted slightly.
  # I don't want to develop too much on the styles until some feedback is received
  # as there is no design yet. - Rupert
   ========================================================================== */

$top-fields-height: 80px;

.c-field-tags {
    display: flex;
    .c-label-field__text {
        margin-right: 6px;
    }
    .react-tags {
        border: 0;
        overflow-y: auto;
    }
    &__form-less {
        max-height: 41px;
        max-width: 75%;
        .react-tags {
            border-top: 0;
            align-items: center;
        }
    }
}

.react-tags {
    flex-grow: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 6px 0 0 6px;
    border: $border-light;
    border-radius: 2px;
    /* shared font styles */
    font-size: 1em;
    line-height: 1.2;
    /* clicking anywhere will focus the input */
    cursor: text;
    &__suggestions {
        position: absolute;
        z-index: 999;
        top: $top-fields-height;
        ul {
            border-radius: 2px !important;
            li {
                $suggestion-size: 15px;
                @include kare-h7();
                @include heightLine($spacing-m);
                padding: 0 $spacing-m;
                position: relative;
                cursor: pointer;
                &:before {
                    position: absolute;
                    left: $spacing-xs;
                    top: 50%;
                    transform: translateY(-50%);
                    content: '';
                    display: block;
                    width: $suggestion-size;
                    height: $suggestion-size;
                    background-image: url('/src/static/assets/label@2x.png');
                    background-size: $suggestion-size $suggestion-size;
                }
                &.is-active,
                &:hover {
                    background-color: $color-surface-dark;
                }
                &:first-of-type {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
        width: 200px;
        max-height: 200px;
    }
}

.react-tags.is-focused {
    border-color: $color-purple-500;
}

.react-tags__selected {
    display: flex;
    flex-wrap: wrap;
}

.react-tags__selected-tag {
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: $border-light;
    border-radius: $border-radius-menu;
    background: $color-surface-light;
    white-space: nowrap;
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag:after {
    content: '\2715';
    color: $color-text-dark;
    margin-left: 8px;
    cursor: pointer;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: $color-text-dark;
}

.react-tags__search {
    display: flex;
    flex-direction: column;

    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
    min-width: 100px;
}

.react-tags__search input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    min-width: 100px;
    min-height: 15px;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-family: $global-text-font-stack;
    font-size: $font-size-xs;
    line-height: inherit;
}

.react-tags__suggestions {
    width: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
    left: 0;
    ul {
        margin: 4px -1px;
        padding: 0;
        list-style: none;
        background: $color-white-1000;
        border: $border-light;
        border-radius: 2px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        overflow: hidden;
    }
    li {
        border-bottom: $border-light;
        padding: 6px 8px 6px 0;
        @include truncateText(100%);
        &:hover {
            cursor: pointer;
            background: $color-grey-300;
        }
        &.is-active {
            background: $color-grey-300;
        }
        &.is-disabled {
            opacity: 0.5;
            cursor: auto;
        }
        mark {
            text-decoration: underline;
            background: none;
            font-weight: 600;
        }
    }
}
