.launcher-preview__custom {
    width: 100%;
    pre {
        font-weight: bold;
    }
    span {
        strong {
            font-weight: bold;
        }
    }
}
