@import 'no-responses/index';
@import 'response-portal/index';
.split-icon {
    transform: rotate(180deg);
}
.c-btn--chatter {
    justify-content: flex-start;
    border: 0;
    padding-left: 40px;
}
.knowledge-base-table__title-label {
    display: flex;
    & > span {
        align-self: center;
        margin-right: 10px;
        color: $color-black-900;
    }
    .knowledge-base-table__label-container {
    }
    .kare-label {
        cursor: pointer;
        &:hover {
            background-color: $color-grey-700;
        }
    }
}
.knowledge-base-table {
    .knowledge-base-table__dialog {
        display: none;
        color: $color-purple-500;
        font-weight: bold;
    }
    .rt-tr:hover {
        .knowledge-base-table__dialog {
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                color: $color-purple-600;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
}

.dialpad-sidebar-new-response {
    margin: 20px $spacing-m 0 $spacing-s;
}
