$kare-label-border-radius: 8px;
.kare-label {
    white-space: nowrap;
    display: inline-block;
    margin-right: $spacing-xs;
    padding: 3px $spacing-xs;
    cursor: default;
    text-align: center;
    width: auto !important;
    overflow: visible !important;
    border-radius: 2px;

    &--has-close {
        padding-right: 20px;
    }
    background-color: $color-surface-dark;
    position: relative;
    &__input {
        cursor: pointer;
        outline: 0;
        border: 0;
    }
    &__text {
        text-align: center;
        color: $color-black-1000;
        font-size: $font-size-xs;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        cursor: pointer;
        &:hover {
            fill: $color-black-700;
        }
    }
    &__select-menu {
        @include kare-box-shadow();
        background-color: $color-white-1000;
        width: 100%;
        border-radius: $kare-label-border-radius;
        position: absolute;
        left: 0;
        top: $spacing-m;
        z-index: 9;
        overflow: hidden;
    }
    &__select-item {
        padding: 0.3em;
        display: flex;
        justify-content: flex-start;
        &.highlighted {
            background-color: $color-grey-500;
        }
    }
    &__menu-icon {
        margin-right: 0.5em;
        margin-left: 0;
    }

    &__menu-text {
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }

    &.editable {
        background-color: white;
        padding: 0;
    }
}

.kare-label.kare-label-context-options {
    background-color: $color-purple-600;
    &__select-menu {
        background-color: $color-purple-600;
    }
    &.editable {
        background-color: white;
    }
    > .kare-label__text {
        color: white;
    }
    > .kare-label__icon {
        color: white;
    }
}

.kare-label-plus {
    margin-bottom: -6px;
}
