.message-rta__container {
    display: flex;
    flex-direction: column;
}

.query-rta-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    margin-top: 0.8rem;
    border-left: 0.4rem solid #160645;
    padding-left: 0.8rem;
    margin-right: auto;

    > .subtitle {
        color: $color-grey;
        font-weight: bold;
    }
}

.query-rta-message-wrapper {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.2rem;
    margin: 0;
    color: $color-white-1000;
    font-size: 1.2rem;
    line-height: 1.8rem;
    border-top: 0.4rem solid transparent;
    border-radius: 0.4rem;
    background: linear-gradient($color-purple-800 0 0) padding-box,
    linear-gradient(90deg, #F40FB9 0%, #7C52FF 100%) border-box;
}

.query-rta-message {
    margin-left: 0.8rem
}

.message-rta-wrapper {
    margin-left: 20%;
    margin-right: -1.5rem;
    border-right: 0.4rem solid #160645;

    .feedback-choice-wrapper__left {
        margin-top: -2rem;
    }
}

.message-rta {
    padding: 0.8rem 1.2rem;
    border-top: 0.4rem solid transparent;
    border-radius: 0.4rem;
    background: linear-gradient($color-white-1000 0 0) padding-box,
    linear-gradient(90deg, #F40FB9 0%, #7C52FF 100%) border-box;
    font-size: 1.2rem;
    line-height: 1.8rem;
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.18);
    margin-bottom: 0.4rem;
    margin-right: 0.8rem;

    > .markdown p {
        margin: 0;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        font-weight: bold;
        margin-bottom: 0.6rem;
    }

    &__title {
        margin-left: 0.8rem;
    }
}

.message-rta-text {
    font-family: inherit;
    margin: 0;
    font-size: inherit;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}
