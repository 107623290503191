@import '../constants';
$modal-search-height: 40px;
$modal-search-padding: 70px;
.query-response__documents-list-modal {
    padding: $padding-modal;
    &__title {
        text-align: center;
        display: flex;
        align-items: center;
        .modal-title__close-icon {
            @include square($icon-size);
            color: $color-grey-1000;
            cursor: pointer;
        }
    }

    &__body {
        overflow: auto;
        max-height: calc(
            90vh - #{$modal-search-height} - #{$modal-search-padding}
        );
        .query-response__documents-list-modal {
            display: flex;
            margin: $spacing-xs 0;
            padding: $spacing-xs;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.1s linear;
            &:hover {
                background-color: $color-grey-500;
            }
            svg {
                margin-right: $spacing-s;
            }
        }
    }
    .query-response__documents-modal-item {
        display: flex;
        margin: $spacing-xs 0;
        padding: $spacing-xs;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.1s linear;
        &:hover {
            background-color: $color-grey-500;
        }
        svg {
            margin-right: $spacing-s;
        }
    }
}
