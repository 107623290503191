.settings__application-info {
    h1 {
        @include kare-form-label();
        margin-top: 20px;
    }
    .settings__application-info-code {
        border: 1px solid $color-text-dark;
        border-radius: 4px;
        @include kare-code();
        background-color: $color-surface-light;
        margin-bottom: $spacing-xs;
        header {
            color: $color-text-light;
            background-color: $color-text-dark;
            padding: $spacing-xxs $spacing-xs;
            display: flex;
            justify-content: space-between;
            > div.copy-button {
                text-transform: uppercase;
                cursor: pointer;
            }
        }
        .settings__application-info-columns {
            display: flex;
            padding: $spacing-xxs $spacing-xs;
        }
        pre {
            display: block;
            overflow: auto;
            code {
                display: block;
            }
        }
        pre.settings__application-info-numbers {
            margin-right: $spacing-xs;
            flex-shrink: 0;
            code {
                color: $color-black-500;
            }
        }
    }
    &__callbacks {
        pre {
            background-color: $color-surface-light;
            border: 1px solid $color-text-dark;
            border-radius: 4px;
            padding: $spacing-xxs $spacing-xs;
        }
    }
}
