@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.ListResponses__root {
    .ListResponses__item {
        display: flex;
        flex-flow: row;
        height: 40px;
        align-items: center;
        color: $color-grey;
        font-size: 14px;
        cursor: pointer;
        &:hover {
            color: $color-primary;
        }
        svg {
            margin-right: $spacing-xs;
        }
        &.itemIsActive {
            color: $color-primary;
        }
    }
}

.ListResponses__inputContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-bottom: 10px;
    > div:first-child {
        flex: 1;
        margin-right: 8px;
        margin-bottom: 0;
    }
}

.ListResponses__tagsContainer {
    margin-bottom: 8px;
    .c-field-tags__form-less {
        max-width: 100%;
    }
    .react-tags__suggestions {
        left: unset;
        right: 0;
    }
}

.ListResponses__buttons {
    display: flex;
    justify-content: space-between;
}

.ListResponses__listContainer {
    padding: 0 15px 15px 15px;
    margin-right: -15px;
    height: 400px;
    overflow-y: scroll;
}
