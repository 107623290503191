.assets-uploader {
    height: 100%;
    padding-bottom: 50px;

    button[type='submit'] {
        margin-top: 20px;
        display: block;
    }
    .assets-image__file-name, .assets-video__file-name {
        margin-bottom: $spacing-xs;
        display: block;
        margin-top: $spacing-xs;
    }
    .assets-uploader__drag {
        border: 2px dashed $color-black-300;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.active {
            border-style: solid;
        }
        &.dragging {
            border-color: $color-purple-500;
            .assets-uploader__drag-add {
                color: $color-purple-500;
            }
        }
        .assets-uploader__drag-add {
            font-size: 40px;
            color: $color-grey-light;
        }
        .assets-uploader__description {
            text-align: center;
        }
        &:hover {
            border-color: $color-purple-500;
            .assets-uploader__drag-add {
                color: $color-purple-500;
            }
        }
    }
}
.asset-upload__selector {
    border-bottom: 2px solid $color-grey-700;
    padding-bottom: 2px;
    margin-bottom: $spacing-s;
    .asset-upload__selector-btn {
        margin-right: $spacing-s;
        cursor: pointer;
        &.active {
            color: $color-secondary;
            border-bottom: 2px solid $color-purple-500;
            padding-bottom: 4px;
        }
    }
}
.video-block-modal,
.image-block-modal {
    display: flex;
    flex-flow: column;
    height: 100%;
    .media-block-modal-buttons {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 15px;
    }
    .media-block-modal-current-image {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: $color-grey-700;
    }
}
.video-block-modal {
    .assets-video_preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 15px 15px;

        > div {
            display: flex;
            justify-content: center;
        }
    }
}

.thumbnail {
    width: 50%;
}
