.kare-select-input {
    margin-bottom: $spacing-m;
    label {
        @include kare-form-label();
    }
    // Override react-select-container until changed
    .react-select-container {
        & > div:first-of-type {
            & {
                cursor: pointer;
            }

            &,
            &:hover {
                border: 1px solid $color-black-700;
                box-shadow: none;
            }
            & > div:last-of-type > span {
                background-color: $color-black-700;
            }

            &:hover {
                background-color: $color-grey-500;
            }
            svg {
                fill: $color-black-700;
            }
        }
    }
}
