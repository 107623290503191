:global {
    .kare-dropdown__indicator-separator {
        display: none;
    }

    .kare-dropdown__control {
        border-radius: 4px;
        border-color: #676f73 !important;
        min-height: 30px !important;
        &--is-focused {
            box-shadow: none !important;
        }
    }

    .kare-dropdown__option {
        color: #676f73 !important;
        &--is-selected {
            background-color: #eeeeee !important;
        }
    }

    .kare-dropdown__menu {
        margin-top: 0 !important;
    }

    .kare-dropdown__menu-list {
        border-radius: 0px !important;
        border: 1px solid #676f73 !important;
        border-top: none !important;
    }
}
