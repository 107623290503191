@import './src/app/styles/kss/spacings';
@import './src/app/styles/kss/colors';

.CxMetrics__treemapLoading {
    padding: $spacing-m 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2 {
        margin-bottom: $spacing-m;
    }
}

.CxMetrics__cardContainer {
    transition: 0.3s all ease;
}

.CxMetrics__kareTitleContainer {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;

    .CxMetrics__kareCardTitle {
        display: inline-flex;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 600;
        color: $color-black-900;
        margin: 0;
        text-transform: capitalize;

        h1 {
            font-size: 1.6rem;
        }
    }

    .CxMetrics__kareCardStats {
        display: inline-flex;
        font-size: 1.4rem;
        line-height: 1.7rem;
        font-weight: 600;
        color: $color-black-900;
        margin: 0;
        justify-content: flex-end;
        align-items: center;

        .CxMetrics__stats {
            margin: 0 1rem;

            &:last-of-type {
                margin-right: 0;
            }

            div {
                height: 100%;
            }

            .CxMetrics__counter {
                font-size: 1.4rem;
                font-weight: 700;
            }

            .CxMetrics__label {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
}

.CxMetrics__kareGraphContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .CxMetrics__radialChart {
        flex: 1;

        .RadialChart__hero strong {
            color: $color-black-900;
            max-width: 10rem;
            font-size: 1rem;
            font-weight: normal;
        }

        small {
            font-weight: 800;
        }
    }

    .CxMetrics__barChart {
        .CxMetrics__questionTitle {
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: normal;
            color: $color-black-900;
            margin: 0 2rem;

            h1 {
                font-size: 1.6rem;
            }
        }

        .BarChart__container {
            padding-right: 0.8rem;
        }

        flex: 4;
        height: 20rem;
    }
}

.CxMetrics__kareFormulaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease;
    z-index: 1;

    .CxMetrics__label {
        font-size: 1.4rem;
        font-weight: 600;
        color: $color-black-700;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex: 1;

        .CxMetrics__labelContainer {
            margin: 1rem 0;
            display: flex;
            background: none;
            color: inherit;
            border: none;
            padding: 0.5rem;
            font: inherit;
            outline: inherit;
            cursor: pointer;
        }

        .CxMetrics__formulaPanel {
            padding: 2rem 1rem;
            border-top: 0.1rem $color-black-300 solid;
            border-bottom: 0.1rem $color-black-300 solid;
            flex: 0 1 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .CxMetrics__formulaInfoces,
            .CxMetrics__formulaInfonps {
                display: flex;
                flex-direction: column;

                span {
                    margin: 0.25rem 0;
                }
            }

            .CxMetrics__formulaInfocsat {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .CxMetrics__satisfactionLabel {
                    font-size: 1.3rem;
                    word-break: break-word;
                    width: 10rem;
                    text-align: center;
                }
            }

            .CxMetrics__formulaInfocsat span {
                margin: 0 0.5rem;
            }

            .CxMetrics__fractionTop {
                flex: 1;
                padding-bottom: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    margin: 0 0.5rem;
                }
            }

            .CxMetrics__fractionBottom {
                flex: 1;
                padding-top: 1rem;
                font-size: 1rem;
                color: $color-black-1000;
                display: flex;
                justify-content: center;
                border-top: 0.1rem solid $color-black-1200;
            }

            .CxMetrics__formulaSchemaces,
            .CxMetrics__formulaSchemanps {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .CxMetrics__icons,
                .CxMetrics__scores {
                    flex: 0 1 100%;
                    display: flex;
                    margin-bottom: 1rem;

                    .CxMetrics__icon,
                    .CxMetrics__score {
                        flex: 0 1 4rem;
                        display: flex;
                        justify-content: center;
                    }
                }

                .CxMetrics__difficultyHelper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: center;
                    color: $color-black-1000;
                    flex: 1;

                    .CxMetrics__difficult {
                        flex: 3;
                        border-top: #d3262e 0.1rem solid;
                        padding-top: 1rem;
                    }

                    .CxMetrics__neutral {
                        flex: 2;
                        border-top: transparent 0.1rem solid;
                        padding-top: 1rem;
                    }

                    .CxMetrics__easy {
                        flex: 2;
                        border-top: #33c779 0.1rem solid;
                        padding-top: 1rem;
                    }
                }
            }

            .CxMetrics__formulaSchemacsat {
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
            }

            .CxMetrics__divider {
                width: 0.1rem;
                margin: 0 2rem;
                background: $color-black-300;
                height: 6rem;
            }
        }
    }
}

.CxMetrics__showFormulaEnterDone {
    opacity: 1;
}

.CxMetrics__hideFormulaEnterDone {
    opacity: 1;
}

.CxMetrics__showFormulaEnter {
    opacity: 0;
    transform: scale(0.9);
}

.CxMetrics__hideFormulaEnter {
    opacity: 0;
    transform: scale(0.9);
}

.CxMetrics__showFormulaEnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.CxMetrics__hideFormulaEnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.CxMetrics__showFormulaExit {
    opacity: 1;
}

.CxMetrics__hideFormulaExit {
    opacity: 1;
}

.CxMetrics__hideFormulaExitDone {
    opacity: 0;
}

.CxMetrics__showFormulaExitDone {
    opacity: 0;
}

.CxMetrics__showFormulaExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.CxMetrics__hideFormulaExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.CxMetrics__barChartTooltip {
    padding: 1.2rem;
    margin: -0.5rem -0.9rem;
    background-color: $color-black-1000;
    color: $color-white-1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CxMetrics__emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24.4rem;
    flex: 1;
}

.read-reviews-icon {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.7rem;

}
