@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.TextInput__input {
    width: 100%;
    height: 30px;
    padding: 12px 20px;
    border-radius: 2px;
    border: 1px solid #676f73;
    margin-top: 0 !important;
    box-sizing: border-box;
}

.TextInput__inputAndError {
    display: flex;
    flex-flow: column;
    > span {
        align-self: flex-end;
        color: $color-danger;
        font-size: $font-size-xs;
    }
}
.TextInput__label {
    color: $label-color;
    font-size: $font-size-s;
}
