/* ==========================================================================
   # KNOWLEDGE BASE PANEL BODY
   ========================================================================== */

.knowledge-base__panel-body {
    flex-grow: 2;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
}

.knowledge-base__portal-sidebar {
    flex-shrink: 0;
    .knowledge-base__panel-body {
        overflow: auto;
    }
}
