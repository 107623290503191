@import './feedback-list/index.scss';
@import './feedback-viewer/index.scss';

.feedback-list-viewer-wrapper {
    height: inherit;
    background-color: $color-white-1000;

    .feedback-list-viewer-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        padding: 20px;
        border-bottom: 1px solid $color-black-300;
        height: 75px;

        .feedback-list-viewer-dropdown,
        .feedback-list-viewer-question {
            line-height: 33px;
        }

        .feedback-list-viewer-dropdown {
            width: 350px;
        }

        .feedback-list-viewer-question {
            margin-left: 20px;
            flex-grow: 6;
            font-style: italic;
            color: $color-black-700;
            font-size: 13px;
        }

        .feedback-list-viewer-feedback-count {
            flex-grow: 1;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;

            .feedback-list-viewer-icon {
            }

            .feedback-list-viewer-counter {
                color: $color-black-1000;

                .counter {
                    font-size: 20px;
                    font-weight: 600;
                    margin-right: 5px;
                }

                .label {
                    font-size: 12px;
                }
            }
        }

        .feedback-list-viewer-icon {
            margin-right: 5px;
        }
    }

    .feedback-list-viewer-body {
        display: flex;
        height: calc(100% - 75px);
        width: 100%;
    }
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;

    .no-results-icon,
    .no-results-label {
        flex: 0 1 100%;
        height: 48%;
        display: flex;
        justify-content: center;
    }

    .no-results-icon {
        align-items: flex-end;
    }

    .no-results-label {
        align-items: flex-start;
    }
}

.feedback__button {
    background-color: $color-black-700;
    color: $color-white-1000;
    border-radius: $border-radius-menu;
    font-size: 12px;
    padding: 20px;

    &:hover {
        background-color: darken($color-black-700, 10%);
        border-color: darken($color-black-700, 10%);
        color: $color-white-1000;
    }
}

.conversation-button a {
    text-decoration: none;
}

.feedback-spinner {
    display: flex;
    align-items: center;
    margin: 0 auto;
}
