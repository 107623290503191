.settings-zendesk-form {
    .settings-zendesk-form-content {
        display: flex;
        align-items: center;
        p {
            margin-bottom: $spacing-xs;
        }
    }
    h1 {
        @include kare-form-label();
    }
    .c-btn {
        margin-right: $spacing-xs;
        line-height: unset;
    }
    svg {
        margin-right: $spacing-xxs;
    }
}
