.no-responses {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        @include kare-h2();
        margin-bottom: $spacing-s;
    }
}
