@import "src/app/styles/kss/colors";

.Dialog__container {
    background-image: url('./query.svg');
    background-repeat: repeat;
    position: relative;

    .Dialog__empty {
        position: fixed;
        top: 30px;
        left: 184px;
        z-index: 9999;
        p {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    h1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 35px 35px 0;
        margin-bottom: 35px;
        min-height: 85px;
    }
    .Dialog__canvas {
        height: 100vh;
        & > * {
            width: 100%;
            height: 100%;
        }
    }
    .Dialog__backButton {
        position: fixed;
        bottom: 20px;
        left: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-purple-600;
        background-color: $color-white;
        color: $color-purple-600;

        svg {
            margin-right: 5px;
        }
    }
    .Dialog__zoomButtons {
        position: fixed;
        right: 20px;
        bottom: 20px;
        display: flex;
        & > div {
            cursor: pointer;
            border: 1px solid $color-purple-600;
            border-radius: 4px;
            background-color: white;
            margin-right: 10px;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                background-color: $color-purple-100;
            }
            &.disabled {
                opacity: 0.9;
                cursor: not-allowed;
            }
            svg {
                fill: $color-purple-600;
            }
        }
    }
}
