@import '../common';
@import 'response-viewer/index';

.selected-response {
    flex: 1;
    position: relative;
    transition: background-color #{$transition-properties};
    height: 100%;
    .query-response__attach-content-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        svg {
            margin-right: $spacing-xs;
        }
    }
    .query-response__viewer-header-title {
        @include kare-overline-2();
        margin-left: $response-content-margin-left;
        margin-top: $spacing-xs;
        margin-bottom: $spacing-xs;
    }
    .query-response__viewer-title {
        @include truncateText(100%);
        svg {
            margin-right: $spacing-s;
        }
    }
    .query-response__title {
        @include kare-h3();
        margin-top: 0;
        padding: 0 $response-content-margin-left;
        height: 40px;
        flex-direction: column;
        div {
            display: flex;
            align-items: center;
            span {
                display: block;
                @include truncateText(100%);
            }
        }
    }
    .query-response__description {
        border: 1px solid transparent;
        padding: $spacing-xs $response-content-margin-left;
        .response-viewer__quote {
            margin-bottom: $spacing-s;
        }
        code {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }
    .query-response__description,
    .admin-console-draft-md-editor {
        @include kare-body();
    }

    .query-response__find-more {
        @include kare-body();
        margin: $spacing-s 0;
        svg {
            margin-right: $spacing-s;
        }
        a {
            text-decoration: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: $spacing-xs/2 0;
            span {
                text-align: left;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .query-response__edit-indicator {
        @include hide();
        position: absolute;
        top: $spacing-xs;
        right: $spacing-xs;
        @include kare-caption();
        color: $color-secondary;
        transition: #{$transition-properties} all;
        svg {
            transform: translateY(-1px);
        }
    }
}
.query-response__submit-buttons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
    right: -25px;
    svg:first-child {
        fill: $color-secondary;
    }
    svg:last-child {
        margin-top: 27px;
        fill: $color-grey-1000;
    }
}
.is-focused div.admin-console-draft-md-editor {
    border: 1px solid $color-secondary;
}
