@import 'src/app/styles/kss/colors';
@import 'src/app/styles/kss/spacings';

.library-filters {
    width: 100%;
    ul {
        display: flex !important;
        list-style-type: none;
        padding: 24px 25px 0 36px;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid $color-black-100;
        li {
            padding-bottom: 5px;
            margin: 0 12px;
            line-height: 14px;
            cursor: pointer;
            color: $color-black-300;
            &.active {
                cursor: auto;
                border-bottom: 2px solid $color-purple-500;
                color: $color-black-900;
                margin-bottom: -1px;
            }
            &:first-of-type {
                margin-left: 0;
            }
            &:last-of-type {
                margin: 0;
            }
            &:nth-last-child(2) {
                margin-right: auto;
            }
        }
    }
}

.access-uploader-list-wrapper {
    display: flex;
    flex-direction: column;
}

.access-uploader-block-modal {
    h1 {
        @include kare-h4();
        margin-bottom: 20px;
        display: flex;

        > svg {
            margin-left: auto;
            margin-top: 5px;
            cursor: pointer;
        }
    }

    label {
        margin-bottom: $spacing-xs;
        display: block;
    }

    .access-uploader-block-modal-buttons {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;

        > button {
            margin-left: 10px;
        }
    }
}

.assets-uploader-document {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $color-grey-700;
}

.assets-uploader-document-icon-lib {
    margin-left: 0;
    margin-right: 8px;
    margin-bottom: -6px;
}

.assets-uploader-document-icon-check {
    margin-left: 0;
    margin-bottom: -6px;
    color: $color-dataviz-green;
}

.assets-uploader-invalid-doc {
    color: $color-red-500;
}

.assets-uploader-document-icon-close {
    color: $color-red-500;

    > svg {
        margin-left: 0;
        margin-bottom: -6px;
    }
}

.assets-uploader__drag-add {
    font-size: small !important;
}
