@import '~react-perfect-scrollbar/dist/css/styles.css';

$scrollableSectionHeight: 400px;

@import 'cell-wrapper/index';
@import 'queries/index';

.dashboard-responses-container {
    display: flex;
    max-width: $container-max-width;
    border-bottom: $border-light;
    margin-bottom: $spacing-xl;
}

.ReactTable.dashboard-responses {
    @include kare-body();
    width: 60%;
    .dashboard-response-type-icon {
        flex-shrink: 0;
        margin-right: $spacing-xs;
    }
    .dashboard-responses-icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .rt-thead {
        height: $spacing-l;
    }
    .rt-tr-group {
        &:hover {
            .dashboard-responses--icon-chevron {
                color: $color-secondary;
            }
        }
    }
    .rt-tbody {
        border-top: $border-light;
        border-left: $border-light;
        height: $scrollableSectionHeight;
        overflow: auto;
    }

    .dashboard-responses-row--is-active {
        background-color: $color-surface-dark;
    }
    .dashboard__dashboard-responses-column-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
