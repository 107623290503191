@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/fonts';
@import '/src/app/styles/kss/headers';

.WebCrawlerForm__container {
    height: 600px;
}

.WebCrawlerForm__loading {
    display: flex;
    align-items: center;
    height: 100px;
}

.WebCrawlerForm__titleContainer {
    background-color: #fbc02d;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.WebCrawlerForm__titleText {
    @include kare-h4();
    display: flex;

    > svg {
        margin-left: auto;
        cursor: pointer;
    }
}
