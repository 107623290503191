$color-transparent-overlay: rgba(0, 0, 0, 0.2);

$color-black-1200: #1e282c;
$color-black-1000: #263238;
$color-black-700: #676f73;
$color-black-500: #92989b;

$color-grey-1200: #6b818c;
$color-grey-1000: #78909c;
$color-grey-700: #e0e0e0;
$color-grey-500: #eeeeee;
$color-grey-300: #f6f5f2;

$color-white-1000: #ffffff;

$color-green-1200: #009445;
$color-green-1000: #00b957;
$color-green-700: #82eb89;
$color-green-300: #dbf9dd;
$color-green-50: #f2fdf3;

$color-red-1200: #a81e24;
$color-red-1000: #d3262e;
$color-red-700: #f69370;
$color-red-500: #f9b299;
$color-red-300: #fbd1c1;
$color-red-50: #fef7f5;

$color-blue-1200: #0076ac;
$color-blue-1000: #0094d7;
$color-blue-700: #4fc3f7;
$color-blue-500: #81d4f9;
$color-blue-300: #b3e5fb;
$color-blue-50: #f2fafe;

$color-yellow-1200: #fbc02d;
$color-yellow-1000: #fbc02d;
$color-yellow-700: #fde371;
$color-yellow-500: #feeb9a;
$color-yellow-300: #fef3c2;
$color-yellow-50: #fffdf5;

/* DIALPAD */

$color-white: #FFF;

$color-black-25: #F7F7F9;
$color-black-100: #CFCDD8;
$color-black-300: #9C99AC;
$color-black-900: #0D0C0F;

$color-yellow-300: #FFCB59;
$color-yellow-500: #FFBC0F;
$color-yellow-600: #3F2D00;

$color-red-300: #FFA7A7;
$color-red-500: #D81616;
$color-red-600: #930000;
$color-red-700: #480000;

$color-green-300: #9CFF6D;
$color-green-500: #0C9B02;
$color-green-600: #0A5B03;
$color-green-700: #052F01;

$color-purple-100: #ECE1FF;
$color-purple-300: #A687FF;
$color-purple-500: #6C3DFF;
$color-purple-600: #3A1C95;
$color-purple-700: #2A1173;
$color-purple-800: #160645;

$color-dataviz-blue: #2E91FF;
$color-dataviz-green: #36A385;
$color-dataviz-red: #E8586B;
$color-dataviz-orange: #FDAC7A;

// Semantic color variables:

$color-primary: $color-purple-500;
$color-primary-dark: $color-green-1200;
$color-primary-light: $color-green-500;

$color-secondary: $color-black-900;
$color-secondary-dark: $color-blue-1200;
$color-secondary-light: $color-blue-500;
$color-secondary-very-light: $color-blue-50;

$color-error: $color-red-600;
$color-error-dark: $color-red-1200;
$color-error-light: $color-red-500;

$color-warning: $color-yellow-1000;
$color-warning-dark: $color-yellow-1200;
$color-warning-light: $color-yellow-500;

$color-background: $color-white-1000;
$color-surface-light: $color-grey-300;
$color-surface-dark: $color-black-25;
$color-surface-very-dark: $color-purple-800;

$color-text-light: $color-white;
$color-text-dark: $color-black-900;
$color-text-link: $color-purple-500;

$border-lighter: 1px solid $color-grey-500;
$border-light: 1px solid $color-black-100;
$border-dark: 2px solid $color-purple-500;
$border-secondary: 1px solid $color-secondary;

$border-radius-menu: 2px;

$color-grey: #444150;

$color-grey-light: #676f73;
$color-grey-lighter: #bdc1c3;
$color-grey-lightest: #d8d8d8;

$color-success: $color-dataviz-green;
$color-danger: $color-dataviz-red;

$color-warning: #fbc02d;
$default-box-shadow-color: 0 1px 2px 0 rgba(0, 0, 0, 0.24);

$label-color: $color-grey-light;
$label-color-hover: $color-grey;

$color-li-primary: $color-black-900;
$color-li-secondary: $color-black-300;

$colorWhite: $color-white-1000;
$colorBlack: $color-black-1000;
$colorGrey: $color-grey-1000;
$colorSecondary: $color-secondary;
$colorSurfaceDark: $color-surface-dark;
$colorPrimary: $color-primary;
$colorPrimaryDark: $color-primary-dark;
$colorError: $color-error;
