@import './src/app/styles/kss/colors';

.UploadContentModal__buttons {
    display: flex;
    justify-content: space-between;
}

.UploadContentModal__tabs {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    border-bottom: 1px solid #e0e0e0;
    margin: 10px 0 10px;
    .UploadContentModal__tab {
        cursor: pointer;

        &:hover {
            color: $color-grey-1000;
        }
    }

    > div {
        color: $color-grey-1200;
        padding: 0 20px 10px 20px;
    }

    .UploadContentModal__tabDeleted {
        margin-left: auto;
    }

    .UploadContentModal__activeTab {
        color: $color-secondary;
        box-shadow: 0 1px 0 0 $color-secondary;
        &:hover {
            color: $color-secondary;
        }
    }
}
