@import 'date-picker/index';
@import 'dashboard-activity/index';
@import 'queries/index';
@import 'responses/index';
@import 'empty-state/index';

.dashboard-container {
    margin-left: auto;
    margin-right: auto;
}
