.markdown {
    strong {
        font-weight: bold;
    }
    p {
        margin-bottom: 12px;
        color: $color-black-1000;
    }
    code {
        font-family: monospace;
        color: rgb(34, 161, 219);
        background-color: rgb(246, 245, 242);
        padding: 0 3px 0 3px;
        border-style: solid;
        border-width: 1px;
        border-color: rgb(139, 144, 147);
    }
    > *:last-child {
        margin-bottom: 0;
    }
}
.markdown-editor {
    ol,
    ul {
        padding-left: 35px !important;
    }
    .public-DraftStyleDefault-block {
        margin: 0.5em 0;
    }
}
.chat__text-message-iframe {
    width: 100%;
    max-width: 100%;
    iframe {
        width: 100%;
        max-width: 100%;
        max-height: 220px;
    }
    margin-bottom: var(--spacing-unit-tiny);
}
