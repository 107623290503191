@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/fonts';
@import './src/app/styles/kss/spacings';

@mixin kare-h4 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: normal;
    text-decoration: none;
}

.Modal__modal {
    z-index: 9998;
    background-color: rgba(#000, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal__modalContent {
    width: 100%;
    max-width: 500px;
    padding: $spacing-s;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: $color-white-1000;
}

.Modal__header {
    display: flex;
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
}
.Modal__title {
    @include kare-h4();
}
