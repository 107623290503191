.kare-colorpicker {
    position: relative;
    width: 180px;
    input {
        @include kare-h6();
        border: none;
        cursor: pointer;
        display: inline-block;
        padding: 0;
        width: 120px;
        height: 30px;
        text-align: center;
        border: $border-light;
    }
    .kare-colorpicker-picker {
        position: absolute;
        z-index: 100;
        bottom: -306px - $spacing-xxs;
    }
    label {
        @include kare-form-label();
    }
}
