.console-activity-conversation {
    * {
        cursor: pointer;
    }
    border-bottom: $border-light;
    padding: 0 $spacing-s;
    height: 30px;
    &:hover {
        background-color: $color-grey-300;
        .console-activity-conversation-timestamp svg {
            color: $color-secondary;
        }
    }
    &.console-activity-conversation--active {
        background-color: $color-purple-100;
        cursor: default;
        .console-activity-conversation-timestamp svg {
            color: $color-purple-100;
        }
        * {
            cursor: default;
        }
    }
    .console-activity-conversations-list__row {
        height: 30px;

        &:first-child {
            margin-bottom: $spacing-xxs;
            display: flex;
            cursor: pointer;
            justify-content: space-between;
            align-items: center;
            transition: $global-transition;

            .console-activity-conversation-timestamp {
                color: $color-purple-700;
            }
        }
        .visitor {
            color: $color-black-900;
            span {
                color: $color-black-900;
            }
        }
        & > .console-activity-conversations-list__column-1 {
            text-align: center;

            svg {
                width: 15px;
                height: 15px;
            }
            span {
                max-width: 85%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .console-activity-conversations-list__second-row {
        display: flex;
    }
}
.console-activity-conversations-list__label-counter {
    color: $color-black-500;
}

.console-activity-conversation--not-bounced .console-activity-conversation-status {
    color: $color-secondary;
}

.console-activity-conversation--resolved .console-activity-conversation-status {
    color: $color-primary;
}
.console-activity-conversation-status {
    svg {
        background-color: $color-error;
        color: $color-white-1000;
        padding: 2px;
        border-radius: 50%;
    }
}
.console-activity-conversation--bounced .console-activity-conversation-status {
    color: $color-grey-1000;
    svg {
        transform: translateY(2px);
    }
}
