@import '/src/app/styles/kss/spacings';

.ExtraOptions__container {
    margin-bottom: $section-margin-bottom;
    > div {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
