$preview-font-size: 10px;
.settings__widget-preview {
    margin-top: $spacing-xs + 22px; // Adding lineheight of corresponding header so items are aligned.
    width: 264px;
    height: 403px;
    flex-shrink: 0;
    @include kare-box-shadow();
    border-radius: 4px;
    background-color: $color-surface-dark;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .settings__widget-preview-banner {
        display: flex;
        height: 198px;
        flex-direction: column;
        font-size: $preview-font-size;
        transition: all 0.3s ease-in-out;
        .settings__widget-preview-banner-top-bar {
            height: 24px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: $spacing-xxs;
            svg {
                border-radius: 50%;
                padding: 3px;
                margin-left: 6px;
            }
        }
        .settings__widget-preview-banner-logo-section {
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $spacing-xs $spacing-s 0 $spacing-s;
        }
        .settings__widget-preview-banner-tag-section {
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $spacing-xs $spacing-s;
            font-size: $preview-font-size;
            align-content: center;
            margin-top: auto;
            justify-content: center;
        }
    }
    .settings__widget-preview-messages {
        padding: $spacing-s;
        flex-grow: 1;
    }
    .settings__widget-preview-message {
        padding: $spacing-xs;
        background-color: $color-background;
        border-radius: 4px;
        @include kare-box-shadow();
        max-width: 220px;
        display: inline-block;
        font-size: $preview-font-size;
    }
    .settings__widget-preview-composer {
        background-color: $color-background;
        height: 37px;
        flex-shrink: 0;
        padding: 0 $spacing-xs;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            color: $color-grey-1000;
            font-style: italic;
            font-size: $preview-font-size;
        }
    }
}
