@import 'variables';
@import 'mixins';
@import '/src/app/styles/kss/fonts';
@import '/src/app/styles/kss/spacings';
@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/mixins';

// include all needed keyframes
@include notification-keyframes('top');
@include notification-keyframes('bottom');
@include notification-keyframes('left');
@include notification-keyframes('right');
@include notification-keyframes('top', 0);

// rotating animation for loading status
@keyframes rotating {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
    }
}

// NotificationSystem component
.notifications-system {
}
.notification-image-container {
}

// NotificationContainer component
.notifications-container {
    position: fixed;
    z-index: 99999;
}

// for small to large screens, container position and notification animation is
// different depending on container position
.notifications-container {
    // animations depending of the notification position
    &--t {
        width: calc(100% - 40px);
        top: 20px;
        left: 20px;
        @include notification-animation('top');
    }
    &--tc {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        @include notification-animation('top');
    }
    &--tr {
        top: 20px;
        right: 20px;
        @include notification-animation('right');
    }
    &--tl {
        top: 20px;
        left: 20px;
        @include notification-animation('left');
    }
    &--b {
        width: calc(100% - 40px);
        bottom: 0;
        left: 20px;
        @include notification-animation('bottom');
    }
    &--bc {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include notification-animation('bottom');
    }
    &--br {
        bottom: 0;
        right: 20px;
        @include notification-animation('right');
    }
    &--bl {
        bottom: 0;
        left: 20px;
        @include notification-animation('left');
    }
}

// put all notifications at the top of the screen
@media (max-width: #{$screen-xs-max}) {
    .notifications-container {
        &--t {
            width: 100%;
            top: 0;
            left: 0;
            @include notification-animation('top', 0);
        }
    }
}

// Notification component
.notification {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 40px;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    z-index: 999;
    background-color: $color-background;

    &-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        margin-bottom: $spacing-s;
    }
    &-meta {
        width: 100%;
    }
    &-close-button-container {
        height: $spacing-m;
        background-color: $color-surface-very-dark;
        display: flex;
        align-items: center;
    }
    &-close-button {
        // override font-size of `fa` class
        font-size: 14px !important;
        color: $color-text-light;
        cursor: pointer;
        padding: 0 $spacing-xs;
        &:before {
            content: $notification-close-button;
        }
    }
    &-title {
        height: $spacing-m;
        @include kare-button-2-text();
        display: flex;
        background-color: $color-surface-very-dark;
        color: $color-text-light;
        align-items: center;
        padding-left: $spacing-s;
    }
    &-message {
        padding-left: $spacing-s;
        @include truncateText(300px);
    }
}
