.cx-creation-modal-content {
    display: flex;
    flex-flow: column;
    h3 {
        margin-top: 15px;
    }
    span {
        @include kare-h4();
        height: 20px;
        color: #676f73;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
    }
    .cx-creation-modal-wrapper-input {
        margin-bottom: 10px;
        input {
            margin-top: 8px;
            width: 100%;
            height: 30px;
            padding: 0 10px;
            border: 1px solid #676f73;
            border-radius: 2px;
        }
        span {
            font-size: 12px;
        }
        .cx-creation-modal-error {
            font-size: 8px;
            height: 12px;
            color: red;
        }
        .kare-multiselect-checkbox__ui-outer {
            display: inline-flex !important;
            margin-right: 5px;
        }
    }
}

.cx-creation-modal-wrapper-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    span {
        font-size: 10px;
    }
}

.cx-creation-modal {
    color: #263238;
    font-weight: 700;
    line-height: 22px;
    font-size: 18px;
    margin-bottom: 10px;
    h2 {
        @include kare-h4();
        font-size: 18px;
        margin-bottom: 15px;
    }
}
