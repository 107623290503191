/* ==========================================================================
   # KNOWLEDGE BASE PORTAL
   ========================================================================== */

.knowledge-base__portal {
    .knowledge-base-options {
        color: $color-black-700;
        cursor: pointer;
        margin-left: $spacing-xs;
    }
    .knowledge-base-linked-responses-link {
        display: flex;
        align-items: center;
    }
    .knowledge-base-document-tools {
        display: flex;
        align-items: center;
    }
}

.knowledge-base-document-button {
    margin-left: 1rem;
}

.knowledge-base-document-footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.knowledge-base-document-linked-button {
    margin-right: auto;
}

@import 'document-detail/index';
@import 'related-responses/index';
