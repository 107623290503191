@import "src/app/styles/kss/spacings";
@import "src/app/styles/kss/colors";

.ExitsResponseTable__title {
    border-bottom: none;
}

.ExitsResponseTable__titleNode {
    display: flex;
    align-items: center;
    color: #2c3940;
    svg {
        width: 16px;
        height: 16px;
        margin-right: $spacing-xs;
        color: $color-black-900;
    }
}
