.viewer {
    padding: 20px;
    flex-grow: 3;
    .viewer-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        .feedback-details {
            .user,
            .contact {
                display: flex;
                & > span {
                    &:first-child {
                        height: 16px;
                        width: 16px;
                        margin-right: 5px;
                    }
                }
            }
            .user {
                font-weight: 600;
            }
            .contact,
            .date {
                color: $color-black-500;
            }
        }
    }
    .viewer-body {
    }
}
