.document-context {
    padding: $spacing-s;
    margin: 0 -#{$spacing-s};
    border-top: $border-light;
    min-height: $spacing-l;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    align-items: center;

    &.document-context-labels {
        border-bottom: 1px solid #cfcdd8;
        margin-top: 0;
    }

    &.document-context-edit {
        margin-top: 0;
        border-bottom: $border-light;
    }
}

.document-context-labels-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-context-text {
    > b {
        font-weight: $fontWeightBold;
        display: inline-block;
        color: $color-grey;
    }
}

.document-context-entry {
    padding-bottom: $spacing-xxs;
}

.document-context-icons {
    margin-right: 0.8rem;
    color: $color-grey;
}

.document-context-edit-icon {
    cursor: pointer;
    color: $color-grey;
}

.document-context-icons-ctx {
    margin-left: 0;
    margin-right: $spacing-xs;
    color: $color-grey;
}

.document-context-save-text {
    color: $color-purple-600;
    font-weight: 600;
    cursor: pointer;
}
