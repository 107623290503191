$popover-triangle-size: 8px;
$popover-shadow-blur: 8px;
$popover-shadow-main-color: rgba(0, 0, 0, 0.24);
$popover-shadow-secondary-color: rgba(0, 0, 0, 0.12);

$window-preview-width: 264px;
$window-preview-height: 161px;

.launcher-preview {
    display: flex;
    position: relative;
    margin-top: 25px;

    .u-margin-bottom > label, .kare-colorpicker label {
        font-family: $global-text-font-stack;
        font-weight: $fontWeightBold;
        color: #676F73;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
    }
    .launcher-preview__settings {
        flex: 3;
    }

    .launcher-preview__container {
        display: flex;
        flex-direction: column;
        margin-top: 85px;
    }
    .launcher-preview__window {
        border: 1px solid $color-black-700;
        border-radius: 5px;
        width: $window-preview-width;
        height: $window-preview-height;
        overflow: hidden;
        position: relative;
        &__header {
            height: 14px;
            background-color: $color-black-700;
            display: flex;
            align-items: center;
        }
        .window__header__dot {
            @include square(6px);
            background-color: #d8d8d8;
            border-radius: 50%;
            margin-left: $spacing-xxs;
        }
        .launcher-preview__default-demo {
            position: absolute;
            bottom: $spacing-s;
            right: $spacing-xxs;
            &.console-kare-widget--side-left {
                left: $spacing-s;
                right: unset;
                .console-kare-widget {
                    transform-origin: bottom left;
                }
                .launcher-popover__preview-container
                    .launcher-popover__preview {
                    transform-origin: top left !important;
                    right: 0 !important;
                }
                .launcher-popover__preview-container {
                    left: 0;
                    right: unset;
                    .launcher-popover__preview {
                        position: absolute;
                        left: 0;
                        right: unset !important;
                    }
                }
            }
            .console-kare-widget {
                transform: scale(0.4);
                transform-origin: bottom right;
                &--size-small
                    + .launcher-popover__preview-container
                    .launcher-popover__preview {
                    transform: scale(0.2);
                }
                &--size-large
                    + .launcher-popover__preview-container
                    .launcher-popover__preview {
                    transform: scale(0.5);
                }
            }
            .launcher-popover__preview-container {
                position: absolute;
                right: 0;
                width: $window-preview-width;
                display: flex;
                justify-content: flex-end;

                .launcher-popover__preview {
                    display: inline-block;
                    transform: scale(0.4);
                    transform-origin: top right;
                    position: relative;
                    &:after {
                        @include triangle(
                            bottom,
                            $popover-triangle-size,
                            $popover-triangle-size,
                            inherit
                        );
                        transform: rotate(180deg);
                        position: absolute;
                        bottom: auto;
                        left: calc(100% - #{$spacing-s});
                        top: 50%;
                        filter: drop-shadow(
                            0 0 $popover-shadow-blur $popover-shadow-main-color
                        );
                    }
                }
            }
        }
        .launcher-preview__vertical-demo {
            position: absolute;
            right: 0;
            transform: scale(0.5);
            transform-origin: top right;
            margin-top: 0;
            &.launcher-preview__vertical-demo--side-left {
                left: -18px;
                right: unset;
                .launcher-popover__preview-container {
                    position: absolute;
                    left: 50px;
                    top: 50%;
                    transform: translateY(-50%);
                    &:after {
                        display: none;
                    }
                }
            }
            .launcher-popover__preview-container {
                margin-right: $spacing-m;
                display: flex;
                align-items: center;
                position: relative;
                &:after {
                    @include triangle(
                        bottom,
                        $popover-triangle-size,
                        $popover-triangle-size,
                        $color-white-1000
                    );
                    transform: rotate(90deg) translateY(-50%);
                    position: absolute;
                    bottom: auto;
                    left: 100%;
                    filter: drop-shadow(
                        0 0 $popover-shadow-blur $popover-shadow-main-color
                    );
                }
            }
            .launcher-popover__preview {
                transform-origin: right;
            }
            &--size-small {
                .launcher-popover__preview {
                    transform: scale(0.8);
                }
                &.launcher-preview__vertical-demo--side-left {
                    left: -13px;
                }
            }

            &--size-large {
                .launcher-popover__preview {
                    transform: scale(1.2);
                }
                &.launcher-preview__vertical-demo--side-left {
                    left: -23px;
                }
            }
        }
    }

    .launcher-preview__field-wrapper {
        margin-top: $spacing-s;
        h2 {
            margin-bottom: $spacing-s;
        }
        &-options {
            display: flex;
            .radio-input {
                margin-right: $spacing-s;
            }
        }
    }

    .launcher-preview__cta .kare-text-input {
        margin: 20px 0 0;
    }
}

@import 'vertical-launcher/index';
@import 'horizontal-launcher/index';
@import 'custom-launcher/index';
@import 'device-selector/index';
