.feedback-choice-wrapper {
    position: absolute;

    &__left {
        margin-top: -1.5rem;
        left: 12%;
    }

    &__right {
        margin-top: -5.3rem;
        right: 19%;
    }
}

.feedback-choice {
    display: flex;
    align-items: center;

    &__thumbs_down {
        color: $color-primary;
    }

    &__thumbs_up {
        color: $color-primary;
    }

    & span {
        padding-left: 0.4rem;
    }
}

