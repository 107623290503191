@import '/src/app/styles/kss/colors';

.Button__container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    height: 30px;
    cursor: pointer;
    border-radius: 3px;
}

.Button__button {
    border-radius: 4px;
    padding: 8px 20px;
}

.Button__confirm,
.Button__submit {
    color: white;
    background-color: $color-purple-500;
    border: 0;
}

$cancel-color: #92989b;
$cancel-color-hover: #676f73;
.Button__cancel {
    color: $cancel-color;
    border: 1px solid $cancel-color;
    background-color: white;
    &:hover {
        border: 1px solid $cancel-color-hover;
        color: $cancel-color-hover;
    }
}
