.prompt-action-banner {
    background-color: #eaf2fa;
    border: #0000001a solid 1px;
    color: #000;
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 $spacing-s;
    height: $spacing-l;
    display: inline-flex;
    align-items: center;
    z-index: 99;
    width: calc(
        100% - 160px
    ); // This is based on the fixed width of the nav on the left.
    svg {
        color: #01326d;
    }
    h5,
    span {
        margin-bottom: 0;
        color: #000;
        justify-content: flex-start;
        align-items: flex-start;
    }
    h5 {
        margin-left: 4px;
        margin-right: 4px;
        font-weight: 600;
    }
    span {
        margin-bottom: -2px;
    }
}
