@import 'feedback/index';

.dashboard-response-query-text {
    @include kare-body();
    color: $color-text-light;
    position: relative;
    background-color: $color-grey-1000;
    border-radius: 10px;
    padding: 5px $spacing-s;
    margin-bottom: $spacing-xs;
    display: inline-block;
}
