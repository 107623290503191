@import './src/app/styles/kss/colors';

.StaticMessageItem__container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid $color-black-100;
    color: $color-grey;
    > div {
        display: flex;
        align-items: center;
        word-break: break-all;
        width: 50%;

        svg:first-of-type {
            margin-left: 10px;
        }
        > svg:first-child {
            margin-left: 2px;
            margin-right: 10px;
        }
    }
    & .StaticMessageItem__svgActionable {
        cursor: pointer;
    }
    & .StaticMessageItem__description {
        color: $color-grey;
    }
    .StaticMessageItem__assignedResponse {
        cursor: pointer;
        &:hover {
            color: $color-grey-1200;
        }
    }

    &.StaticMessageItem__isDeleted {
        color: $color-error;
        & .StaticMessageItem__capitalize {
            text-transform: capitalize;
            margin-right: 4px;
        }
    }
}

.itemTitleContainer {
    color: $color-grey;
}
