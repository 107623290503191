@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.ChartLegend__legendContainer {
    display: block;
    padding: 10px 8px;
    li {
        align-items: center;
        margin-top: $spacing-s;
    }
    .ChartLegend__itemOneLine {
        display: inline-flex;
        margin-top: 8px;
        strong,
        small {
            font-size: 12px;
        }
    }
    .ChartLegend__icon {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: $spacing-xs;
    }
    width: 100%;
    span {
        display: inline-block;
    }
    strong {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }
    strong,
    small {
        padding-left: $spacing-s;
        display: block;
    }
    small {
        color: $color-grey;
        font-size: 14px;
    }
}
