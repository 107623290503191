.Journey__container {
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    & > div {
        float: left;
        width: 50%;
    }
}
.Journey__chartContainer {
    width: 200px;
    height: 200px;
}
