@import '/src/app/styles/kss/spacings';

.StartingPages__container {
    margin-bottom: $section-margin-bottom;
}

.StartingPages__fieldArrayContainer {
    margin-bottom: 8px;
}

.StartingPages__inputContainer {
    display: flex;
}

.StartingPages__input {
    flex: 1;
    margin-bottom: 0.4rem;
}

.StartingPages__removeInput {
    cursor: pointer;
    margin-left: 0.4rem;
    margin-top: 0.5rem;
}

.StartingPages__checkboxWithQuestion {
    display: flex;
    cursor: pointer;
}
