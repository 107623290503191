@import './src/app/styles/kss/colors';

.Uploader__container {
    background: white;
}

@mixin mediaContainer {
    overflow: hidden;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border: dashed $color-grey-1000 2px;
    margin: 10px 0;
}

.Uploader__videoContainer {
    width: 100%;
    height: 200px;
    @include mediaContainer;
}

.Uploader__imageContainer {
    width: 100px;
    height: 100px;
    @include mediaContainer;
    img {
        border-style: none;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
}

.Uploader__contentContainer {
    width: 100%;
    height: 50px;
    @include mediaContainer;
    svg {
        width: 1.5em;
        height: 1.5em;
    }
    svg:first-of-type {
        margin-right: 8px;
    }
    .Uploader__removeContent {
        display: flex;
        align-items: center;
        margin-left: auto;
    }
    .Uploader__contentName {
        height: 17px;
        color: #676f73;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 17px;
    }
}
.Uploader__inputContainer {
    width: 100%;
    height: 100px;
    @include mediaContainer;
    &:focus {
        outline: none;
    }
    input {
        display: none;
    }

    .Uploader__inputText {
        height: 14px;
        width: 236px;
        color: $color-grey-1000;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 14px;

        > span {
            color: $color-secondary;
            cursor: pointer;

            &:hover {
                color: $color-secondary-dark;
            }
        }
    }
}
