$thumb-height: 12px;
$track-height: 14px;
$track-width: 24px;
// all the styles here that begin with .react are overriding the default l
.kare-switch {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-s;
    .react-toggle:hover {
        .react-toggle-track {
            background-color: $color-grey-1000 !important;
        }
        &.react-toggle--checked {
            .react-toggle-track {
                background-color: $color-purple-700 !important;
            }
        }
    }
    .react-toggle--disabled:not(.react-toggle--checked) {
        .react-toggle-thumb {
            background-color: $color-black-300 !important;
            border: 1px solid $color-black-300;
        }
    }
    .react-toggle-track {
        width: $track-width;
        height: $track-height;
        background-color: $color-black-300;
    }
    .react-toggle-thumb {
        @include square($thumb-height);

        background-color: $color-white-1000;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2) !important;
        top: -($thumb-height - $track-height) / 2;
        left: 1px;
        border: 0;
    }
    .react-toggle--checked {
        .react-toggle-thumb {
            background-color: $color-white;
            left: 11px;
        }
        .react-toggle-track {
            background-color: $color-purple-500;
        }
    }
    > span {
        margin-left: $spacing-xs;
    }
}
