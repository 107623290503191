$radio-input-outer-width: 20px;
$radio-input-outer-border: 2px;
$radio-input-inner-width: 10px;
$radio-input-transition: 0.15s ease;

$radio-input-hover-color: $color-purple-600;

.radio-input {
    input {
        display: none;
        & + label {
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                @include kare-caption();
                font-size: 12px;
                line-height: 17px;
                transition: color $radio-input-hover-color;
            }
            &:hover {
                span {
                    color: $radio-input-hover-color;
                }
            }
            .radio-input__checkmark {
                position: relative;
                margin-right: $spacing-xs;
                &:before,
                &:after {
                    display: block;
                    content: '';
                    border-radius: 50%;
                }
                &:before {
                    border: $radio-input-outer-border solid $color-black-700;
                    transition: border-color #{$radio-input-transition};
                    @include square($radio-input-outer-width);
                }
                &:after {
                    $positioning: $radio-input-inner-width / 2;
                    position: absolute;
                    top: $positioning;
                    left: $positioning;
                    transition: background-color #{$radio-input-transition};
                    @include square($radio-input-inner-width);
                }
            }
        }
        &:checked + label {
            span {
                color: $color-text-dark;
            }
            .radio-input__checkmark {
                &:before {
                    border-color: $color-purple-500;
                }
                &:after {
                    background-color: $color-purple-500;
                }
            }
        }
    }
    &:hover {
        input:not(:checked) + label .radio-input__checkmark {
            &:before {
                border-color: $radio-input-hover-color;
            }
        }
        input:checked + label .radio-input__checkmark {
            &:before {
                border-color: $color-purple-500;
            }
            &:after {
                background-color: $color-purple-500;
            }
        }
    }
    input:disabled {
        & + label {
            cursor: not-allowed;
        }
        &:not(:checked) + label .radio-input__checkmark {
            &:before {
                border-color: $color-grey-500;
            }
        }
        &:checked + label .radio-input__checkmark {
            &:before {
                border-color: $color-grey-500;
            }
            &:after {
                background-color: $color-grey-500;
            }
        }
    }
}
