/* ==========================================================================
   # DOCUMENT DETAIL
   ========================================================================== */


.knowledge-base__document-detail {
    padding: $spacing-s;
    overflow-y: scroll;
    height: 100%;
    flex-grow: 2;
}

.knowledge-base__document-detail-title {
    @include kare-h6();
    margin-bottom: 10px;
}
.knowledge-base__document-detail-link {
    @include kare-overline-3();
    text-transform: none;
    color: $color-purple-500;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding-bottom: 10px;
    span {
        @include truncateText(100%);
    }
}

.knowledge-base__metadata-text {
    color: $color-black-300;
    padding-right: 15px;
    font-size: $font-size-xs;
    font-weight: $fontWeightBold;

    > b {
        color: $color-grey;
    }

    > .knowledge-base__status-text {
        color: $color-purple-500;
        font-weight: 700;
        text-transform: capitalize;
    }
}

.knowledge-base__doc-detail {
    display: flex;
    justify-content: space-between;
}
