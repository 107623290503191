.UploadFromURL__inputHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: #676f73;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
}

.UploadFromURL__inputContent {
    margin-bottom: 8px;
}
