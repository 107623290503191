.BarChart__container {
    height: 354px;
}

.bar-chart-tooltip {
    color: #0D0C0F;
    text-transform: capitalize;
    font-family:
        'Inter','Roboto Mono', arial, sans-serif;
    background-color: #FFF;
    border-radius: 0.2rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.1rem 0.2rem;
    padding: 0.5rem 0.9rem;
}
