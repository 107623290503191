.attachment-block-field-container {
    margin-top: 15px;
    &:first-of-type {
        margin-top: 0;
    }
}
.attachment-block-field-title-container {
    display: flex;
    align-items: center;
    svg {
        color: #03a9f4;
        margin-top: 1px;
        margin-right: 8px;
        width: 15px;
        height: 15px;
        align-self: flex-start;
    }

    font-weight: 900;
    font-size: 15px;
    color: #03a9f4;
}

.attachment-block-field-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachment-block-field-text-container {
    display: flex;
    flex-flow: column;
    color: #03a9f4;

    .attachment-block-field-filename {
        font-weight: unset;
        margin-top: 4px;
        font-size: 12px;
        color: #676f73;
    }
}
