.kare-status-tabs {
    padding: 0 23px 0 36px;
    display: flex;
    justify-content: space-between;
    border-bottom: $border-light;
    > div {
        flex-grow: 2;
        display: flex;
        .kare-status-tab {
            cursor: pointer;
            padding-bottom: $spacing-xxs;
            @include kare-button-1-text();
            color: $color-black-300;
            &.kare-status-tab--active {
                border-bottom: $border-dark;
                cursor: default;
                @include kare-overline-1();
                font-weight: normal;
                margin-bottom: -1px;
            }
            &:hover {
                border-bottom: $border-dark;
            }
        }
    }
    .kare-status-tabs-left {
        justify-content: flex-start;
        .kare-status-tab {
            margin-right: $spacing-m;
        }
    }
    .kare-status-tabs-right {
        justify-content: flex-end;
        .kare-status-tab {
            margin-left: $spacing-m;
        }
    }
}
