.LoadingModal__container {
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
    background: white;
    height: 100px;
    width: 200px;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.55);
}
