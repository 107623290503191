.c-field-attached-document {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    background-color: $color-grey-300;

    .c-field-attached-document-header {
        display: flex;
        justify-content: space-between;
        h1 {
            padding: $spacing-xs $spacing-s;
            @include kare-overline-2();
        }
        svg {
            margin-top: $spacing-xs;
            margin-right: $spacing-s;
        }
        h1 {
            @extend .c-label-field__text;
            margin-bottom: 0;
        }
    }
    .c-field-attached-document-content {
        background-color: $color-grey-300;
        padding: $spacing-s;
        color: $color-text-light;
        display: flex;
        justify-content: space-between;
        z-index: 100;
    }
}

.c-field-attached-document-title {
    text-decoration: none;
}

.c-field-attached-document-close-button {
    cursor: pointer;
    color: $color-text-dark;
}
