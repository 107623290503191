.copyright-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright-wrapper {
    width: clamp(300px, 40vw, 100%);
    height: clamp(300px, 40vh, 100%);
}

.copyright-logo,
.copyright-title,
.copyright-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
.copyright-logo {
    img {
        max-width: clamp(160px, 10vw, 320px);
        height: auto;
    }
}
.copyright-title {
    margin: clamp(5px, 2vh, 20px) 0;
    h1 {
        font-size: clamp(16px, 1.4vw, 32px);
    }
}

.copyright-body {
    color: $color-black-900;
    border-top: 1px solid $color-black-100;
    padding: clamp(5px, 2vh, 20px) 0;
    display: flex;
    flex-wrap: wrap;
}

.copyright-body__text,
.copyright-body__button {
    flex: 0 1 100%;
}
.copyright-body__button {
    margin: clamp(5px, 2vh, 20px) 0;
    max-width: clamp(90px, 10vw, 320px);
    a {
        font-size: clamp(11px, 0.7vw, 12px);
    }
}
.copyright-body__text {
    text-align: center;
    font-size: clamp(11px, 0.7vw, 14px);
    ul {
        li {
            font-weight: 600;
        }
    }
}
