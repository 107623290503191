@import 'button/index';
@import 'layouts/index';
@import 'panel-item/index';
@import 'spinner/index';
@import 'tags-input/index';
@import 'radio-input/index';
@import 'search-bar/index';
@import 'kare-text-input/index';
@import 'kare-dropdown/index';
@import 'kare-label/index';
@import 'kare-multiselect-checkbox/index';
@import 'kare-switch/index';
@import 'kare-quote-selection/index';
@import 'markdown/index';
@import 'markdown-editor/index';
@import 'markdown-editor-error/index';
@import 'tags/index';
@import 'checkbox-input/index';
@import 'multi-website-input/index';
@import 'field';
@import 'panel-body/index';
@import 'queries-input-error/index';
@import 'panel-footer/index';
@import 'panel-header/index';
@import 'panel-summary/index';
@import 'portal-main/index';
@import 'portal-sidebar/index';
@import 'document-external-url-link/index';
@import 'knowledge-base/index';
@import 'tooltip/index';
@import 'response-title-error/index';
@import 'label/index';
@import 'colorpicker/index';
@import 'select-input/index';
@import 'integrations/index';
@import 'multiselect-input/index';
@import 'knowledge-base-portal/index';
@import 'kare-error/index';
@import 'kare-status-tabs/index';
@import 'kare-modal/index';
@import 'kare-dummy-data/index';
@import 'searchable-select-input/index';
@import 'kare-response-renderer/index';
@import 'character-count/index';
@import 'document-metadata-editor/index';
html {
    box-sizing: border-box;
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
}
// TODO(rupertrutland): Move this to an appropriate place.
.content__filter {
    display: block;
    width: 100%;
    h1 {
        @include kare-button-2-text();
        margin: 0;
    }
    > * {
        padding: 0 $spacing-xs;
        height: $spacing-m;
        display: flex;
        align-items: center;
    }
    .content__filter-header {
        border-bottom: $border-light;
    }
    .content__filter-switch {
        a {
            color: $color-text-dark;
        }
        > div {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
