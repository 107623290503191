.queries-card-actions {
    margin-top: $spacing-s;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    &__left-actions {
        display: flex;
    }

    &__right-actions {
        display: flex;
        flex-flow: row;
    }
    &__left-actions,
    &__right-actions {
        button span {
            display: flex;
            align-items: center;
        }
    }
    button {
        svg {
            margin-right: $spacing-xs;
        }
    }
    &__deselect-response {
        color: rgba($color-black-1000, 0.7);
        &:hover {
            color: $color-text-dark;
        }
    }
    &__accept-response {
        .queries-card-actions__accept-spinner {
            margin-left: $spacing-xs;
        }
    }
}
