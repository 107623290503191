@import 'rpa/index';

.escalation-settings {
    form {
        margin: 0;
    }
    p {
        margin-bottom: $spacing-xs;
    }
    &__add__button {
        margin-top: $spacing-xs;
    }
    &__entry__container {
        display: flex;
        align-items: center;
        margin-top: $spacing-xs;
        .c-btn {
            min-width: 130px;
            margin-left: $spacing-m;
        }
    }
    &__entry {
        cursor: pointer;
        width: 100%;
        border: 1px solid $color-black-700;
        border-radius: 2px;
        padding: 0 $spacing-s;
        @include heightLine($spacing-m);
        display: flex;
        justify-content: space-between;
        &__method {
            display: flex;
            align-items: center;
            span {
                margin-left: $spacing-xs;
            }
        }

        &__meta {
            *:not(.escalation-settings__entry__default) {
                display: none;
            }
            align-items: center;
            & > * {
                margin-right: $spacing-xs;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &:hover {
            .escalation-settings__entry__meta {
                *:not(.escalation-settings__entry__default) {
                    display: inline;
                }
            }
        }
        &__default {
            right: $spacing-s;
            text-transform: uppercase;
            font-weight: bold;
            pointer-events: none;
        }
    }
    &__modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color-transparent-overlay;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        &__content {
            width: 600px;
            background-color: $color-white-1000;
            padding: $spacing-s;
            border-radius: 2px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12),
                0 8px 8px 0 rgba(0, 0, 0, 0.24);
            h2 {
                @include kare-h4();
                font-size: 18px;
                margin-bottom: $spacing-s;
                display: flex;

                > svg {
                    margin-left: auto;
                    cursor: pointer;
                }
            }
            &__row {
                display: flex;
                align-content: center;
                margin-bottom: $spacing-s;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &.spaced {
                    justify-content: space-between;
                    .c-btn {
                        padding: 0 $spacing-l;
                        span {
                            margin: 0;
                        }
                    }
                }
                &.confirm-buttons {
                    justify-content: flex-end;
                    .c-btn {
                        padding: 0 $spacing-l;
                        margin-left: $spacing-s;
                        span {
                            margin: 0;
                        }
                    }
                }
                div.kare-text-input {
                    margin-bottom: 0;
                    max-width: 400px;
                }
            }
        }
    }
    .escalation-instructions {
        padding: $spacing-xs $spacing-s;
        border: $border-light;
        margin-bottom: $spacing-xs;
    }
}

.escalation-settings_spain_margins {
    justify-content: space-between;
    span {
        line-height: $spacing-m;
        margin-right: $spacing-m;
    }
    .kare-dropdown-container {
        width: 100%;
        max-width: 400px !important;
    }
}
