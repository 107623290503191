@import "src/app/styles/kss/colors";
@import "src/app/styles/kss/spacings";

$border-color: transparent;

.Table__tableContainer {
    display: grid;
}

.Table__column {
    display: flex;
    cursor: pointer;
    text-transform: uppercase;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
    font-weight: 700;
    color: $color-grey;
    align-items: center;

    > span {
        display: flex;
        align-items: center;
    }

    > svg {
        color: $color-black-900;
    }

    &:first-of-type {
        padding-left: $spacing-s;
    }

    border-bottom: 0.1rem solid $border-color;

    &.Table__active {
        svg {
            color: $color-black-900;
        }
    }

    &.Table__metric {
        justify-content: flex-end;
        padding-right: 1rem;
    }
}

.Table__highlighted {
    background-color: $color-black-25;
}

.Table__rowValue {
    align-items: center;
    height: 3rem;
    cursor: pointer;

    span {
        height: 100%;
    }

    .Table__percentage {
        font-weight: 400;
        margin-left: 0.2rem;
    }

    &.Table__borders {
        border-right: 0.1rem solid $border-color;
    }

    &.Table__last {
        border-right: none;
    }

    &.Table__first {
        padding: 0.6rem 0 0.6rem $spacing-s;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &.Table__position {
        border-right: none;
    }
}

.Table__metric {
    font-weight: 700;
    justify-content: flex-end;
    padding-right: 2rem;
    color: #263238;
    display: flex;
}

.Table__seeAll {
    display: flex;
    justify-content: center;

    > span {
        cursor: pointer;
        color: #676f73;
        text-transform: uppercase;
        font-size: 1rem;
    }

    svg {
        width: 1rem;
        height: 1rem;
    }
}
