// React table
@import '~react-table/react-table.css';
// KSS
@import 'kss/index';
$knowled-base__portal-main-width: 960px;

// APP
@import '../common/index';
@import '../dashboard/index';
@import '../improve/index';
@import '../responses/index';
@import '../documents/index';
@import '../activity/index';
@import '../styleguide/index';
@import '../login/index';
@import '../settings/index';
@import '../with-permissions/index';
@import '../actions/index';
@import '../widget/index';



