.ActionList__container {
    .ActionList__inputContainer {
        margin-bottom: 10px;
    }

    .ActionList__buttons {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
}
