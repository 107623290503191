.kare-user-settings {
    .kare-user-settings-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing-m;
        margin-bottom: $spacing-s;
        border-bottom: $border-light;
        h1 {
            @include kare-h4();
        }
        .kare-user-settings-permissions {
            @include kare-button-2-text();
        }
        .kare-user-settings-right {
            display: flex;

            button {
                min-width: 140px;
                &:first-child {
                    margin-right: $spacing-s;
                }
            }
        }
    }
    .user-settings-add-new-user {
        margin-top: $spacing-s;
        padding-left: calc(#{$spacing-xs} - 2px) ; // To offset the icon's assumed padding.
    }
}
.settings__password-reset-form {
    h1 {
        @include kare-h4();
        display: flex;

        > svg {
            margin-left: auto;
            cursor: pointer;
        }
    }
}

@import 'user-table/index';
@import 'user-form/index';
