@import 'query/index';
.dashboard-response-queries {
    width: 40%;
}

.dashboard-response-queries-list {
    padding: $spacing-s 0 0 $spacing-s;
    border-top: $border-light;
    border-left: $border-light;
    border-right: $border-light;
    background-color: $color-grey-300;
    height: $scrollableSectionHeight - 1px; // Adjust height for 1px border.
    overflow: auto;
    .scrollbar-container {
        padding-right: 20px;
    }
}
// TODO(ruoertrutland): Align headers a bit lower.
.dashboard-response-queries-title {
    @include kare-overline-3();
    height: $spacing-l;
    padding-left: $spacing-xs;
    display: flex;
    align-items: center;
}
