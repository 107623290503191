@import 'queries-response-list-auto-response-item/index';
@import '../../common';

$new-response-height: 60px;
$search-response-height: 50px;
$search-response-block: 84px;
.queries-response-list-container {
    height: calc(100% - #{$search-response-block});
    .queries-response-list-create-button {
        @include kare-button-1-text();
        display: flex;
        align-items: center;
        color: $color-secondary;
        height: $responses-list-height;
        svg {
            margin-right: $spacing-xs;
        }
        cursor: pointer;
    }
    .queries-response-item {
        position: relative;
        height: $responses-list-height;
        color: $color-black-700;
        display: flex;
        align-items: center;
        font-weight: 600;
        cursor: pointer;

        span {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        svg {
            color: $color-black-700;
            margin-right: $spacing-s;
            flex-shrink: 0;
        }
        &:hover {
            &,
            svg {
                color: $color-text-dark;
            }
        }

        &__hover-panel {
            position: absolute;
            left: $spacing-l;
            right: 20px;
            width: calc(90% - #{$spacing-l});
            border-radius: 2px;
            pointer-events: none;
            background-color: $color-white-1000;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12),
                0 8px 8px 0 rgba(0, 0, 0, 0.24);
            z-index: 99;
            top: 0;
            transform: translateY(-100%);
        }
        &:nth-child(-n + 4) {
            .queries-response-item__hover-panel {
                bottom: 0;
                top: auto;
                transform: translateY(100%);
            }
        }
        &__hover-panel-labels,
        &__hover-panel-markdown {
            padding: $spacing-xs $spacing-s;
        }
        &__hover-panel-labels {
            border-top: 1px solid $color-grey-700;
            .kare-label {
                width: auto;
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }
    .ReactVirtualized__Grid.ReactVirtualized__List {
        outline: 0;
    }
    .ReactVirtualized__Grid__innerScrollContainer {
        min-height: 100%;
    }
}
