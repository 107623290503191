.AutomationsTable__title {
    border-bottom: none;
}

.AutomationsTable__titleContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
}

.AutomationsTable__dropdown {
    width: 300px;
}
