@import "src/app/styles/kss/colors";
@import "src/app/styles/kss/spacings";

.Consolidate__container {
    padding: 20px $spacing-m;

    .Consolidate__title {
        font-size: $font-size-xxl;
        margin-bottom: 24px;
    }

    .Consolidate__consolidateTabs {
        width: 100%;
        border-bottom: 1px solid $color-black-100;
        display: flex;
        li {
            margin-right: $spacing-m;
            cursor: pointer;
            margin-bottom: -1px;
            padding-bottom: $spacing-xs;
            color: $color-black-300;
            &.active {
                color: $color-secondary;
                border-bottom: 2px solid $color-purple-500;
            }
            &:hover {
                color: $color-secondary;
            }
        }
    }
}
