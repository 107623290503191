@import '../../../common';

.queries-stored-response__container {
    @include kare-h6();
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    color: $color-secondary;
    cursor: pointer;
    margin-top: $spacing-xs;
    align-items: center;
    height: $responses-list-height;
    span {
        @include truncateText(100%);
    }
    &:hover {
        svg {
            color: $color-secondary;
        }
    }
    svg {
        color: $color-secondary;
        margin-right: $spacing-s;
        @include square(25px);
    }
}
