@import './src/app/styles/kss/fonts';
@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.ChatterMessages__chatterMessagesWrapper {
    .ChatterMessages__header {
        display: flex;
        justify-content: space-between;
        padding: 20px $spacing-m 0;
    }

    .ChatterMessages__title {
        font-size: 24px;
        line-height: 29px;
        font-weight: $fontWeightBold;
        text-transform: capitalize;
        margin-bottom: $spacing-m;
    }

    .ChatterMessages__description {
        padding: 0 $spacing-m;
    }

    .ChatterMessages__tabs {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        border-bottom: 1px solid $color-black-100;
        margin: 35px 0 10px;
        padding: 0 24px 0 36px;
        .ChatterMessages__tab {
            cursor: pointer;

            &:hover {
                color: $color-grey-1000;
            }
        }

        > div {
            color: $color-black-300;
            padding: 0 12px $spacing-xs 12px;

            &.ChatterMessages__activeTab {
                color: $color-black-900;
                border-bottom: 2px solid $color-purple-500;
                margin-bottom: -1px;
                &:hover {
                    color: $color-black-300;
                }
            }
        }

        .ChatterMessages__tabDeleted {
            margin-left: auto;
        }
    }
}
