.knowledge-base__portal {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1000;
    div.knowledge-base__portal__overlay {
        display: block;
    }
    &__body {
        height: 100vh;
        width: 100vw;
    }
    .knowledge-base__search-bar {
        margin-bottom: 0;
    }
}
