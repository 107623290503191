$circle-size: 50px;
$circle-margin: 2px;

.message-cx__csat-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > div {
        flex: 1;
    }
    .message-cx__csat-title-container {
        margin: 5px 0;
        .message-cx__csat-title {
            font-size: 14px;
            color: $color-black-1000;
            margin-bottom: 5px;
        }
    }
    .message-cx__csat-icon-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        .message-cx__csat-item {
            width: calc(100% / 3);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            .message-cx__csat-icon-wrapper {
                flex: 0 1 calc(100% / 3);
                max-height: $circle-size;
                max-width: $circle-size;
                margin: 0 10px;
                .message-cx__csat-icon {
                    svg {
                        border-radius: 50%;
                    }
                    &.selected.danger svg {
                        background: $color-red-1000;
                    }
                    &.selected.warning svg {
                        background: $color-yellow-1000;
                    }
                    &.selected.success svg {
                        background: $color-green-1000;
                    }
                }
            }
            .message-cx__csat-label-container {
                display: flex;
                align-items: center;
                justify-content: center;
                .message-cx__csat-label-negative,
                .message-cx__csat-label-neutral,
                .message-cx__csat-label-positive {
                    width: 100%;
                    margin: 0 10px;
                    font-family: $global-text-font-stack;
                    font-weight: 400;
                    font-size: 10px;
                    letter-spacing: normal;
                    text-decoration: none;
                    color: #676f73;
                    line-height: 20px;
                    text-align: center;
                    word-break: break-word;
                }
            }
        }
    }
}
