@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

$toolbar-background: $color-background;
.markdown-editor {
    position: relative;
    height: 100%;
    width: 100%;
    .rdw-image-imagewrapper {
        width: 100%;
    }
}
.DraftEditor-editorContainer {
    min-height: 200px;
    font-family: $global-text-font-stack;
}
.markdown-editor__wrapper {
    // max-height: calc(100% - 125px);
    background-color: $color-background;
    flex-grow: 2;
    position: relative;
    border: 1px solid $color-secondary;
    border-radius: 2px;
    .character-count {
        position: absolute;
        transform: translateY(-50%);
        top: 20px;
        left: 315px;
    }
    .editor-done {
        @include kare-overline-3();
        color: $color-secondary;
        position: absolute;
        top: 13px;
        right: 39px;
        cursor: pointer;
    }
    .trash-editor-block {
        color: $color-text-dark;
        position: absolute;
        top: 14px;
        right: 13px;
        cursor: pointer;
    }
    &.markdown-editor__wrapper--error {
        border-color: $color-error;
    }
}
div.rdw-editor-toolbar {
    padding: 0;
    background-color: hsla(0, 0%, 88%, 0.2);
    border: 1px solid hsla(0, 0%, 88%, 0.2);
    margin-bottom: 0;
    border-radius: 0;
    width: 100%;
    box-sizing: border-box;
    & > div {
        margin-bottom: 0;
        .rdw-option-wrapper {
            padding: 0 5px;
            height: 38px;
            width: 38px;
            display: flex;
            justify-content: center;
        }
    }
    .rdw-option-wrapper {
        border: 0;
        background-color: transparent;
        margin: 0;
        &:hover {
            box-shadow: none;
        }
        &.rdw-option-active,
        &.rdw-option-active:hover {
            background-color: $toolbar-background;
            box-shadow: none;
        }
    }
    .rdw-image-modal {
        &-header {
            flex-direction: column;
            &:before {
                content: 'Insert image';
                display: block;
                @include kare-h6();
            }
            &:after {
                content: 'Image URL';
                display: block;
                @include kare-body();
                margin-top: $spacing-s;
                margin-bottom: -20px;
            }
            &-option {
                display: none;
            }
        }
        &-size {
            display: none;
        }
        &-url-input {
            width: 100%;
        }
        .rdw-image-mandatory-sign {
            display: none;
        }
        &-btn-section {
            display: flex;
            justify-content: space-between;
            margin: 10px 0 0;
            .rdw-image-modal-btn {
                text-transform: uppercase;
                @include kare-button-2-text();
                &:first-of-type {
                    order: 2;
                    background-color: $color-primary;
                    color: $color-white-1000;
                    &:disabled {
                        cursor: not-allowed;
                        pointer-events: none;
                        background-color: $color-grey-700;
                    }
                }
                &:last-of-type {
                    order: 1;
                }
            }
        }
    }
}
.admin-console-draft-md-editor {
    padding: $spacing-xs;
    ::selection {
        background-color: $color-secondary;
        color: $color-text-light;
    }
    li {
        margin-left: 0 !important;
    }
}

.is-invalid .admin-console-draft-md-editor {
    border-color: $color-error;
}

.is-focused .admin-console-draft-md-editor {
    border-color: $color-primary;
}

.rdw-inline-wrapper,
.rdw-list-wrapper {
    margin-bottom: 0;
}

.rdw-option-wrapper.rdw-option-active {
    background-color: $color-surface-dark;
}
.markdown-editor .rdw-link-modal {
    border-radius: 3px;
    border: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    height: auto !important;
    left: -110px;
    .rdw-link-modal-input {
        height: $spacing-m;
        width: calc(100% - 30px);
    }
    .rdw-link-modal-target-option {
        display: none;
    }
    .rdw-link-modal-buttonsection {
        display: flex;
        width: 100%;
        justify-content: space-between;
        button {
            border-radius: 3px;
            margin: 0;
            width: 40%;
        }
        button:hover {
            box-shadow: none;
        }
        button:first-child {
            order: 2;
            &:not([disabled]) {
                background-color: $color-primary;
                color: $color-text-light;
                &:hover {
                    background-color: $color-primary-dark;
                }
            }
        }
        button:last-child {
            order: 1;
            color: $color-black-500;
            &:hover {
                color: $color-black-700;
            }
        }
    }
}
