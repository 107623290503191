@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.BrokenCard__title {
    position: relative;
    cursor: pointer;
    svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}
.BrokenCard__content {
    display: flex;
    justify-content: space-evenly;
    text-align: center;

    .BrokenCard__block {
        padding: $spacing-m 0;
        h2 {
            color: $color-grey;
        }
    }
}
.BrokenCard__customEmpty {
    text-align: center;
    .BrokenCard__top {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-bottom: $spacing-xs;
        svg {
            color: green;
            margin-right: $spacing-xs;
            width: 22px;
            height: 22px;
        }
        span {
            font-weight: bold;
            font-size: $font-size-l;
        }
    }
    .BrokenCard__bottom {
        font-size: $font-size-s;
    }
}
