@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.muted__container {
    h1 {
        width: 100%;
        text-align: center;
        padding: $spacing-l 0;
    }
    .muted__subHeader {
        margin-top: $spacing-s;
        font-size: $font-size-s;
        line-height: 16px;

        color: #263238;
    }
    .muted__content {
        & > h3 {
            margin-top: $spacing-m;
            font-size: $font-size-xs;
            color: $color-grey;
            font-weight: bold;
            padding-left: $spacing-m;
        }
    }

    ul {
        li.muted__clusterItem {
            padding: $spacing-xs $spacing-m;
            border-top: 1px solid $color-grey-lighter;
            display: flex;
            align-items: center;
            &:first-of-type {
                margin-top: $spacing-xs;
            }
            transition: background-color 0.15s ease;
            &:last-of-type {
                border-bottom: 1px solid $color-grey-lighter;
            }
            &:hover {
                background-color: $color-black-25;

                li {
                    background-color: #FFFFFF;
                }

                .muted__button {
                    opacity: 1;
                }
            }


        }
    }
}

.muted__buttonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.muted__mutedQueryTableTitleLabel {
    display: flex;
    align-items: center;
    height: 100%;
}
