$icon-size: 40px;
$icon-margin: 0px;

.ces-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .ces-icon-wrapper {
        width: 100%;
        height: 100%;
        margin: $icon-margin;
        flex: 0 1 calc(14.28% - (#{$icon-margin} * 2));

        .ces-icon {
            width: $icon-size;
            height: $icon-size;
            transition: all 0.3s ease-in-out;
            border-radius: 50%;
            cursor: pointer;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                transition: all 0.3s ease-in-out;

                &.danger-dark {
                    background-color: $color-red-1200;
                }

                &.danger {
                    background-color: $color-red-1000;
                }

                &.danger-light {
                    background-color: #dc5158;
                }

                &.warning {
                    background-color: $color-yellow-1000;
                }

                &.warning-light {
                    background-color: #fccd57;
                }

                &.success-light {
                    background-color: #33c779;
                }

                &.success {
                    background-color: $color-green-1200;
                }
            }
        }
    }
}

.kare-block-ces {
    h1 {
        font-size: 17px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 15px;
    }

    span.label {
        font-family: $global-text-font-stack;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: normal;
        text-decoration: none;
        height: 20px;
        color: #676f73;
        line-height: 35px;
    }

    .ces-labels {
        display: flex;
        justify-content: space-between;
        .negative {
            flex: 1;
        }
    }
}
