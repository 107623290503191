$input-width: 210px;

.knowledge-base__container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .knowledge-base__toolbar {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .knowledge-base-search__container {
            width: 100%;
            .knowledge-base-search__container-top {
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 20px 25px 20px 36px;
                .knowledge-base__toolbar-left {
                    .knowledge-base-search__counter {
                        display: flex;
                        flex-direction: row;
                        .knowledge-base-search__counter-text {
                            &-info {
                                @include kare-caption();
                                display: block;
                                margin-right: $spacing-xs;
                            }
                        }
                    }
                    .knowledge-base-search__counter-arrow {
                        cursor: pointer;
                    }
                }
                .knowledge-base__toolbar-right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    > * {
                        margin-left: $spacing-s;
                    }
                    .more-vertical {
                        color: $color-black-700;
                        cursor: pointer;
                        margin-left: $spacing-xs;
                    }
                }
            }
            .knowledge-base-search__bottom {
                display: flex;
                @include kare-overline-2();
                align-items: center;
                margin: 0 0 16px 36px;
                & > span {
                    margin-right: $spacing-xs;
                    @include heightLine($spacing-m);
                }
                .knowledge-base-search__new-label-button {
                    cursor: pointer;
                }
            }
        }
    }

    .ReactTable.knowledge-base-table {
        $header-padding: 2px;
        .rt-thead {
            .rt-th {
                text-align: center;
                display: flex;
                align-items: center;
                @include kare-button-2-text();
                & > div {
                    text-align: left;
                }
                // Triangle styles for sorting
                &.-cursor-pointer {
                    & > div {
                        text-align: left;
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        &:after {
                            content: '▾';
                            font-size: 13px;
                            margin-left: $spacing-xxs;
                            margin-top: -6px;
                            color: $color-grey;
                        }
                    }
                    &.-sort-desc,
                    &.-sort-asc {
                        box-shadow: none;
                    }
                    &.-sort-asc {
                        > div:after {
                            transform: rotate(180deg);
                            color: $color-grey;
                            margin-top: 0;
                        }
                    }
                    &.-sort-desc {
                        > div:after {
                            color: $color-grey;
                        }
                    }
                }
            }
        }

        .rt-tbody {
            .rt-td {
                @include kare-body();
                display: flex;
                align-items: center;
                border-right: none !important;
                color: $color-grey;
            }

            .kare-multiselect-checkbox {
                display: none;
            }
            .rt-tr:hover {
                .kare-multiselect-checkbox {
                    display: block;
                }
            }
        }

        &.knowledge-base-table--has-selected-rows {
            .rt-tbody {
                .kare-multiselect-checkbox {
                    display: block;
                }
            }
        }

        // The spinner is automatically cnetered, but the loaind container in React-table is too.
        // So we need to undo the double offset.
        .c-spinner {
            position: static;
            transform: none;
        }
    }

    &.knowledge-base__container--loading {
        .knowledge-base-search__container-top {
            visibility: hidden;
        }
    }
}
