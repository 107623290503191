/* ==========================================================================
   # NO RELATED DOCUMENT RESULTS
   ========================================================================== */

.knowledge-base__no-related-document-results {
    padding: $spacing-s;
}

.knowledge-base__no-related-document-results-title {
    @include kare-h4();
}
