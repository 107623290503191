$text-input-height: 32px;
$text-input-transition: 0.15s ease;

.kare-text-input {
    width: 100%;
    margin-bottom: $spacing-s;
    &--tight {
        margin-bottom: $spacing-xs;
    }
    &__label {
        @include kare-form-label();
        color: $color-grey;
    }
    &__tip {
        @include kare-caption();
        margin-bottom: $spacing-xxs;
    }
    &--has-tip {
        &__label {
            margin-bottom: $spacing-xxs;
        }
    }

    &__input-row {
        display: flex;
        align-items: center;
        position: relative;
        svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
            cursor: default;
        }
    }
    &__clear-icon {
        margin-left: $spacing-xs;
        cursor: pointer;
    }
    &__field {
        padding-left: $spacing-xs;
        border-radius: 4px;
        outline: 0;
        border: 1px solid $color-grey;
        width: 100%;
        height: $text-input-height;
        font-family: $global-text-font-stack;
        font-size: $font-size-s;

        &:hover, :focus {
            box-shadow: 0 0 0 3px rgba(108,61,255,0.5);
        }
        &--has-error {
            border-color: $color-error;
            &,
            &::placeholder {
                color: $color-error;
            }
        }
        &,
        &::placeholder {
            transition: color $text-input-transition,
                border-color $text-input-transition;
        }
        &:not(.kare-text-input__field--has-error):focus,
        &:not(.kare-text-input__field--has-error):hover {
            border-color: $color-purple-500;
            &::placeholder {
                color: $color-grey-1000;
            }
        }
    }
    &--readonly {
        .kare-text-input__field {
            cursor: not-allowed;
        }
    }
    &__info {
        display: block;
        margin-top: $spacing-xxs;
        @include kare-caption();
        &--error {
            color: $color-error;
        }
        &--warning {
            color: $color-warning;
        }
    }
    &__append-text {
        margin-left: $spacing-xxs;
    }
}

.kare-text-input__label .text-input .kare-text-input__field {
    margin-bottom: 0;
}

.d-truncate {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
.d-c-pointer {
    cursor: pointer !important;
}

.config-url-input {
    background: $color-grey-700;
}

.config-url-description {
    font-size: $font-size-xs;
}
