@import "./src/app/styles/kss/spacings";
@import "./src/app/styles/kss/colors";

.login-form {
    width: 100%;

    .loginButton {
        width: 100%;
        margin-bottom: 24px;
    }

    .forgotPasswordLink {
        display: block;
        width: 100%;
        text-align: center;
        color: $color-purple-500;

        &:hover {
            text-decoration: none;
        }
    }
}
