@import './src/app/styles/kss/fonts';
@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';
@import './src/app/styles/kss/variables';

.DateRange__container {
    display: flex;
    align-items: center;
    span {
        margin-right: $spacing-xs;
        &:last-of-type {
            margin-left: $spacing-xs;
        }
    }
    span + div {
        background-color: $color-white;
        border: 1px solid $color-grey-light;
        border-radius: 4px;
        width: 82px;
        cursor: pointer;
        &,
        & > div {
            height: $date-input-height;
            cursor: pointer;
            &:before,
            &:after {
                display: none;
            }
        }
        input {
            cursor: pointer;
            font-size: 12px;
            font-family: $global-text-font-stack;
            text-align: center;
            color: #263238;
        }
    }
}

.DateRange__subtitle {
    color: $color-black-300;
    font-size: $font-size-xs;
    font-weight: 700;
}
