@import url('https://fonts.googleapis.com/css?family=Inter:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,600,700');

// For large headers we reduce the size and lineheight down to a standard.
// Below tablet landscape, the max font size is 16px;
// This was done mainly for the small macbook air.


@mixin kare-overline-1 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    text-transform: capitalize;
    text-decoration: none;
}

.kare-overline-1 {
    @include kare-overline-1();
}

@mixin kare-overline-2 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightSemiBold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
    text-decoration: none;
}

.kare-overline-2 {
    @include kare-overline-2();
}

@mixin kare-overline-3 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: normal;
    text-transform: capitalize;
    text-decoration: none;
}

.kare-overline-3 {
    @include kare-overline-3();
}

@mixin kare-body {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightNormal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: normal;
}

.kare-body {
    @include kare-body();
}

@mixin kare-caption {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightNormal;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: normal;
}

.kare-caption {
    @include kare-caption();
}
