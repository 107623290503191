@import 'document-list/index';
@import 'document-summary/index';
@import 'constants';

.query-response__documents-modal,
.query-response__documents-list-modal,
.modal-overlay {
    overflow: hidden;
    display: none;
    .open-modal & {
        display: block;
    }
}
.modal-overlay {
    z-index: $overlay-z-index - 1;
    background-color: rgba(#000, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: auto;
}

.query-response__documents-modal,
.query-response__documents-list-modal {
    @include positionMiddle;
    position: fixed;
    z-index: $overlay-z-index;
    box-sizing: border-box;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24);
    cursor: auto;
    height: 90vh;
    width: 900px;
    .modal-title {
        margin-bottom: $spacing-s;
        .knowledge-base__search-bar {
            width: 100%;
            .knowledge-base__search-bar__input-container {
                width: 100%;
                .kare-text-input {
                    width: 100%;
                }
            }
        }
    }
    .query-response__documents-list-modal-close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}
