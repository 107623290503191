.kare-block-controls {
    border-left: 1px solid $color-black-700;
    padding-left: $spacing-xs;
    flex-direction: column;
    justify-content: space-between;
    height: 80px;
    display: flex;
    visibility: hidden;
    > div {
        cursor: pointer;
        color: $color-black-700;
        height: 10px;
        line-height: 10px;
    }
}
