/* ==========================================================================
   # KNOWLEDGE BASE PANEL FOOTER
   ========================================================================== */

.knowledge-base__panel-footer {
    padding: $spacing-s;
    background-color: $color-background;
    border-top: 1px solid $color-grey-700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 65px;
    .response-not-found & {
        display: none;
    }
}

.knowledge-base__panel-footer-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $color-secondary;
    &:hover {
        color: $color-text-dark;
        text-decoration: underline;
    }
}
