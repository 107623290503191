@import "src/app/styles/kss/spacings";

.UrlsTable__title {
    border-bottom: none;
    padding-left: 0;
    padding-right: $spacing-s;
}

.UrlsTable__titleContainer {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: $spacing-s;
}

.UrlsTable__dropdown {
    width: 400px;
}
