.knowledge-base-table.documents__documents-table {
    .rt-tbody {
        .rt-td.documents-table__column-view-original button {
            display: none;
        }
        .rt-tr:hover {
            .rt-td.documents-table__column-view-original button {
                display: flex;
            }
        }
    }
}
