.dashboard-responses-cell-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    span {
        @include truncateText(100%);
    }
}

.dashboard-responses-cell-wrapper--align-center {
    justify-content: center;
}
.dashboard-responses-cell-wrapper--dark {
    color: $color-text-dark;
}
