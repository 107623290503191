@import 'language-picker/index';
@import 'nav-link/index';
@import 'account-menu/index';

.kare-side-bar {
    background-color: $color-surface-very-dark;
    width: 160px;
    min-width: 160px;
    .kare-nav-link {
        position: relative;
        height: 32px;

        &.is-active {
            .kare-side-bar__dropdown {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        span {
            text-transform: capitalize;
        }
    }
    .kare-side-bar__dropdown {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
    }
    @for $i from 1 through 10 {
        &__submenu-#{$i} {
            $nav-item-height: 32px;
            $nav-left-padding: 15px;
            $nav-icon-width: 15px;
            position: relative;
            margin: $spacing-s 0;

            a {
                position: relative;
                display: block;
                line-height: $nav-item-height;
                padding-left: ($nav-left-padding * 2) + $nav-icon-width;
                transition: padding 0.5s ease;
                color: $color-black-300;

                &:before {
                    content: '';
                    height: 100%;
                    width: 4px;
                    background-color: $color-black-300;
                    position: absolute;
                    left: 20px;
                    top: 0;
                    z-index: 99;
                }

                &.is-active {
                    color: $color-white;
                    background-color: transparent;
                    font-weight: bold;

                    &:before {
                        background-color: white;
                    }
                }

                &:hover {
                    color: $color-white;
                    background-color: transparent;
                }
            }
        }
    }
    nav {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .dialpad-sidebar-logo {
            padding: $spacing-m $spacing-s;
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .kare-side-bar-patent {
            color: $color-black-100;
            margin-bottom: $spacing-s;
            text-align: center;
            font-size: 10px;
        }
        .kare-nav-bottom-section {
            margin-top: auto;
        }
    }
    .kare-nav-bottom-section-beta-preview {
        color: $color-black-1200;
        background: white;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        margin-bottom: $spacing-xl;
        &:before {
            content: 'BETA PREVIEW';
        }
        &:hover:before {
            color: $color-primary-dark;
            content: 'RETURN TO CONSOLE';
        }
    }
}
