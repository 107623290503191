$checkbox-size: 20px;
$card-title-gutter: 20px;
$checkbox-inner-size: $checkbox-size - 8px;
$subheader-height: 31px;
.manage {
    &__query-list-container {
        flex: 1;
        background-color: $color-surface-dark;
        min-width: 0;
        max-height: 100%;
        h2.response-card-title {
            @include kare-overline-3();
            color: $color-grey-1000;
            margin-left: $card-title-gutter;
            margin-top: $spacing-xs;
            &.selected-queries {
                color: $color-secondary;
            }
        }
        .response-card-selected-queries-container {
            position: relative;
            .response-card-title {
                color: $color-secondary;
            }
            span {
                @include kare-overline-3();
                color: $color-black-700;
                position: absolute;
                top: 0;
                right: $spacing-s;
                font-weight: bold;
                cursor: pointer;
                &:hover {
                    color: $color-text-dark;
                }
            }
        }
        .scrollbar-container {
            padding: 0 40px $spacing-s $spacing-xs;
            height: calc(100% - #{$subheader-height});
        }
        .manage__query-list-spinner-container {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    &__query-list-item-container {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        input[type='checkbox'] {
            cursor: pointer;
            opacity: 0;
            position: absolute;
            @include square($checkbox-size);
            &:hover + .manage__query-list-item-checkbox > div {
                background-color: $color-black-500;
                transition: all 0.15s linear;
            }
            &:checked + .manage__query-list-item-checkbox {
                border: 2px solid $color-black-1000;
                > div {
                    background-color: $color-black-1000;
                }
            }
        }
        &:hover .manage__query-list-item-checkbox {
            visibility: visible;
            transition: visibility 0.5s linear;
        }
        .manage__query-list-item-checkbox {
            cursor: pointer;
            @include square($checkbox-size);
            margin-right: $spacing-s;
            border: 2px solid $color-black-500;
            border-radius: 2px;
            display: flex;
            visibility: hidden;
            justify-content: center;
            align-items: center;
            > div {
                cursor: pointer;
                @include square($checkbox-inner-size);
            }
            &--visible {
                visibility: visible;
            }
        }
        .manage__query-list-item-remove {
            flex-shrink: 0;
            visibility: hidden;
            margin-left: $spacing-xs;
            cursor: pointer;
            color: $color-black-500;
            &:hover {
                color: $color-black-700;
            }
        }
        &:hover .manage__query-list-item-remove {
            visibility: visible;
            transition: visibility 0.5s linear;
        }
        .manage__query-list-item {
            &__count {
                $size: 15px;
                @include kare-overline-3();
                background-color: $color-white-1000;
                border-radius: 2px;
                margin-left: 8px;
                height: $size;
                line-height: $size;
                text-align: center;
                padding: 0 4px;
            }
        }
    }
    &__query-list-item {
        border-radius: 10px;
        background-color: $color-grey-1000;
        @include kare-body();
        color: $color-text-light;
        display: flex;
        align-items: center;
        padding: $spacing-xs $spacing-s;
        color: $color-white-1000 !important;
    }
}
