@import 'conversations/index';
@import 'messages/index';
@import 'feedback/index';

.console-activity {
    padding: 0;
    display: flex;
    background-color: $color-black-25;
    height: 100vh;
}
