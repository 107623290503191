.BorderlessTextInput__container {
    height: 30px;
}

.BorderlessTextInput__input {
    border: none;
    margin-top: 5px;
    &:focus {
        outline: none;
    }
}
