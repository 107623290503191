.c-tooltip-overlay button.language-picker-option {
    cursor: pointer;
    display: flex;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
    padding: 0;
    margin-bottom: $spacing-xs;
    align-items: center;
    height: 20px;
    outline: 0;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover .language-picker-option-text {
        text-decoration: underline;
    }
    .flag-icon {
        margin-right: $spacing-xs;
        width: 15px;
    }
    .language-picker-option-active-icon {
        color: $color-purple-500;
    }
    .language-picker-option-text {
        @include kare-body();
        margin-right: $spacing-xs;
    }
}
