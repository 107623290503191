.message-cta__container {
    margin-left: 20%;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 #0000002e;
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    p {
        color: #263238;
        font-family: $global-text-font-stack;
        line-height: 33px;
        letter-spacing: normal;
        text-decoration: none;
        font-size: 14px;
        font-weight: normal;
    }
    .message-cta__body {
        a {
            color: #fff;
            background-color: $color-primary;
            border: 1px solid $color-primary;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px;
            height: 30px;
            cursor: pointer;
            border-radius: 3px;
            text-decoration: none;
        }
    }
}
