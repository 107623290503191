.extension-creation-modal {
    display: flex;
    flex-direction: column;
    h2 {
        @include kare-h4();
        font-size: 18px;
        margin-bottom: $spacing-m;
    }
    h3 {
        @include kare-button-2-text();
        font-weight: bold;
    }
    &__content {
        display: flex;
    }
    &__responses,
    &__detail {
        height: 646px;
        padding: 0 $spacing-xs;
    }
    &__detail {
        textarea {
            height: 160px;
            width: 100%;
            border: 1px solid $color-grey-500;
            border-radius: 2px;
            background-color: $color-grey-300;
        }
        input[type='text'] {
            height: 30px;
            border: 1px solid $color-grey-500;
            border-radius: 2px;
            padding-left: 10px;
            width: 100%;
            margin-top: 5px;
        }
    }
    &__responses {
        flex: 2;
        overflow: hidden;
        .extension-creation-modal__responses-search {
            position: relative;
            input {
                padding: $spacing-xs $spacing-xs $spacing-xs
                    $spacing-m;
                outline: none;
                border: $border-light;
                width: 100%;
                border-radius: 3px;
            }
            margin-top: $spacing-xs;
            margin-bottom: $spacing-xs;
            label {
                svg {
                    color: $color-black-700;
                }
                position: absolute;
                left: $spacing-xs;
                top: $spacing-xs;
            }
        }
    }
    &__create-button {
        position: absolute;
        bottom: $spacing-xs;
        right: $spacing-s;
    }

    &__response {
        display: flex;
        padding: 10px;
        align-items: center;
        cursor: pointer;
        &:hover {
            span {
                color: $color-black-1000;
            }
            svg {
                fill: $color-black-1000;
            }
        }
        &.selected {
            &,
            &:hover {
                span {
                    color: $color-secondary;
                }
                svg {
                    fill: $color-secondary;
                }
            }
        }
        svg {
            fill: $color-black-700;
        }
        span {
            @include kare-button-2-text();
            text-transform: none;
            margin-left: $spacing-xs;
        }
    }

    &__radio-group {
        margin-left: 10px;
        label {
            margin-bottom: 10px;
        }
    }

    &__detail {
        flex: 3;
        border-left: 1px solid $color-grey-700;

        .extension-creation-modal__detail-input-wrapper {
            margin: $spacing-xs 0;
            input {
                width: 100%;
                height: $spacing-m;
                padding: 0 $spacing-xs;
                border: $border-dark;
                border-radius: 3px;
            }
        }
        button {
            width: 100%;
        }
    }
    &__text {
        margin: 15px 0;
        display: flex;
        align-items: center;
        svg {
            margin: 0 10px;
        }
        span {
            color: #676f73;
            font-family: $global-text-font-stack;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
        }
    }
    &__selected-response {
        $icon-height: 22px;
        display: flex;
        align-items: flex-start;
        padding: $spacing-s 0;
        border-bottom: 1px solid $color-grey-700;

        &__title {
            @include kare-button-2-text();
            flex: 1;
            text-transform: none;
            display: flex;

            &.edited {
                flex-direction: column;
            }
            strong {
                @include kare-h6;
                line-height: 16px;
            }
        }

        &__chat,
        &__index {
            margin-right: $spacing-xs;
        }

        svg {
            fill: $color-grey-1000;
        }

        &__index {
            border: 1px solid $color-grey-1000;
            border-radius: 50%;
            @include square(20px);
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-grey-1000;
            font-size: 9px;
        }

        &__actions {
            display: flex;
            justify-content: center;
            align-content: center;
        }

        &__done {
            color: $color-secondary;
            font-weight: bold;
            text-transform: uppercase;
            line-height: $icon-height;
        }

        &__done {
            cursor: pointer;
        }
        &__edit,
        &__close {
            cursor: pointer;
            width: $icon-height;
            height: $icon-height;
        }
        &__edit {
            transform: scale(0.7);
        }
        &__edition {
            flex: 1;
            display: flex;
            flex-direction: column;
            &-input {
                position: relative;
                input {
                    width: 100%;
                    outline: none;
                    border: 0;
                    height: 22px;
                }
                svg {
                    position: absolute;
                    right: $spacing-xs;
                    top: $spacing-xxs;
                    cursor: pointer;
                }
                span {
                    opacity: 0.8;
                }

                strong {
                    @include kare-h6;
                    @include heightLine(16px);
                }
            }
        }
    }
}
