/* ==========================================================================
  # TOOLTIP
  ========================================================================== */
.c-tooltip-overlay {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > * {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    ul {
        list-style-type: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            min-width: 150px;
            cursor: pointer;
            align-items: center;
            height: $spacing-m;
            @include kare-h7();
            svg {
                margin-right: $spacing-s;
            }
            &:hover {
                color: $color-text-dark;
            }
        }
    }
    .c-btn {
        font-size: $font-size-m;
        color: #322D37;
        width: 100%;
        margin: 0;
    }
}
.rc-tooltip-content {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    overflow: hidden;
}
// The styles that follow are based on rc-tooltip boostrap-white theme
.rc-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    background-color: $color-background;
    border-radius: 3px;
    &.rc-tooltip--is-simple {
        .rc-tooltip-inner {
            padding: 0;
        }
    }
}
.rc-tooltip-hidden {
    display: none;
}
.rc-tooltip-inner {
    padding: $spacing-xs;
    text-align: left;
    text-decoration: none;
    background-color: $color-background;
    border-radius: 3px;
    min-height: 34px;
    min-width: 170px;
}
.rc-tooltip--no-inner-padding .rc-tooltip-inner {
    padding: 0;
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: -5px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: $color-text-dark;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
    bottom: 1px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: $color-text-light;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: $color-text-dark;
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
    left: 1px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: $color-text-light;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: -5px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: $color-text-dark;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
    right: 1px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: $color-text-light;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: 15%;
    margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: -5px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: $color-text-dark;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
    top: 1px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: $color-text-light;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: 15%;
}
