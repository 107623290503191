@import 'document-portal/index';
@import 'documents-table/index';
@import 'library-filters/index';
.knowledge-base__toolbar-left {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.knowledge-base-search__header {
    display: flex;
    button {
        margin-left: 20px;
    }
}
