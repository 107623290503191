@import 'form/index';

.automations-container {
    display: flex;
    flex-flow: column;
    margin-top: $spacing-m;
}

.automations-row {
    display: flex;
    flex-flow: row;
    margin-bottom: $spacing-m;
}

.automations-col-1 {
    color: $color-black-500;
    width: 200px;
    margin-right: $spacing-s;
    text-align: right;
}

.automations-col-2 {
    width: 100%;

    > a, > button {
        margin-top: $spacing-xs;
        width: min-content;
    }
}

.automations-hostname-container {
    display: flex;
    flex-flow: column;
}

.automations-hostname {
    margin-top: $spacing-xxs;
}

.automations-hostname {
    display: flex;
    flex-flow: row;
    align-items: center;
    > span {
        @include kare-h1();
        font-size: 16px;
        margin-right: $spacing-xl;
    }
}

.automations-actions {
    color: $color-purple-500;
    font-weight: bold;
}
