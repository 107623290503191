@import './src/app/styles/kss/spacings';

.OpenRateChart__chartContainer {
    height: 165px;
}

.OpenRateChart__chartAndLegendContainerColumn {
    margin-top: $spacing-m;
}

.OpenRateChart__chartAndLegendContainerRow {
    height: 165px;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    margin-top: $spacing-m;
    > div:first-child {
        flex: 1;
    }
    > div:last-child {
        flex: 2;
    }
}
