/* ==========================================================================
   # PANEL SUMMARY
   ========================================================================== */

.knowledge-base__panel-summary {
    @include kare-body();
    padding: $spacing-s;
    display: block;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        background-color: $color-surface-dark;
        text-decoration: none;
        color: inherit;
    }
}

.knowledge-base__panel-summary-title {
    @include kare-h6();
    margin-bottom: 0;
    display: flex;
}

.knowledge-base__panel-summary-attached-label {
    @include kare-caption();
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.knowledge-base__panel-summary--has-tags {
    .knowledge-base__panel-summary-title {
        margin-bottom: 24px;
    }
}

$attachedDocumentBackgrooundColor: rgba($color-secondary, 0.2);

.knowledge-base__panel-summary--is-document-attached {
    background-color: $attachedDocumentBackgrooundColor;
    .knowledge-base__panel-summary-title {
        color: $color-text-dark;
    }
    &:hover {
        background-color: $attachedDocumentBackgrooundColor;
    }
}

.knowledge-base__panel-summary-attached-icon {
    margin-right: $spacing-xxs;
}
