.attachment-creation-modal {
    background: white;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: flex-start;
    .attachment-creation-modal-entry-container {
        border-bottom: 1px solid #676f73;
        margin-bottom: 8px;
    }
    .attachment-creation-modal-add-new-attachment {
        border-bottom: 1px solid #676f73;
        padding-bottom: 8px;
        margin-bottom: 10px;
    }
    .attachment-creation-modal-entry {
        display: flex;
        justify-content: flex-start;
        height: 30px;
        color: #676f73;
        font-size: 15px;
        text-transform: uppercase;
        align-items: center;
        svg {
            width: 17px;
            height: 17px;
            cursor: pointer;
        }
        svg:first-child {
            margin-right: 5px;
            cursor: unset;
        }
        svg:nth-child(2) {
            margin-left: auto;
            margin-right: 10px;
        }
    }
    .attachment-creation-modal-entry-input {
        margin-bottom: 10px;
    }

    .attachment-creation-modal-buttons {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }
}
