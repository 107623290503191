@import 'queries-response-list/index';
@import '../common';

.queries-response-container {
    flex: 1;
    min-width: 0;
    height: 100%;
    .kare-block {
        margin-bottom: 20px;
        &:first-of-type {
            margin-top: 20px;
        }
    }
    .queries-response-container__list-documents {
        height: 100%;
        margin-left: $response-content-margin-left;
    }
}
