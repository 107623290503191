$circle-size: 40px;
.message-cx__feedback-question-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    & > div {
        flex: 1;
        margin-bottom: 8px;
    }
    .message-cx__feedback-icon-wrapper {
        height: $circle-size;
        width: $circle-size;
        max-width: $circle-size;
        max-height: $circle-size;
        margin-right: 8px;
        .message-cx__feedback-icon-nps-circle {
            position: relative;
            background: transparent;
            border-radius: 50%;
            width: $circle-size;
            height: $circle-size;
            max-width: $circle-size;
            max-height: $circle-size;
            transition: all 0.3s ease;
            cursor: pointer;
            border: 1px solid transparent;
            span {
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-size: 14px;
                font-weight: 400;
                color: #fff;
                transition: all 0.3s ease;
            }
            &.danger {
                background: $color-red-1000;
            }
            &.warning {
                background: $color-yellow-1000;
            }
            &.success {
                background: $color-green-1000;
            }
        }
        .message-cx__feedback-icon-ces,
        .message-cx__feedback-icon-csat {
            width: 100%;
            height: 100%;

            svg {
                border-radius: 50%;
            }
            &.selected.danger-dark svg {
                background-color: $color-red-1200;
            }
            &.selected.danger svg {
                background: $color-red-1000;
            }
            &.selected.danger-light svg {
                background-color: #dc5158;
            }
            &.selected.warning svg {
                background: $color-yellow-1000;
            }
            &.selected.warning-light svg {
                background-color: #fccd57;
            }
            &.selected.success svg {
                background: $color-green-1000;
            }
            &.selected.success-light svg {
                background-color: #33c779;
            }
        }
    }
    .message-cx__feedback-question-wrapper {
        .message-cx__feedback-question {
            font-size: 14px;
            font-weight: 600;
            color: $color-black-1000;
        }
    }
}
