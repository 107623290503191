$icon-size: 60px;
$icon-margin: 8px;

.csat-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    .csat-item {
        width: calc(100% / 3);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        .csat-icon-wrapper {
            margin: $icon-margin;
            width: $icon-size;
            height: $icon-size;
            max-height: $icon-size;
            max-width: $icon-size;
            flex: 0 1 calc((100% / 3) - (#{$icon-margin} * 2));
            border-radius: 50%;
            transition: all 0.3s ease;
            cursor: pointer;

            img {
                width: $icon-size;
                height: $icon-size;
            }

            &:hover {
                transition: all 0.3s ease;

                &.danger {
                    background-color: $color-red-1000;
                }

                &.warning {
                    background-color: $color-yellow-1000;
                }

                &.success {
                    background-color: $color-green-1200;
                }
            }
        }
        .csat-text-wrapper {
            margin: 0 $icon-margin;
            width: $icon-size -20px;
            max-width: $icon-size -20px;
            flex: 0 1 calc((100% / 3) - (#{$icon-margin} * 2));
            text-align: center;

            span {
                flex: 1;
                font-family: $global-text-font-stack;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: normal;
                text-decoration: none;
                text-align: center;
                text-overflow: ellipsis;
                color: #676f73;
                line-height: 20px;
                word-break: break-all;
            }
        }
    }
}

.csat.kare-cx-block-wrapper {

    h1 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 600;
        margin-bottom: 15px;
    }
}
