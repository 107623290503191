.c-field.checkbox-field {
    padding-left: 0;
    padding-right: 0;
}

.checkbox-field-container {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-s;

    label {
        cursor: pointer;
        color: $color-black-900;
    }
}

.checkbox-field-input {
    position: relative;
    width: 18px;
    height: 18px;
    appearance: none;
    border: 2px solid $color-grey-lighter;
    border-radius: 4px;
    cursor: pointer;
    flex-shrink: 0;
    margin: 0 $spacing-xs 0 0;

    &:disabled {
        border-color: $color-black-25;
        cursor: not-allowed;

        & + label {
            color: $color-black-25;
            cursor: not-allowed;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: $color-black-1000;
        transform: translate(-50%, -50%) scale(0);
    }

    &:checked {
        border-color: $color-black-900;
        &::after {
        transform: translate(-50%, -50%) scale(1);
        }
    }
}
