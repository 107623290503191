@import '~react-dates/lib/css/_datepicker.css';
@import 'single-date-picker-field/index';
.dashboard--date-picker {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    text-transform: uppercase;
    max-width: $container-max-width;
    h2 {
        @include kare-h2();
        align-self: flex-start;
        flex-grow: 2;
    }
    label {
        width: 160px;
    }

    .SingleDatePickerInput,
    .DateInput,
    .DateInput_input {
        border: 0;
        background-color: transparent;
        width: 100px;
    }
    span,
    input,
    select {
        @include kare-overline-3();
        color: $color-grey-1000;
        height: 25px;
    }
    select {
        width: 100px;
        margin-top: 2px;
    }
    .DateInput_input {
        line-height: 1.5;
    }
    .DateInput_input,
    select {
        transition: $global-transition;
        border-bottom: 1px solid $color-grey-1000;
        padding: 0 0 0 6px;
        cursor: pointer;
        color: rgba($color-grey-1000, 0.7);
        &:hover {
            color: $color-grey-1000;
        }
    }
    span {
        margin: 7px 12px 0 20px;
    }
    .c-field-error {
        display: none;
    }
    .c-label-field--floating-active {
        margin-top: 0;
    }
    .dashboard--chevron {
        transform: translateX(-100%) translateY(2px);
        color: $color-grey-1000;
        pointer-events: none;
    }
    .DateRangePicker {
        z-index: 999;
    }
    .DateRangePickerInput {
        border: 0;
    }
    .DateRangePickerInput_arrow_svg {
        @include square(18px);
    }
}
