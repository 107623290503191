@import './src/app/styles/kss/colors';

.BlockNodeWidget__choiceNode {
    padding: 10px 14px 10px 30px;
    width: 200px;
    border: 1px solid $color-grey;
    border-radius: 2px;
    background-color: #ffffff;
    &.BlockNodeWidget__noTitle {
        margin-left: 100px;
        transform: translateX(-50%);
        width: 36px;
        height: 36px;
        padding: 0;
    }
    span {
        color: $color-grey;
        font-size: 12px;
    }
    .BlockNodeWidget__topPortAttachment,
    .BlockNodeWidget__bottomPortAttachment {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        svg,
        path {
            fill: $color-grey;
        }
    }
    .BlockNodeWidget__topPortAttachment {
        top: -13px;
    }
    .BlockNodeWidget__bottomPortAttachment {
        bottom: -13px;
    }
    .BlockNodeWidget__ports {
        .BlockNodeWidget__portIn {
            & > div {
                bottom: 0;
            }
        }
    }
    .BlockNodeWidget__infoIcon svg {
        position: absolute;
        left: 10px;
        top: 13px;
        fill: $color-grey;
        transform: rotate(180deg);
    }
}
.BlockNodeWidget__container {
    position: relative;
    cursor: pointer;
    .BlockNodeWidget__innerContainer {
        min-height: 80px;
    }
    &.BlockNodeWidget__response {
        .BlockNodeWidget__innerContainer {
            background-color: $color-purple-600;
        }
    }
    &.BlockNodeWidget__response.published {
        .BlockNodeWidget__innerContainer {
            background-color: $color-purple-600;
        }
    }
    &.BlockNodeWidget__response.unpublished {
        .BlockNodeWidget__innerContainer {
            background-color: #1f6a9d;
        }
    }
    &.BlockNodeWidget__response.chattering {
        .BlockNodeWidget__innerContainer {
            background-color: #00b957;
        }
    }
    &.BlockNodeWidget__content {
        .BlockNodeWidget__innerContainer {
            background-color: #d3262e;
        }
        .BlockNodeWidget__title {
            color: white;
        }
        &.BlockNodeWidget__published {
            .BlockNodeWidget__typeIconContainer {
                svg {
                    fill: #000;
                }
            }
            .BlockNodeWidget__innerContainer {
                background-color: #fbc02d;
            }
            .BlockNodeWidget__title {
                white-space: normal;
                word-break: break-all;
                color: black;
            }
        }
        &:hover {
            &.BlockNodeWidget__published {
                .BlockNodeWidget__innerContainer {
                    background-color: #fbc02d;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    .BlockNodeWidget__innerContainer {
        border-radius: 2px 2px 0 0;
    }
    .BlockNodeWidget__topPortAttachment,
    .BlockNodeWidget__bottomPortAttachment {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        svg,
        path {
            fill: $color-grey;
        }
    }
    .BlockNodeWidget__topPortAttachment {
        top: -13px;
    }
    .BlockNodeWidget__bottomPortAttachment {
        bottom: -13px;
    }
    .BlockNodeWidget__menu {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 20px;
        height: 20px;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        z-index: 9999;

        span {
            margin-bottom: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:last-of-type {
                margin-bottom: 0;
            }
            &:hover {
                background-color: #fff;
                svg {
                    fill: #263238;
                    color: #263238;
                }
            }
        }

        &.BlockNodeWidget__true {
            span:first-of-type {
                background-color: #fff;
                svg {
                    fill: #263238;
                    color: #263238;
                }
            }
        }
        span {
            width: 17px;
            height: 17px;
            border-radius: 3px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #263238;
        }
        svg {
            fill: white;
            color: white;
        }
    }
    &:hover .BlockNodeWidget__menu {
        opacity: 1;
        visibility: visible;
    }
    .BlockNodeWidget__queriesContainer {
        color: white;
        text-transform: uppercase;
        margin-left: 30px;
        line-height: 30px;
        font-size: 10px;
        font-weight: bold;
        border-top: 1px solid white;
        position: relative;
        svg {
            color: white;
            fill: white;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            g {
                color: white;
                fill: white;
            }
        }
    }
    .BlockNodeWidget__editIcon {
        position: absolute;
        padding: 10px;
        top: 25px;
        right: 0;
    }
    .BlockNodeWidget__parentContainer {
        position: relative;
        &.BlockNodeWidget__content .BlockNodeWidget__parents {
            top: -47px;
        }
        .BlockNodeWidget__parents {
            position: absolute;
            padding: 10px;
            top: -70px;
            transform: translateX(25px);
            background-color: white;
            width: 400px;
            z-index: 9999999999;
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
            text-align: left;
            border-radius: 3px;
            .BlockNodeWidget__parentsTitle {
                background-color: $color-grey;
                margin-left: -10px;
                margin-right: -10px;
                margin-top: -10px;
                padding: 0 10px;
                line-height: 30px;
                height: 30px;
                color: white;
            }
            li {
                line-height: 30px;
                height: 30px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .BlockNodeWidget__typeIconContainer {
        position: absolute;
        left: 10px;
        top: 12px;
        fill: white;
        color: white;
    }
    .BlockNodeWidget__title {
        padding: 10px 20px 10px 30px;
        width: 200px;
        color: $color-white;
        overflow: hidden;
        font-size: 12px;
        font-weight: bold;
    }
    .BlockNodeWidget__blocksRow {
        display: flex;
        overflow: hidden;
        height: 26px;
        line-height: 26px;
        padding-left: 5px;
        align-items: center;
        background-color: white;
        border: 1px solid $color-grey;
        & > div {
            display: flex;
            align-items: center;
            height: 26px;
            line-height: 26px;
            margin-right: 10px;
            svg {
                margin-right: 3px;
                color: $color-grey;
                fill: $color-grey;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
.BlockNodeWidget__ports {
    pointer-events: none;
    .BlockNodeWidget__portIn {
        * {
            pointer-events: none;
        }

        & > div {
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .BlockNodeWidget__portOut {
        & > div {
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.BlockNodeWidget__choicesContainer {
    padding: 16px;
    border: 1px solid black;
    border-radius: 3px;
    position: relative;
}

.BlockNodeWidget__ports {
    .BlockNodeWidget__portIn {
        & > div {
            position: absolute;
            top: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .BlockNodeWidget__portOut {
        & > div {
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
