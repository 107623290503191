@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.missing__container {
    h1 {
        width: 100%;
        text-align: center;
        padding: $spacing-l 0;
    }
    .missing__subHeader {
        margin-top: $spacing-s;
        font-size: $font-size-s;
        line-height: 16px;

        color: #263238;
    }
    .missing__filters {
        display: flex;
        padding: $spacing-s 0;
        line-height: 25px;
        ul {
            display: flex;
            margin-left: $spacing-m;
            li {
                display: flex;
                margin-right: $spacing-m;
                align-items: center;
                cursor: pointer;
                &:hover {
                    .missing__filterCheckbox {
                        &:before {
                            background-color: $color-grey-light;
                            content: '';
                            position: absolute;
                            display: block;
                            height: 12px;
                            width: 12px;
                            top: 2px;
                            left: 2px;
                        }
                    }
                }
                .missing__filterCheckbox {
                    width: 20px;
                    height: 20px;
                    border: 2px solid black;
                    border-radius: 2px;
                    margin-right: $spacing-xxs;
                    position: relative;
                    &:before {
                        transition: 0.3s;
                    }
                    &.active {
                        &:before {
                            background-color: black;
                            content: '';
                            position: absolute;
                            display: block;
                            height: 12px;
                            width: 12px;
                            top: 2px;
                            left: 2px;
                        }
                    }
                }
                .missing__filterIcon {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: $spacing-xs;
                }
            }
        }
    }
    .missing__content {
        margin: 0 -35px;
    }
    ul {
        li.missing__clusterItem {
            padding: $spacing-s $spacing-m;
            border-top: 1px solid $color-grey-lighter;
            &:first-of-type {
                margin-top: $spacing-xs;
            }
            cursor: pointer;
            transition: background-color 0.15s ease;
            &:last-of-type {
                border-bottom: 1px solid $color-grey-lighter;
            }
            &:hover {
                background-color: $color-black-25;

                li {
                    background-color: #FFFFFF;
                }
            }
            .missing__responseIcon {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: $spacing-xs;
            }
            .missing__header {
                display: flex;
                align-items: center;
                text-transform: capitalize;
                font-weight: bold;
                strong {
                    margin-left: auto;
                }
            }
        }
    }
    .missing__responseContent {
        display: flex;
        .missing__queries {
            display: block;
            width: 60%;
            position: relative;
            ul {
                display: inline-block;
                width: 100%;
                li {
                    line-height: 30px;
                    margin-left: $spacing-xs;
                    border-bottom: 1px solid $color-grey-lighter;
                    background-color: #f6f5f2;
                    max-width: 100%;
                    display: block;
                    padding: 0 0 0 $spacing-xs;
                }
            }
            .missing__more {
                font-weight: bold;
                text-transform: capitalize;
                position: absolute;
                bottom: 20px;
                right: 0;
                transform: translateX(calc(100% + 10px)) translateY(50%);
            }
        }
        .missing__info {
            margin-left: auto;
            .missing__improveInfo {
                line-height: 30px;
                strong {
                    font-weight: bold;
                    color: #0094d7;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: $spacing-xs;
                    }
                }
            }
            .missing__choicesInfo {
                position: relative;
                span {
                    line-height: 30px;
                    svg {
                        margin-right: $spacing-xs;
                        transform: translateY(3px);
                    }
                    text-align: right;
                    font-weight: bold;
                    color: #0094d7;
                    display: block;
                }
                .missing__choicesMore {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    text-align: right;
                    font-weight: bold;
                    color: #0094d7;
                    transform: translateY(calc(100%));
                }
            }
        }
    }
}
