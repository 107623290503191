.actions-modal-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    padding: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: $color-white-1000;
    .actions-modal-content {
        display: flex;
        flex-flow: row;
        svg {
            position: absolute;
            cursor: pointer;
            right: 25px;
        }
    }
    iframe {
        width: 100%;
        height: calc(40vh);
        border: none;
    }
}

.actions-header {
    padding: 20px 24px 24px 36px;
}
