a.kare-nav-link,
.kare-nav-link {
    cursor: pointer;
    @include kare-button-1-text();
    color: $color-text-light;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    transition: $global-transition;
    align-items: center;
    height: 35px;
    padding-left: $spacing-s;
    &:hover {
        background-color: $color-purple-500;
        text-decoration: none;
        color: $color-text-light;
    }
    &.is-active {
        background-color: $color-purple-600;
    }
    span {
        margin: 0;
    }
    svg {
        display: block;
        margin: 0 $spacing-xs 0 0;
        &.more-vertical {
            margin: 0 $spacing-xs 0 auto;
        }
    }
    &.kare-nav-link--is-inverted {
        color: $color-text-dark;
        &.is-active {
            color: $color-text-light;
        }
        &:hover {
            color: $color-text-light;
        }
    }
}
