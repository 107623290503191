@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.BorderlessButton__button {
    color: $label-color;
    font-size: $font-size-s;
    cursor: pointer;
    &:hover {
        color: $label-color-hover;
    }
}
