.try-me-now-widget-custom-button {
    border-radius: 38px;
    top: unset;
    background: #069edf;
    display: flex;
    align-items: center;
    z-index: 2147483000;
    cursor: pointer;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24);
    transition: all 0.3s ease-in-out;
    position: fixed;
    border: none;
    outline: none;
    right: 20px;
    bottom: 85px;
    padding: 10px;
    color: white;
    &:hover {
        color: black;
        background: white;
    }
}

.widget {
    margin: 15px;
}
