div.knowledge-base__search-bar {
    .knowledge-base__search-bar__input-container {
        position: relative;
    }
    svg {
        position: absolute;
        left: 5px;
        margin: 0 !important;
        top: 4px;
        fill: $color-black-700;
    }
    .kare-text-input {
        width: 250px;
        margin-bottom: 0;
        input {
            padding-left: 35px;
            margin-right: 0;
            @include kare-body();
        }
    }
    a svg {
        right: 5px;
        top: 5px;
        left: auto;
    }
    .knowledge-base__filter-button {
        margin-left: $spacing-s;
    }
}
