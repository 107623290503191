@import '/src/app/styles/kss/colors';

.KareAvatarUpload__kareAvatar {
    label {
        color: #676f73;
        font-family: "Open Sans", arial, sans-serif;
        font-weight: 600;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: normal;
        text-transform: uppercase;
        text-decoration: none;
        display: block;
    }
    span {
        color: #263238;
        font-family: "Open Sans", arial, sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: normal;
        margin-bottom: 15px;
    }
    .KareAvatarUpload__kareAvatarWrapper {
        margin-top: 10px;
        display: flex;
        .KareAvatarUpload__kareAvatarOptions {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            justify-content: space-between;
            .KareAvatarUpload__kareAvatarInfo {
                span {
                    font-size: 9px;
                    color: #676f73;
                }
            }
            .KareAvatarUpload__kareAvatarActions {
                span {
                    font-size: 11px;
                    font-weight: 600;
                    cursor: pointer;
                }
                .KareAvatarUpload__changeImage {
                    position: relative;
                    overflow: hidden;
                    span {
                        color: $color-secondary;
                    }
                    .KareAvatarUpload__avatarInput {
                        opacity: 0;
                        z-index: 2;
                        position: absolute;
                        left: 0;
                        width: 83px;
                        cursor: pointer;
                    }
                }
                .KareAvatarUpload__removeImage {
                    color: $color-red-1000;
                }
                .KareAvatarUpload__divider {
                    color: #676f73;
                    margin: 0 10px;
                    font-size: 14px;
                }
            }
        }
        .KareAvatarUpload__kareAvatarUpload {
            display: flex;
            position: relative;
            width: 45px;
            height: 45px;
            border: lightgray 1px solid;
            border-radius: 50%;
            overflow: hidden;
            img {
                // Hack to bypass parents border
                min-height: calc(100% + 1px);
                min-width: calc(100% + 1px);
                margin-top: -1px;
            }
            .KareAvatarUpload__avatarInput {
                opacity:0;
                height: 100%;
                width: 100%;
                z-index: 2;
                cursor: pointer;
            }
            .KareAvatarUpload__addImage {
                position: absolute;
                width: 45px;
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                color: lightgray;
                svg {
                    height: 22.5px;
                    width: 22.5px;
                }
            }
        }
        }
    }

