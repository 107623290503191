.dashboard-activity-queries-summary {
    display: flex;
    margin-top: $spacing-xl;
    color: $color-text-dark;
    width: 360px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2 {
        @include kare-h3();
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing-xs;
        align-items: center;
        span {
            height: 24px;
        }
        span:first-child {
            flex: 1;
        }
        span:last-child {
            font-weight: 600;
            align-self: flex-end;
        }
    }
    p {
        @include kare-body();
        margin: $spacing-xs 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        span:last-child {
            align-self: flex-end;
        }
    }
    .dashboard-activity-queries-summary__field {
        svg {
            margin-right: $spacing-s;
            padding: 1px;
        }
        &.responses {
            svg {
                margin-left: 1px;
                color: $color-primary;
            }
        }
        &.disambiguations {
            svg {
                fill: $color-secondary;
            }
        }
        &.missing {
            svg {
                color: $color-error;
            }
        }
        span {
            font-weight: 600;
        }
        span:first-of-type {
            flex: 1;
        }
    }
    .dashboard-activity-queries-summary__pie-chart {
        .dashboard-activity-queries-summary__cell {
            &.responses {
                fill: $color-primary;
            }
            &.disambiguations {
                fill: $color-secondary;
            }
            &.missing {
                fill: $color-error;
            }
        }
    }
    .dashboard-activity-queries-summary__resolved-ratio {
        font-size: 24px;
        font-weight: bold;
    }
    .dashboard-activity-queries-summary__resolved-ratio-description {
        font-size: 12px;
        font-weight: bold;
    }
    .dashboard-activity-queries-summary__fields {
        width: 200px;
    }
}
