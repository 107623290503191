/* ==========================================================================
   #PANEL ITEM
   ========================================================================== */

.c-panel__item {
    flex: 1;
    padding: $spacing-m;
    > h1 {
        @include kare-form-label();
    }
}
