@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.RadialChart__container {
    .RadialChart__hero {
        max-width: 100px;
        font-size: $font-size-max;
        text-align: center;
        margin-bottom: -47px;
        transform: translateY(-48px);
        margin-left: auto;
        margin-right: auto;
        strong {
            margin: $spacing-s 0;
            color: $color-grey;
            font-weight: 600;
            font-size: $font-size-s;
            display: block;
            text-transform: capitalize;
        }
    }
    .RadialChart__content {
        width: 100%;
        padding: $spacing-xs 0;
        display: flex;
        &.RadialChart__column {
            .RadialChart__right {
                padding: 0 $spacing-m;
                .RadialChart__labelContainer {
                    flex-direction: row;
                    align-items: center;
                    margin-top: 0;
                    margin-bottom: $spacing-xs;
                    padding-left: $spacing-s;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                .RadialChart__label {
                    margin-bottom: 0;
                    span {
                        font-size: $font-size-l;
                    }
                }
                strong {
                    margin-left: auto;
                    font-size: $font-size-m;
                }
            }
        }
    }
    .RadialChart__left,
    .RadialChart__right {
        flex: 1;
        flex-basis: 165px;
        height: 165px;
    }
    .RadialChart__right {
        display: flex;
        align-items: center;
        flex-direction: column;
        .RadialChart__label {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-xs;
            svg {
                width: $spacing-s;
            }
            span {
                color: #676f73;
                font-size: $font-size-s;
                margin-left: $spacing-xxs;
                text-transform: capitalize;
            }
        }
        .RadialChart__labelContainer {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: $spacing-m;
        }
        strong {
            color: #414141;
            font-size: $font-size-xxl;
            margin-left: $spacing-xxs;
            small {
                font-size: $font-size-s;
            }
        }
    }
    .RadialChart__aboveLegend,
    .RadialChart__belowLegend {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
            font-size: $font-size-m;
            &:last-of-type {
                font-weight: bold;
            }
        }
    }
    .RadialChart__aboveLegend {
        margin-bottom: $spacing-s;
    }
    .RadialChart__belowLegend {
        margin-top: $spacing-s;
        margin-bottom: 2px;
    }
}

.RadialChart__percentage {
    font-size: 1.4rem;
    margin-left: 2px;
}
