.ActionList__container {
    overflow-y: auto;
    max-height: 40vh;
    margin-bottom: 10px;
}

.ActionList__item {
    display: flex;
    color: #676f73;
    font-size: 1.5rem;
    align-items: center;
    height: 40px;

    &.ActionList__itemSelected {
        color: #0094d7;
    }

    &:hover {
        cursor: pointer;
        color: #0094d7;
    }

    svg {
        width: 15px;
        height: 15px;
        margin-right: 15px;
    }
}
