@import 'queries-card/index';
@import 'cluster-header/index';
@import 'documents-modal/index';

.manage-panel {
    display: flex;
    flex-flow: column;
    margin: unset;
    h1 {
        @include kare-h4();
        margin-bottom: $spacing-s;
        color: $color-purple-600;
    }
}

.manage__container {
    max-width: $container-max-width;
    margin: 0;
    h2 {
        @include kare-h2();
    }
}
.manage__spinner {
    height: 100%;
    display: flex;
    align-items: center;
}

.manage__queries-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    height: 100%;
}
