// TODO(rupertrutland): Sort out numbers and some fonts on this page with Oz.
$dashboardEmptyDigitWidth: $spacing-xs * 2;
$maxRowWidth: 718px;
.dashboard-empty-state {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    .dashboard-empty-state-body {
        flex-grow: 2;
        padding-left: 122px;
        padding-top: 92px;
        padding-right: $spacing-m;
        > h2 {
            margin-bottom: $spacing-m;
            @include kare-h5();
        }
        .dashboard-empty-state-row {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-m;
            max-width: $maxRowWidth;
            .c-btn {
                width: 175px;
                min-width: 175px;
            }
            .dashboard-empty-state-row-left {
                flex-grow: 2;
                margin-right: $spacing-l;
                h1 {
                    @include kare-h5();
                    display: flex;
                    span {
                        width: $dashboardEmptyDigitWidth;
                    }
                }
                p {
                    padding-left: $dashboardEmptyDigitWidth;
                }
            }
        }
    }

    .dashboard-empty-state-footer {
        padding: $spacing-xs 62px $spacing-xs 122px +
            $dashboardEmptyDigitWidth;
        background-color: $color-black-100;
        > p {
            max-width: $maxRowWidth - $dashboardEmptyDigitWidth;
        }
        .dashboard-empty-state-buttons {
            display: flex;
            .c-btn {
                width: 220px;
            }
        }
    }
    .step-complete-check {
        color: $color-primary;
        width: 175px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
    }
}
