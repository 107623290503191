

.kare-block__quote {
    overflow-x: auto;
    &-text {
        position: relative;
        margin-bottom: $spacing-s;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 4px;
            background-color: $color-grey-700;
            border-radius: 2px;
            left: 0;
        }
        border-radius: 2px;
        padding-left: $spacing-s;
    }
    &-title {
        color: $color-black-1000;
        display: flex;
        align-items: center;
        font-size: 16px;
        &-broken {
            color: $color-red-1000;
        }
        &a {
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
        &--missing {
            @include kare-overline-3();
            color: $color-error;
        }
        svg {
            margin-right: $spacing-xxs;
        }
    }
}
