.c-field {
    @include kare-body();
    border: none; /* [1] */
    min-height: $spacing-m;
    display: flex;
    // width: 100%;
    &:focus {
        outline: none; /* [1] */
    }
    &.has-warning {
        border-color: $color-warning !important;
    }
    &.is-invalid {
        border-color: $color-error !important;
    }
    &::placeholder {
        color: $color-grey-1000;
    }
}

.c-field--input {
    border-bottom: 1px solid $color-black-1000;
    background-color: transparent;
    &:active,
    &:focus {
        border-color: $color-secondary;
    }

    &[readonly] {
        border-color: $color-grey-300;
    }
}

.c-icon-field {
    display: flex;
    align-items: center;
    input {
        margin: 0 $spacing-s 0 0;
    }
    svg {
        vertical-align: middle;
        fill: $color-black-1000;
        cursor: pointer;
        margin: 0 $spacing-s 0 0;
    }
}

.c-field-error,
.c-field-warning {
    @include kare-caption();
    margin: $spacing-s 0 0 0;
    text-align: right;
}

.c-field-error {
    color: $color-error;
}

.c-field-warning {
    color: $color-warning;
}

.form-row {
    margin-bottom: $spacing-m;
}
