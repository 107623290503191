@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import 'navigation/index';
@import 'prompt-action-banner/index';
@import 'kare-notifications-system/index';

.layouts__main-layout {
    display: flex;
    height: 100%;
    main {
        height: 100%;
        display: flex;
        background-color: $color-background;
        overflow: auto;
        flex-grow: 2;
        position: relative;
        flex-direction: column;
    }
    &.layouts__main-layout--has-prompt-banner {
        main {
            padding-top: $spacing-l;
        }
    }

    .loadingWrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
