.superadmin-title {
    display: flex;
    justify-content: center;
    span {
        line-height: 50px;
        font-size: 14px;
    }
}
.superadmin-spinner {
    height: 200px;
}
