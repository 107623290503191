.dashboard-response-query-feedback {
    position: absolute;
    right: -7px;
    bottom: -5px;
    height: 20px;
    width: 20px;
    background-color: $color-background;
    box-shadow: 0 1px 2px 0 rgba($color-black-1000, 0.18);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-response-query-feedback--positive {
    color: $color-primary;
}

.dashboard-response-query-feedback--negative {
    color: $color-error;
}
