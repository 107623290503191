.kare-dropdown-container {
    margin-bottom: $spacing-m;
    label {
        @include kare-form-label();
    }
}

div .kare-dropdown {
    z-index: 1000;
    position: absolute;

    &__control {
        border: 1px solid $color-black-700;
        border-radius: 4px;
        background-color: $color-background;
        cursor: pointer;
        &--is-focused {
            outline: 0;
            box-shadow: none;
        }
        &:hover {
            border: 1px solid $color-black-700;
            border-radius: 2px;
            background-color: $color-grey-500;
        }
        svg {
            transition: transform 0.25s ease;
            fill: $color-black-700;
            margin-left: 10px;
        }
    }
    &__control--is-focused {
        .dropdown__indicator svg.rotate {
            transform: rotate(180deg);
        }
    }
    &__indicator-separator {
        display: none;
    }
    &__menu {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12),
            0 8px 8px 0 rgba(0, 0, 0, 0.24);
        border-radius: 2px;
        background-color: $color-background;
        margin-top: 0;
    }
    &__menu-list {
        padding: 0;
    }
    &__option {
        color: $color-black-700;
        cursor: pointer;
        svg {
            margin-right: 10px;
        }
    }
    &__option--is-focused {
        background-color: $color-grey-500;
    }
    &__option--is-selected {
        background-color: $color-secondary;
        color: $color-text-light;
    }
}
