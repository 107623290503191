$circle-size: 30px;
$circle-margin: 2px;

.message-cx__nps-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > div {
        flex: 1;
    }
    .message-cx__nps-title-container {
        margin: 5px 0;
        .message-cx__nps-title {
            font-size: 14px;
            color: $color-black-1000;
            margin-bottom: 5px;
        }
    }
    .message-cx__nps-circle-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .message-cx__nps-circle-wrapper {
            width: 100%;
            height: 100%;
            margin: $circle-margin;
            flex: 0 1 calc((100% / 6) - (#{$circle-margin} * 2));
            .message-cx__nps-circle {
                position: relative;
                border: 1px solid #263238;
                background: transparent;
                border-radius: 50%;
                width: $circle-size;
                height: $circle-size;
                max-width: $circle-size;
                max-height: $circle-size;
                transition: all 0.3s ease;
                span {
                    box-sizing: border-box;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    font-size: 12px;
                    font-weight: 400;
                    color: #263238;
                    transition: all 0.3s ease;
                }
                &.selected {
                    background: #263238;
                    transition: all 0.3s ease;
                    border: 1px solid transparent;
                    span {
                        color: #fff;
                        transition: all 0.3s ease;
                    }
                }
                &.selected.danger {
                    background: $color-red-1000;
                }
                &.selected.warning {
                    background: $color-yellow-1000;
                }
                &.selected.success {
                    background: $color-green-1000;
                }
            }
        }
    }
    .message-cx__nps-label-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .message-cx__nps-label-negative,
        .message-cx__nps-label-positive {
            flex: 1;
            font-family: $global-text-font-stack;
            font-weight: 400;
            font-size: 10px;
            letter-spacing: normal;
            text-decoration: none;
            height: 20px;
            max-width: 50%;
            color: #676f73;
            line-height: 20px;
        }
        .message-cx__nps-label-positive {
            text-align: right;
        }
    }
}
