.SubmitControls__container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;
}

.SubmitControls__button {
    width: 90px;
    margin-left: 10px;
}
