.ErrorLoadingWrapper__loading,
.ErrorLoadingWrapper__empty,
.ErrorLoadingWrapper__error {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ErrorLoadingWrapper__error h3 {
    font-weight: bold;
}
