@import 'message-factory/index';

$activity-messages-header-panel-height: 65px;
.console-activity-messages {
    flex-grow: 1;
    height: 100%;
    border-left: 1px solid $color-black-100;
    max-width: 360px;
    position: relative;
    @include kare-caption();
    overflow-x: hidden;
    padding-bottom: 10px;
    .console-activity-messages__info {
        z-index: 999999;
        background-color: $color-white-1000;
        padding: $spacing-xs;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12),
            0 8px 8px 0 rgba(0, 0, 0, 0.24);
        &__header {
            > span:first-child {
                display: flex;
                align-items: center;
                svg {
                    margin-right: $spacing-xxs;
                }
            }
            display: flex;
            flex-direction: column;
            position: relative;
            strong {
                @include kare-button-2-text();
            }
        }
        &__content {
            margin-top: 0;
            overflow: hidden;
            & > div:not(:first-of-type) {
                margin-top: $spacing-xs;
            }

            &-single {
                display: flex;
                flex-direction: column;
                color: $color-grey;
                word-wrap: break-word;
            }
            &-multiple {
                display: flex;
                flex-direction: row;
                color: $color-grey;
                & > div {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
            }
        }
    }
    .console-activity-messages__info {
        &-labels {
            .kare-label {
                & {
                    margin-bottom: 5px;
                }
                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
        &__content {
            padding-top: $spacing-xs;
            margin-top: $spacing-xs;
            max-height: none;
            border-top: 1px solid $color-black-100;
            strong {
                @include kare-button-2-text();
                color: $color-black-900;
            }
        }
        .console-activity-messages__info__header {
            display: flex;
            justify-content: space-between;
            flex-flow: row;
            &-detail-text {
                color: $color-black-1000;
            }
            &-user-visitor {
                color: $color-black-1000;
            }
        }
    }
    .scrollbar-container {
        padding: $spacing-s;
    }
    &__error {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $spacing-l;
    }
    &__spinner {
        height: 100%;
        display: flex;
        align-items: center;
    }

    time.console-activity-message-timestamp {
        bottom: -$spacing-xs;
        margin-top: $spacing-s;
        @include kare-h8();
        position: absolute;
        color: $color-text-light;
        background-color: $color-black-1000;
        padding: 0 $spacing-xs;
        @include heightLine($spacing-m);
        z-index: 1000;
    }
    .console-activity--in {
        time.console-activity-message-timestamp {
            left: 0;
            &:after {
                @include triangle(
                    left,
                    $spacing-m,
                    8px,
                    $color-black-1000
                );
            }
        }
    }
    .console-activity--out,
  .console-activity-message--out,
  // TODO(rupertrutland): What is the difference between console-activity--out and console-activity-message--out?c
  .message-user-choice__container {
        time.console-activity-message-timestamp {
            right: 0;
            &:after {
                @include triangle(
                    right,
                    $spacing-m,
                    8px,
                    $color-black-1000
                );
            }
        }
    }
}
.console-activity-messages__container {
    position: relative;
    height: 100%;
    padding: $spacing-s $spacing-s $spacing-s 0;
    & > div:last-of-type {
        padding-bottom: 36px;
    }
    .console-activity-messages__escalated-rule {
        @include kare-overline-3();
        color: $color-error;
        display: flex;
        align-items: center;
        border-left: 4px solid $color-error;
        span {
            margin: 0 $spacing-s;
            margin-left: 6px;
            display: flex;
            align-items: center;
            svg {
                background-color: $color-error;
                color: $color-white-1000;
                padding: 2px;
                border-radius: 50%;
                margin-right: $spacing-xxs;
            }
        }
        .console-activity-messages__escalated-rule-line {
            display: none;
            flex-grow: 2;
            height: 1px;
            background-color: $color-error;
        }
    }
}
.console-activity-messages-status {
    text-transform: capitalize;
    font-weight: 1000;
    &__escalated {
        color: $color-danger;
    }
    &__handedover {
        color: #8fb5ce;
    }
    &__resolved {
        color: $color-dataviz-green;
    }
    &__unresolved {
        color: #676f73;
    }
}

.console-activity-messages-scroll-to-bottom {
    height: 100px;
    width: 100%;
    display: block;
}
