@import 'conversation/index';


$headerHeight: 88px;
$subHeaderHeight: 26px;

$columnWidth1: 20%;
$columnWidth2: 7.5%;
$columnWidth3: 7.5%;
$columnWidth4: 7.5%;
$columnWidth5: 7.5%;
$columnWidth6: 7.5%;
$columnWidth7: 7.5%;
$columnWidth8: 7.5%;
$columnWidth9: 7.5%;
$columnWidth10: 15%;
$columnWidth11: 7.5%;
$columnWidth12: 7.5%;
$columnWidth13: 7.5%;

.console-activity-conversations {
    &__no-more,
    &__load-more {
        width: 100px;
        margin: 20px auto 0;
    }
    header {
        display: flex;
        justify-content: space-between;
        padding: $spacing-s;
        h1 {
            @include kare-h3();
            font-weight: 700;
            margin: 0;
            color: $color-black-1000;
            line-height: 24px;
            h3 {
                @include kare-h5();
                font-size: 10px;
            }
        }
    }
    background-color: $color-background;
    height: 100%;
    flex: 1;
}

.timeFrame {
    @include kare-h5();
    font-size: 10px;
    color: $color-black-1000;
    margin: 0;
}

.console-activity-conversations-list {
    height: calc(100% - #{$headerHeight + $subHeaderHeight});
    &__column-loading-more {
        display: block;
        margin: 0 auto;
        padding: $spacing-s 0;
        @include kare-overline-2();
        text-align: center;
    }
}

.activity-refresh-container {
    display: flex;
    align-items: center;
    color: $color-grey-1000;
    &--not-refreshing {
        cursor: pointer;
        &:hover {
            color: $color-text-dark;
        }
    }
    &__refreshing-icon {
        &--refreshing {
            animation: refreshing-animation 2s infinite;
        }
    }
    h6 {
        @include kare-h5();
        margin-bottom: 0;
        margin-left: 4px;
        user-select: none;
    }
}

@keyframes refreshing-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.console-activity-conversations-list__header {
    height: $subHeaderHeight;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: $border-light;
    border-bottom: $border-light;
    font-weight: 600;
    padding: 0 $spacing-s;
    .console-activity-conversations-list__column {
        @include kare-button-2-text();
        text-transform: uppercase;
        font-size: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg:hover {
            cursor: pointer;
        }
    }
    .console-activity-conversations-list__column-1 {
        padding-left: 19px;
        justify-content: start;
    }
}
.console-activity-conversations-list__column.active {
    color: black;
    font-weight: bold;
}
.console-activity-conversations-list__column {
    @for $i from 3 through 11 {
        &-#{$i} {
            cursor: pointer;
        }
    }

    &-1 {
        @include truncateText($columnWidth1);
        width: $columnWidth1;
    }
    &-2 {
        width: $columnWidth3;
        text-align: center;
    }
    &-3 {
        width: $columnWidth4;
        text-align: center;
    }
    &-4 {
        width: $columnWidth5;
        text-align: center;
    }
    &-5 {
        width: $columnWidth6;
        text-align: center;
    }
    &-6 {
        width: $columnWidth7;
        text-align: center;
    }
    &-7 {
        width: $columnWidth8;
        text-align: center;
    }
    &-8 {
        width: $columnWidth8;
        text-align: center;
    }
    &-9 {
        width: $columnWidth9;
        text-align: center;
    }
    &-10 {
        width: $columnWidth10;
        text-align: center;
    }
    &-11 {
        width: $columnWidth11;
        text-align: center;
    }
    &-12 {
        width: $columnWidth12;
        div {
            @include truncateText(100%);
        }
        text-align: right;
    }

    &.console-activity-conversations-list__user-id {
        display: flex;
        justify-content: flex-start;
        svg {
            margin-right: $spacing-xxs;
        }
        span {
            @include kare-caption();
            color: #263238;
            font-weight: 600;
        }
    }
}

.console-activity-conversations-list-status {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 1000;
    &__escalated {
        color: $color-dataviz-red;
    }
    &__handedover {
        color: $color-dataviz-blue;
    }
    &__resolved {
        color: $color-dataviz-green;
    }
    &__unresolved {
        color: $color-black-300;
    }
}
