.settings__widget-form {
    .widget-appbar-actions label.kare-switch:last-of-type {
        display: inline-flex;
        margin-top: -15px;
        & + button {
            display: inline-block;
            margin-top: -15px;
        }
    }
    .css-warning {
        display: block;
        font-size: 0.7em;
    }
    .settings_widget-form-columns {
        display: flex;
        .settings_widget-form-first-column {
            margin-right: $spacing-m;
            flex-grow: 1;
        }
    }
    form {
        flex-grow: 1;
    }

    .submit-button-container {
        display: flex;
        justify-content: flex-end;
    }
}

// POPOVER
$component-max-width: 400px;
$popover-shadow-blur: 8px;
$popover-triangle-size: 8px;
$popover-shadow-main-color: rgba(0, 0, 0, 0.24);
$popover-shadow-secondary-color: rgba(0, 0, 0, 0.12);
.launcher-popover {
    .launcher-scroll-options {
        margin-bottom: 40px;
        margin-top: 15px;
        .radio-input {
            margin-bottom: 10px;
        }
        label span {
            margin-right: 15px;
        }
    }
    position: relative;
    margin-top: $spacing-s;
    .kare-switch {
        display: flex;
        margin: $spacing-s 0;
        span {
            order: 1;
        }
    }
    input {
        max-width: $component-max-width;
    }
    .launcher-popover__preview {
        position: absolute;
        right: 0;
        top: 0;
        &:after {
            @include triangle(
                bottom,
                $popover-triangle-size,
                $popover-triangle-size,
                $color-white-1000
            );
            transform: rotate(90deg) translateY(-50%);
            position: absolute;
            bottom: auto;
            left: 100%;
            filter: drop-shadow(
                0 0 $popover-shadow-blur $popover-shadow-main-color
            );
        }
    }
}
.launcher-popover__preview {
    border-radius: 5px;
    background-color: $color-white-1000;
    box-shadow: 0 0 $popover-shadow-blur 0 $popover-shadow-secondary-color,
        0 $popover-shadow-blur $popover-shadow-blur 0 $popover-shadow-main-color;
    padding: $spacing-s $spacing-m;
    max-width: $component-max-width;
}

.widget-appbar-actions {
    margin-top: 40px;

    .title {
        display: inline-block;
        margin: 25px 0 0;
    }
}

.form-tabs-container {
    margin-top: 10px;
    display: flex;
    border-bottom: 1px solid #dfe1e1;
    margin-bottom: 15px;

    .form-tabs-item {
        margin-right: 20px;

        &._is-active {
            border-bottom: 1px solid #0287c3;
        }
    }
}

.widget-popover-selected-response {
    justify-content: flex-start;
    background-color: transparent;
    cursor: default;
    &:hover {
        color: #92989b !important;
    }
}

.widget-config-form-popover-config-container {
    display: flex;
    margin-left: 10px;
    > span {
        margin-right: 30px;
    }
    div {
        margin-right: 12px;
    }
}

.widget-popover-response-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: 1px solid #263238;
    padding: 10px;
    cursor: pointer;
    max-width: 400px;
    height: 35px;
    .widget-popover-response-icon-edit {
        margin-left: auto;
    }
    .widget-popover-response-icon-message {
        margin-right: 10px;
    }
    svg:first-child {
        cursor: unset;
    }
    svg {
        cursor: pointer;
    }
    .widget-popover-response-select-button {
        cursor: pointer;
        &:hover {
            color: grey;
        }
    }
}

.widget-popover-response-root {
    margin-top: 20px;
    margin-left: 10px;
}

.widget-popover-text-container {
    margin-top: 20px;
    margin-left: 10px;
}

.widget-webextensions-selector-container {
    max-height: 40vh;
    margin-bottom: 10px;
    overflow-y: auto;
}

.widget-form-webextension-links-list-container {
    max-height: 40vh;
    margin: 10px 0;
    padding-right: 5px;
    overflow-y: auto;
}

.widgetLabel {
    display: block;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    color: #676f73;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    margin: 20px 0 5px;
}

.smallInfo {
    display: block;
    color: #263238;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 6px;
}

@import 'widget-preview/index';
@import 'launcher-preview/index';
