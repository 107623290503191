@import './src/app/styles/kss/fonts';
@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.Impressions__container {
    .Impressions__empty {
        padding: $spacing-s;
        text-align: center;
    }
    .Impressions__tableTitle {
        display: flex;
        background-color: #fff;
        transform: translateY(-1px);
        padding-left: $spacing-s;
        border-bottom: 1px solid transparent;
        span {
            text-transform: uppercase;
            height: 30px;
            font-size: $font-size-xs;
            font-weight: bold;
            color: $color-grey;
            cursor: pointer;
            display: flex;
            align-items: center;
            svg {
                margin-left: $spacing-xxs;
            }
            svg:last-of-type {
                color: $color-grey;
            }
            &.active svg {
                color: $color-grey;
            }
        }
        .Impressions__title {
            flex: 1;
        }
        .Impressions__last {
            width: 50px;
            text-align: center;
        }
    }
    ul {
        li {
            display: flex;
            height: 30px;
            cursor: pointer;
            align-items: center;
            padding-left: $spacing-s;
            &:hover {
                background-color: rgba($color-grey-lightest, 0.2);
            }
            svg {
                margin-right: $spacing-xs;
                width: 16px;
                height: 16px;
            }
            & > div.Impressions__title {
                flex: 1;
            }
            .Impressions__count {
                margin-left: auto;
                width: 50px;
                text-align: center;
            }
        }
    }
}
.Impressions__labelsFilterContainer {
    display: flex;
    margin-left: $spacing-s;
    height: 30px;
    align-items: center;

    input::placeholder {
        font-family: $global-text-font-stack;
        font-size: $font-size-xs;
        color: $color-grey;
    }

    h4 {
        color: $color-grey;
        font-size: 10px;
        font-weight: bold;
        line-height: 24px;
        margin-right: 10px;
        margin-bottom: 0;
        text-transform: capitalize;
    }
}
