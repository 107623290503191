@import 'dashboard-activity-chart/index';
@import 'dashboard-activity-summary/index';
@import 'dashboard-activity-queries-summary/index';

.dashboard-activity__container {
    display: flex;
    flex-direction: column;
    max-width: $container-max-width;
}

.dashboard-activity__empty {
    font-size: 24px;
    color: $color-text-dark;
}
