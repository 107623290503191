// Breakpoints
$break-tablet-landscape: 768px;

@mixin responsiveLargeHeader {
    @media screen and (max-width: $break-tablet-landscape) {
        font-size: 16px;
        line-height: 22px;
    }
}

@mixin kare-h1 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: normal;
    text-decoration: none;
    @include responsiveLargeHeader();
}

.kare-h1 {
    @include kare-h1();
}

@mixin kare-h2 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightNormal;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: normal;
    text-decoration: none;
    @include responsiveLargeHeader();
}

.kare-h2 {
    @include kare-h2();
}

@mixin kare-h3 {
    color: $color-black-1000;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: normal;
    text-decoration: none;
    margin: 0;
    @include responsiveLargeHeader();
}

.kare-h3 {
    @include kare-h3();
}

@mixin kare-h4 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: normal;
    text-decoration: none;
}

.kare-h4 {
    @include kare-h4();
}

@mixin kare-h5 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightNormal;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: normal;
    text-decoration: none;
}

.kare-h5 {
    @include kare-h5();
}

@mixin kare-h6 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: normal;
    text-decoration: none;
}

.kare-h6 {
    @include kare-h6();
}

@mixin kare-h7 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightNormal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: normal;
    text-decoration: none;
}

.kare-h7 {
    @include kare-h7();
}

@mixin kare-h8 {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightBold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.15px;
    text-decoration: none;
}

.kare-h8 {
    @include kare-h8();
}
