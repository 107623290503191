@import '../message-globals';


.console-activity-messages .message-user-choice__container {
    @include kare-overline-2();

    .message-feedback-container {
        display: flex;
        flex-flow: column;
        p {
            font-weight: bold;
        }
        > a {
            display: flex;
            cursor: pointer;
            color: #676f73;
            align-items: center;
            span {
                float: none;
                margin-left: 0;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
    .message-user-choice {
        @include kare-button-2-text();
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        border-left: 4px solid #676f73;
        padding-left: $spacing-xs;
        margin-top: 10px;
        & + .console-activity-message-timestamp {
            left: 0;
            right: unset;
            bottom: -40px;
        }
        .message-user-choice__bubble {
            background-color: #676f73;
            padding: 6px 12px;
            border-radius: 10px;
            color: white;
        }
        &.green {
            color: $color-primary;
            border-left: 4px solid $color-primary;
        }
        &.red {
            color: $color-primary;
            border-left: 4px solid $color-primary;
        }
        & > div {
            margin-bottom: 0;
        }
        svg {
            margin-right: $spacing-xxs;
        }
        &__open-document {
            svg {
                margin-left: 0;
                margin-right: $spacing-xs;
            }
        }
        &.message-user-choice__escalate {
            color: $color-danger;
            div {
                font-weight: bold;
            }
            svg {
                background-color: $color-danger;
                color: $color-white-1000;
                padding: 2px;
                border-radius: 50%;
                margin-right: 2px;
            }
        }
        &.message-user-choice__opened-link {
            text-transform: none;
            color: $color-purple-500;
            .message-user-choice__opened-link-text {
                word-break: break-all;
            }
            a {
                color: $color-purple-500;
                text-decoration: none;
                word-break: break-all;
                p:last-child {
                    text-decoration: underline;
                }
            }
        }
        &.message-user-choice__response,
        &.message-user-choice__open-document {
            div div span {
                @include truncateText(300px);
            }
        }
        &.message-user-choice__default {
            border: 0;
        }
    }
}
.message-user-choice__container + .message-user-choice__container {
    margin-top: 10px;
}
