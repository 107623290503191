.dashboard-activity-summary {
    display: flex;
    margin-top: $spacing-xl;
    color: $color-text-dark;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
        @include kare-h3();
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing-xs;
        align-items: center;

        span {
            height: 24px;
        }

        span:first-child {
            flex: 1;
        }

        span:last-child {
            font-weight: 600;
            align-self: flex-end;
        }
    }

    p {
        @include kare-body();
        margin: $spacing-xs 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        span:last-child {
            align-self: flex-end;
        }
    }

    .dashboard-activity-summary__field {
        svg {
            @include square(18px);
            margin-right: $spacing-s;
            border-radius: 50%;
            padding: 1px;
        }

        &.resolved {
            svg {
                background-color: $color-primary;
                fill: white;
            }
        }

        &.escalated {
            svg {
                background-color: $color-error;
                fill: white;
            }
        }

        &.handover {
            svg {
                fill: $color-secondary;
                background-color: $color-secondary;
            }
        }

        &.bounced {
            // I'm sorry for this :( This icon is one our custom
            // svg and doesn't work like the others in terms of height
            // and with, so I have to adjust it manually to align it
            svg {
                color: $color-grey-1000;
                border-radius: 0;
                @include square(23px);
                padding: 0;
                transform: translateX(-1px);
            }

            span {
                transform: translateX(-4px) translateY(-2px);

                &:last-of-type {
                    transform: translateY(-2px);
                }
            }
        }

        span {
            font-weight: 600;
        }

        span:first-of-type {
            flex: 1;
        }
    }
    .dashboard-activity-summary__bounce-rate-pie-chart {
        margin-left: $spacing-l;

        .dashboard-activity-summary__bounce-rate-pie-chart-cell {
            &.engaged {
                fill: $color-primary;
            }

            &.bounced {
                fill: $color-grey-1200;
            }
        }
    }

    .dashboard-activity-summary__pie-chart {
        .dashboard-activity-summary__cell {
            &.resolved {
                fill: $color-primary;
            }

            &.escalated {
                fill: $color-error;
            }

            &.bounced {
                fill: $color-grey-1000;
            }

            &.handover {
                fill: $color-secondary;
            }
        }
    }

    .dashboard-activity-summary__resolved-ratio {
        fill: $color-primary;
        font-size: 24px;
        font-weight: bold;
    }

    .dashboard-activity-summary__fields {
        width: 200px;
        margin-left: $spacing-xs;
        & > h2 {
            & > span:last-child {
                margin-left: 10px;
            }
        }
    }

    .dashboard-activity-summary__bounce-rate {
        width: 200px;
        margin-left: $spacing-xl;
    }

    .dashboard-activity-summary__bounce-rate-fields {
        margin-left: $spacing-xs;

        > p {
            width: 100%;

            & > span:last-child {
                margin-left: 20px;
            }
        }

        & .engaged {
            & > svg {
                background: $color-primary;
                fill: white;
            }
        }

        & .rate-bounced {
            & > svg {
                background: $color-grey-1200;
                fill: white;
            }
        }
    }
}
