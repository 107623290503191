

.avatar {
    margin: 15px 0;
}
.kare-console-settings {
    .react-tabs {
        position: relative;
        ul.react-tabs__tab-list {
            display: flex;
            margin: 0;
            padding-top: 24px;
            border-bottom: $border-light;
            li {
                @include kare-button-1-text();
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;
                flex-grow: 0;
                cursor: pointer;
                transition: $global-transition;
                text-transform: capitalize;
                color: $color-black-300;
                padding: 0 0 $spacing-xs;
                margin: 0 12px -1px;
                &:first-of-type {
                    margin-left: $spacing-m;
                }
                &:hover {
                    color: $color-black-1200;
                }
                &.react-tabs__tab--selected {
                    border-bottom: 2px solid $color-purple-500;
                    color: $color-black-900;
                }
            }
        }
    }
    .settings-section {
        margin-bottom: $spacing-m;
    }
    .c-panel__item {
        padding-top: 5px;
    }
    .settings__application-info {
        padding-bottom: 24px;
    }
}

.widget-config-actions-tabe {
    .rt-tbody {
        .rt-td.documents-table__column-view-original button {
            display: none;
        }
        .rt-tr:hover {
            .rt-td.documents-table__column-view-original button {
                display: flex;
            }
        }
    }
    .rt-th {
        > div {
            display: flex;
        }
    }
}

.widget-form-webextension-links-container {
    .widget-form-webextension-links-item {
        display: inline-flex;
        width: 100%;
        justify-content: space-around;

        &:first-of-type {
            margin-top: 10px;
        }
        > div {
            padding-right: 20px;
            padding-left: 15px;
        }
        > svg {
            cursor: pointer;
        }
    }
}

@import 'knowledge-management/index';
@import 'company-form/index';
@import 'widget-form/index';
@import 'escalation-settings/index';
@import 'application-info/index';
@import 'application-form/index';
@import 'user-settings/index';
@import 'automations-form/index';
