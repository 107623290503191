

.ReactTable.kare-user-settings-user-table {
    .rt-thead .rt-th {
        @include kare-button-2-text();
        box-shadow: none;
        &:first-child {
            text-align: left;
        }
    }
    .rt-td {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: $border-light;
        &:first-child,
        &:nth-child(2) {
            border: none;
        }
    }

    .rt-tbody {
        .rt-td:first-child {
            justify-content: flex-start;
        }
        .rt-tr-group:hover {
            background-color: inherit;
            cursor: default;
        }
        .dashboard__dashboard-responses-admin {
            @include kare-overline-3();
            color: $color-primary;
        }
    }
    .user-settings-settings {
        cursor: pointer;
        color: $color-black-300;
        &:hover {
            color: $color-black-1000;
        }
    }
}
