/* ==========================================================================
   # KNOWLEDGE BASE RESPONSE PORTAL
   ========================================================================== */

.knowledge-base__portal.response-portal {
    .response-content-error {
        display: flex;
        align-items: center;
    }
    $submit-button-width: 150px;
    z-index: 1001;
    .knowledge-base__portal-footer-locale-feedback {
        @include kare-body();
        color: $color-black-500;
        margin-right: $spacing-xs;
        .flag-icon {
            margin-right: $spacing-xs;
            filter: grayscale(100%);
        }
    }
    .knowledge-base__panel-footer-right {
        display: flex;
        align-items: center;
    }
    .knowledge-base__panel-footer-link-options {
        margin-left: 7px;
        cursor: pointer;
    }
    .response-portal-submit-button {
        min-width: $submit-button-width;
    }
    button.response-portal-submit__chattering-delete {
        padding: 0 $spacing-xs;
        bottom: 3px;
        svg {
            margin-right: $spacing-xs;
            fill: $color-purple-600;
        }
    }
    .response-portal__locale {
        position: absolute;
        top: 0;
        left: $spacing-xs;
    }
    .response-portal-submit__tooltip {
        z-index: 99;
        position: absolute;
        bottom: $spacing-xxs;
        right: $submit-button-width + $spacing-s;
        svg {
            fill: $color-purple-600;
        }
    }
}

.knowledge-base__response-portal-not-found {
    @include kare-h5();
    padding: $spacing-s;
}

@import 'response-form/index';
@import 'documents-sidebar/index';
@import 'response-not-found/index';
