.settings__webcrawler-tooltip-content {
    display: block;
    > div {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: $spacing-xxs $spacing-s;
        margin-bottom: $spacing-xxs;
        background-color: $color-surface-light;
        transition: $global-transition;
        &:hover {
            background-color: $color-surface-dark;
        }
        &:last-child {
            margin: 0;
        }
    }
}
