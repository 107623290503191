@import 'providers/login-form/index';
@import "src/app/styles/kss/fonts";
@import "src/app/styles/kss/mixins";

.loginWrapper {
    @include kare-center-block();
}

.loginContainer {
    @include kare-center-block();
    width: 240px;

    .title {
        margin: 36px 0 $spacing-s 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }
}
