.ActionListModal__container {
    position: absolute;
    left: 35%;
    top: 5%;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.55);
    z-index: 1000;
    background-color: white;
    width: 500px;
    padding: 10px;
}

.ActionListModal__title {
    font-size: 2rem;
    color: #263238;
    margin-bottom: 15px;
}
