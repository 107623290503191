.message-attachment__container {
    display: flex;
    flex-flow: column;
    margin-left: $message-margin;
    justify-content: flex-end;
    @include kare-body();
    position: relative;
    padding-right: 8px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-right: -15px;
    border-right: 4px solid $color-secondary;
    > div {
        background-color: $color-background;
        border-radius: $message-border-radius;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    }
}


.message-attachment__body {
    padding: $spacing-xs $spacing-s;
    display: flex;
    align-items: center;
    svg {
        transform: rotate(-90deg);
        width: 15px;
        margin-top: -2px;
        margin-right: 6px;
        height: 15px;
        fill: $color-primary;
    }
}
