.ResourceSelectionButton__container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}
.ResourceSelectionButton__buttonContainer {
    display: flex;
    align-items: center;
    border: 1px solid #263238;
    padding: 10px;
    cursor: pointer;
    max-width: 400px;
    width: 100%;
    height: 35px;
    .ResourceSelectionButton__iconEdit {
        margin-left: auto;
    }
    .ResourceSelectionButton__iconLeft {
        display: flex;
        margin-right: 10px;
    }
    svg:first-child {
        cursor: unset;
    }
    svg {
        cursor: pointer;
    }
    .ResourceSelectionButton__placeholderText {
        cursor: pointer;
        &:hover {
            color: grey;
        }
    }
}
