/* ==========================================================================
   # KNOWLEDGE BASE PANEL HEADER
   ========================================================================== */

.response-editor__portal-header-title {
    @include kare-h5();
    margin: 0;
}
// TODO(rupertrutland): Make the styles for this header similar to those of the footer.
.response-editor__portal-header {
    background-color: $color-purple-600;
    color: $color-text-light;
    padding: $spacing-xxs $spacing-s;
    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .response-editor__portal-header-title {
        @include kare-h8();
        font-weight: 600;
        color: $color-text-light;
        margin-right: auto;
    }
    &.invertColors {
        background-color: $color-surface-dark;
        a,
        svg,
        .response-editor__portal-header-title {
            color: $color-text-dark;
        }
    }
}

.response-editor__portal-header-close-button {
    cursor: pointer;
    display: flex;
    align-self: flex-start;
    svg {
        color: $color-text-light;
    }
}
