.kare-error {
    align-self: center;
    width: 100%;
    .error-message-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            @include kare-h2();
            margin-top: $spacing-s; // There is padding around the icon, and this makes the spacing look correct.
            margin-bottom: $spacing-xs;
        }
        p {
            margin-bottom: $spacing-s;
        }
    }
}
