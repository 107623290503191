

.kare-block-choice {
    > h1 {
        @include kare-h5();
        margin-bottom: $spacing-xs;
        color: #263238;
        font-family: $global-text-font-stack;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: normal;
    }
    .choices-container {
        display: flex;
        align-items: stretch;
        .side-bar {
            width: 1px;
            background-color: $color-grey-700;
        }
        .choices {
            article {
                position: relative;
                padding-left: $spacing-xxs;
                margin-bottom: $spacing-xs;
                &:before {
                    content: '';
                    display: block;
                    width: $spacing-xxs;
                    height: 1px;
                    background-color: $color-grey-700;
                    left: 0;
                    top: 10px;
                    position: absolute;
                }
                .choice-broken-title {
                    color: $color-red-1000;
                }
                h1 {
                    @include kare-h6();
                    color: $color-secondary;
                    svg {
                        margin-right: $spacing-xs;
                        margin-left: $spacing-xs;
                    }
                }
                p {
                    color: $color-black-500;
                    margin-left: $spacing-xxs + $spacing-xs +
                        $spacing-xs + $spacing-xs;
                }
            }
        }
    }
}
