@import 'block-controls/index';
@import 'quote-block-field/index';
@import 'image-block-field/index';
@import 'extension-block-field/index';
@import 'nps-block-field/index';
@import 'csat-block-field/index';
@import 'ces-block-field/index';
@import 'handover-block-field/index';
@import 'dialog-block-field/index';
@import 'attachment-block-field/index';

.kare-block-wrapper,
.kare-cx-block-wrapper {
    .kare-block-cta {
        h1 {
            font-weight: normal;
            font-size: 14px;
        }
    }

    display: flex;

    &:hover {
        .kare-block-controls {
            visibility: visible;
        }
    }
}

.kare-block-broken {
    border: 1px $color-red-1000 solid;
}

.kare-block {
    background-color: $color-background;
    padding: $spacing-s;
    margin-right: $spacing-s;
    flex-grow: 2;
    min-height: 80px;
    max-width: calc(100% - 36px);

    .markdown {
        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }

        ol,
        ul {
            padding-left: $spacing-l;
        }
    }

    &.kare-block--active {
    }

    &.kare-block--inactive {
        @include kare-box-shadow();
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin-bottom: $spacing-xs;
        width: 100%;
    }

    .kare-video-block-player-wrapper {
        width: 100%;
        height: 250px;
        margin-bottom: $spacing-xs;
    }
}

.media-block-url {
    @include kare-caption();
    display: flex;
    align-items: center;

    > svg {
        margin-right: $spacing-xxs;
        flex-shrink: 0;
    }

    > span {
        flex-grow: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.media-block-modal {
    h1 {
        @include kare-h4();
    }

    label {
        margin-bottom: $spacing-xs;
        display: block;
    }

    input {
        height: $spacing-m;
        border: $border-dark;
        width: 100%;
        padding: 0 $spacing-xs;
        margin-bottom: $spacing-s;
    }

    .media-block-modal-buttons {
        display: flex;
        justify-content: space-between;
    }
}
