@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.TabBar__container {
    ul {
        margin-bottom: $spacing-s;
        border-bottom: 1px solid $color-grey-lighter;
        li {
            cursor: pointer;
            display: inline-block;
            color: $color-black-300;
            padding: 0 0 $spacing-xs;
            margin-right: 24px;
            border-bottom: 1px solid transparent;
            margin-bottom: -1px;
            font-size: $font-size-s;
            &:hover {
                color: $color-grey;
            }
            &.TabBar__selected {
                color: $color-secondary;
                border-bottom: 2px solid $color-purple-500;
            }
            &:first-of-type {
                padding-left: 0;
            }
        }
    }
}
