$flagWidth: 15px;

@import 'option/index';

.language-picker {
    padding: 0 $spacing-xs $spacing-xxs $spacing-s;
    h3 {
        height: 24px;
        font-family: $global-text-font-stack;
        font-size: 8px;
        color: #7b7789;
        text-align: left;
        text-transform: capitalize;
    }
    > div {
        display: flex;
        align-items: flex-start;
        .flag-icon {
            margin-right: $spacing-xs;
            width: $flagWidth;
        }
        .language-picker-text {
            .language-picker-text-language {
                @include kare-button-1-text();
                color: $color-text-light;
            }
            .language-picker-text-country {
                @include kare-caption();
                color: $color-black-700;
            }
        }
        .more-vertical {
            color: $color-text-light;
            cursor: pointer;
            margin-left: auto;
        }
    }
}

.c-tooltip-overlay .language-picker-overlay {
    display: block;
    min-width: 200px;
    h1 {
        @include kare-overline-2();
        color: $color-black-700;
        margin-bottom: $spacing-s;
    }
}
