@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.UnderRepresented__container {
    .UnderRepresented__tableHeader {
        display: flex;
        justify-content: space-between;
        font-size: $font-size-xs;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-grey;
        border-bottom: 1px solid $color-black-100;
        margin-top: $spacing-l;
        padding-left: $spacing-s;
        padding-right: $spacing-s;
        & > span {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
        }
    }
    ul {
        display: flex;
        flex-direction: column;

        li {
            height: 30px;
            display: flex;
            font-size: $font-size-s;
            align-items: center;
            color: $color-grey;
            cursor: pointer;
            padding: 0 $spacing-s;
            &:hover {
                color: $color-grey;
                background-color: rgba($color-primary, 0.1);
            }
            svg {
                margin-right: $spacing-xs;
            }
            small {
                margin-left: auto;
                color: $color-danger;
                span {
                    display: flex;
                    align-items: center;
                    svg {
                        margin-left: $spacing-xs;
                        margin-right: $spacing-xxs;
                    }
                }
            }
        }
    }
    .UnderRepresented__noData {
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            margin-top: $spacing-l;
        }
    }
}
