@import '~react-vis/dist/style';

.treemap {
    .rv-treemap__leaf__content {
        color: white;
    }
    h2 {
        margin-bottom: 15px;
    }
    h3 {
        display: block;
    }
    .legend {
        padding: 10px 0;
    }
    .legend-blocks {
        display: flex;
        padding-left: 2px;
    }
    .legend-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 15px;
        span {
            font-weight: 18px;
            font-weight: bold;
            color: #666;
        }
    }
    .legend-dot {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        border-radius: 3px;
    }
    .rv-treemap__leaf {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 3px;

        span {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
            font-size: 8px;
        }
        small {
            position: absolute;
            bottom: 5px;
            right: 5px;
            font-weight: bold;
            font-size: 12px;
            background-color: white;
            padding: 1px 3px;
            border-radius: 2px;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            pointer-events: none;
        }
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
                0 10px 10px rgba(0, 0, 0, 0.22);
            z-index: 99999999;
        }
    }
}
