.console-activity-message-container {
    position: relative;
}
.console-activity-message-sender {
    display: flex;
    flex-direction: column;
    & > span {
        &:first-of-type {
            margin-top: 5px;
            font-weight: bold;
        }
        &:last-of-type {
            margin-bottom: 15px;
        }
    }
    strong {
        display: flex;
        font-weight: bold;
        align-items: center;
        margin-bottom: 10px;
        svg {
            margin-right: 5px;
        }
    }
}
.message-user-choice__container.console-activity-message-container .console-activity-message.message-user-choice.message-user-choice__response {
    margin-top: 10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message-user-choice__default {
    text-transform: lowercase;
    word-break: break-word;
}

.console-activity-message-container.console-activity-message.console-activity-message--out.console-activity--out {
    margin-right: -15px;
    border-right: 4px solid $color-secondary;
    padding-right: 8px;
}
.console-activity--out + .console-activity--in,
.console-activity--in + .console-activity--out {
    margin-top: 10px;
}

.console-activity-message--in-widget {
    border-left: 4px solid $color-primary;
    margin: 10px 0;
    padding-left: 8px;

    &.exit {
        span {
            display: flex;
            align-items: center;
            font-weight: 600;
            color: $color-primary;

            svg {
                margin-right: 5px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    &.launched {
        p {
            display: flex;
            align-items: center;
            font-weight: 600;
            color: $color-primary;
        }
    }
    padding-bottom: 0 !important;

}
.console-activity-message-container.console-activity-message.console-activity-message--in.console-activity--in {
    border-left: 4px solid $color-secondary;
    margin: 10px 0;
    &.is-user-query {
        margin-top: 10px;
        border-left: 4px solid $color-grey;
        & > div {
            padding-top: 0;
            padding-bottom: 0;
        }
        & > div > div {
            background-color: $color-grey;
            padding: $spacing-xs;
        }
        p {
            color: $color-grey;
        }
    }
    &.is-cx-question {
        flex-direction: column;
        margin-top: 10px;
        border-left: 4px solid $color-yellow-1000;
        & > div {
            overflow: inherit;
            padding-top: 0;
            padding-bottom: 0;
        }
        & > div > div {
            background-color: $color-white-1000;
            color: $color-black-1000;
            padding: 8px;
        }
        p {
            color: $color-grey;
        }
    }
    & > div {
        background-color: transparent;
        margin-bottom: 0;
        padding-top: 0;
        .markdown p {
            color: white;
            font-size: $font-size-s;
            font-weight: normal;
        }
        p {
            display: flex;
            align-items: center;
            color: $color-purple-600;
            font-weight: bold;
            font-size: 10px;
            svg {
                margin-right: 3px;
            }
        }
        & > div {
            background-color: $color-secondary;
            padding: 10px;
            border-radius: 10px;
        }
    }
}
