

$icon-size: 20px;

.manage__container {
    .manage-answer-information {
        h2 {
            @include kare-h8();
            display: flex;
            align-items: center;
            color: $color-text-light;
            margin-bottom: 0;
            padding-left: $spacing-s;
            padding-right: $spacing-s;
            @include heightLine($spacing-m);
            justify-content: space-between;
        }
        p {
            @include kare-body();
            margin-left: $spacing-s;
            margin-bottom: 0;
        }
    }
    .manage-answer-information__content {
        display: flex;
        height: 78px;
        padding: 0 20px;
        flex-direction: column;
        justify-content: center;
        svg {
            margin-right: $spacing-s;
        }
        strong {
            @include kare-h8();
            margin-bottom: 5px;
            display: flex;
            align-content: center;
            font-weight: $fontWeightBold;
            line-height: 14.5px;
        }
        span:last-of-type {
            @include kare-body();
            display: block;
            color: $color-black-700;
            padding-left: 20px + $spacing-s;
        }
    }
}
