@import 'queries-response/index';
@import 'queries-response/queries-response-search/index';
@import 'response/index';
@import 'queries-card-actions/index';
@import 'query-list/index';

$border-card: 1px solid $color-grey-700;
.empty .queries-card-container {
    box-shadow: none;
}
.queries-card-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18), 0 0 8px 0 rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    overflow: hidden;
    .queries-card-content {
        display: flex;
        flex: 1;
        flex-direction: row;
        border-top: $border-card;
        border-bottom: $border-card;
        height: 100%;
    }
}
