/* ==========================================================================
   #ALIASES
   ========================================================================== */
@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin heightLine($height) {
    height: $height;
    line-height: $height;
}

@mixin hide() {
    opacity: 0;
    visibility: hidden;
}

@mixin show() {
    opacity: 1;
    visibility: visible;
}

@mixin positionMiddle() {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

@mixin truncateText($width) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin triangle($position, $offset, $width, $color) {
    bottom: 100%;
    #{$position}: $offset;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: #{$width} solid transparent;
    border-bottom-color: $color;
    margin-left: -#{$width};
}

@mixin errorTooltip($top) {
    @include kare-body();
    position: absolute;
    background-color: $color-error;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18), 0 0 4px 0 rgba(0, 0, 0, 0.24);
    color: $color-text-light;
    padding: $spacing-xs;
    width: 70%;
    left: $response-content-margin-left;
    top: $top;
    z-index: 99;
    pointer-events: none;
    height: auto !important;
    &:after {
        @include triangle(
            left,
            $response-content-margin-left,
            8px,
            $color-error
        );
    }
}

@mixin kare-box-shadow {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24);
}

.kare-box-shadow {
    @include kare-box-shadow();
}

@mixin kare-center-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kare-center-block {
    @include kare-center-block();
}

@mixin kare-button-1-text {
    color: $color-text-dark;
    font-family: $global-text-font-stack;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: normal;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
}

.kare-button-1-text {
    @include kare-button-1-text();
}

@mixin kare-button-2-text {
    color: $color-grey;
    font-family: $global-text-font-stack;
    font-weight: $fontWeightSemiBold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: normal;
    text-transform: capitalize;
    text-decoration: none;
}

.kare-button-2-text {
    @include kare-button-2-text();
}

@mixin kare-code {
    color: $color-text-dark;
    font-family: Menlo, 'Courier New', Courier, monospace;
    font-weight: $fontWeightNormal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: normal;
}

.kare-code {
    @include kare-code();
}

@mixin kare-text-primary {
    color: $color-primary;
}

.kare-text-primary {
    @include kare-text-primary();
}

@mixin kare-text-error {
    color: $color-error;
}

.kare-text-error {
    @include kare-text-error();
}

@mixin kare-text-center {
    text-align: center;
}

.kare-text-center {
    @include kare-text-center();
}

@mixin kare-form-label {
    @include kare-button-2-text();
    margin-bottom: $spacing-xs;
    display: block;
}

.kare-form-label {
    @include kare-form-label();
}
