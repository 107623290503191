@import '/src/app/styles/kss/colors';

.Loading__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    & > div svg {
        color: $color-purple-800;
    }
}
