$circle-size: 30px;

.message-cx__ces-block {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    & > div {
        flex: 1;
    }
    .message-cx__ces-title-container {
        margin: 5px 0;
        .message-cx__ces-title {
            font-size: 14px;
            color: $color-black-1000;
            margin-bottom: 5px;
        }
    }
    .message-cx__ces-icon-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .message-cx__ces-icon-wrapper {
            flex: 1;
            align-items: center;
            justify-content: center;
            .message-cx__ces-icon {
                svg {
                    border-radius: 50%;
                }
                &.selected.danger-dark svg {
                    background-color: $color-red-1200;
                }
                &.selected.danger svg {
                    background: $color-red-1000;
                }
                &.selected.danger-light svg {
                    background-color: #dc5158;
                }
                &.selected.warning svg {
                    background: $color-yellow-1000;
                }
                &.selected.warning-light svg {
                    background-color: #fccd57;
                }
                &.selected.success svg {
                    background: $color-green-1000;
                }
                &.selected.success-light svg {
                    background-color: #33c779;
                }
            }
        }
    }
    .message-cx__ces-label-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .message-cx__ces-label-negative,
        .message-cx__ces-label-positive {
            flex: 1;
            font-family: $global-text-font-stack;
            font-weight: 400;
            font-size: 10px;
            letter-spacing: normal;
            text-decoration: none;
            height: 20px;
            max-width: 50%;
            color: #676f73;
            line-height: 20px;
        }
        .message-cx__ces-label-positive {
            text-align: right;
        }
    }
}
