.response-context {
    padding: $spacing-xs;
    border-top: $border-light;
    border-bottom: $border-light;
    min-height: 4.2rem;
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    align-items: center;
    color: $color-grey;

    &.response-context-editing {
        border-top: 1px solid $color-purple-600;
        border-bottom: 1px solid $color-purple-600;
    }
}

.response-context-text {
    padding-right: $spacing-xxs;
    > b {
        font-weight: 800;
        display: inline-block;
    }
}

.response-context-entry {
    padding-bottom: $spacing-xxs;
}

.response-context-icons {
    margin-left: 0;
    margin-right: $spacing-xs;
}

.response-context-edit-icon {
    cursor: pointer;
    margin-bottom: auto;
}

.response-context-save-text {
    color: $color-purple-600;
    font-weight: 600;
    cursor: pointer;
}

.response-context-label {
    display: flex;
    align-items: center;
}
