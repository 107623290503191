@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';
@import '/src/app/styles/kss/fonts';
@import '/src/app/styles/kss/mixins';

.empty-layout {
    background-color: $color-background;
    height: 100vh;
    overflow-y: auto;
    padding: $spacing-m $spacing-l;
    .empty-layout__kare-logo--top {
        @include kare-center-block();
    }
    .empty-layout__kare-logo--top-left {
        display: flex;
        justify-content: flex-start;
    }
    .empty-layout__kare-logo--bottom-left {
        position: fixed;
        bottom: $spacing-l;
        left: $spacing-l;
    }
    .empty-layout__kare-logo-container {
        button {
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }
    }
}

.emptyLayoutLoadingWrapper {
    display: flex;
    align-items: center;
    height: 100%;
}
