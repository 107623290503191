@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.RadialChartsBottomLegend__container {
    display: flex;
    justify-content: center;
    padding: $spacing-m 0 $spacing-xs $spacing-xs;
    & > div {
        flex: 1;
        h3 {
            font-size: $font-size-xs;
            color: $color-grey;
            display: flex;
            align-items: center;
        }
        h3:last-of-type {
            margin-left: $spacing-s;
        }
        svg {
            margin-right: $spacing-xs;
            width: 16px;
            height: 16px;
        }
        strong {
            font-weight: 700;
            font-size: $font-size-xxl;
            margin-left: $spacing-xs;
        }
        span {
            margin-left: $spacing-m;
            display: block;
        }
        small {
            font-size: $font-size-s;
            color: $color-grey;
            margin-left: 40px;
        }
    }
}
