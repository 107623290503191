@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.WebCrawlerItem__container {
    display: flex;
    justify-content: space-between;
    background-color: #f6f5f2;
    margin-bottom: $section-margin-bottom;
    align-items: center;
    padding: 5px;
    > svg {
        cursor: pointer;
    }
}

.WebCrawlerItem__host {
    font-size: $font-size-m;
    color: #263238;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
}
