@import '/src/app/styles/kss/spacings';

.Labels__container {
    display: flex;
    flex-flow: row wrap;
    .Labels__label:first-child {
        margin: 4px 8px 4px 0;
    }
    .Labels__label:last-child {
        margin: 4px 0px 4px 0px;
    }
    margin-bottom: $section-margin-bottom;
}

.Labels__label {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    height: 20px;
    padding: 0 4px 0 8px;
    border-radius: 2px;
    margin: 4px 8px 4px 0;
}

.Labels__close {
    cursor: pointer;
    margin-left: 8px;
}
