// TODO(rupertrutland): Extract styles here, so they can be better shared with the response form.
.kare-response-renderer {
    margin: $spacing-s;
    height: 0;
    h1 {
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 18px;
        color: $color-purple-600;
        svg {
            margin-right: $spacing-xs;
        }
    }
}
