@import 'attached-document/index';
@import 'title-input/index';
@import 'dialog-creation-modal/index';
@import 'extension-creation-modal/index';
@import 'handover-creation-modal/index';
@import 'block-fields/index';
@import 'add-block-widget/index';
@import 'cta-creation-modal/index';
@import 'cx-creation-modal/index';
@import 'attachment-creation-modal/index';

$portal-header-height: 31px;
$form-title-height: 40px;
$form-labels-height: 47px;
$portal-footer-height: 65px;
$queries-input-height: 50px;
$attachments-height: 40px;

.knowledge-base__response-form {
    .parents-list {
        height: 100%;
        span {
            display: block;
            line-height: $spacing-l;
            padding: 0 $spacing-xs;
            color: #676f73;
            font-size: 12px;
            border-bottom: 1px solid $color-grey-700;
            cursor: pointer;
            &:hover {
                color: black;
            }
        }
    }
    .queries-list__empty,
    .parents-list__empty {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            color: #263238;
            font-size: 12px;
            line-height: 17px;
        }
        h1 {
            color: #263238;
            font-size: 16px;
            line-height: 22px;
        }
        h1,
        h2 {
            text-align: center;
            display: block;
        }
    }
    .queries-list__activation {
        border-bottom: 1px solid $color-grey-700;
        height: 32px;
        display: flex;
        align-items: center;
        span {
            padding: 0 $spacing-xs;
            cursor: pointer;
            color: $color-black-300;
            font-size: 12px;
            height: 100%;
            display: flex;
            align-items: center;
            &:first-of-type {
                cursor: auto;
                &:hover {
                    color: $color-black-900;
                }
            }
            &:hover {
                color: $color-black-900;
            }
            &.selected {
                color: $color-black-900;
                border-bottom: 2px solid $color-purple-500;
                margin-bottom: -1px;
            }
        }
    }
    .blocks-warning {
        color: $color-error;
        text-align: center;
    }
    .array-field-editor-container {
        > div:first-child {
            flex-grow: 2;
        }
        .add-inner-block {
        }
    }
    position: relative;
    input[type='text'] {
        @include kare-body();
        width: 100%;
        outline: none;
        border: none;
        padding-left: $spacing-xs;
        padding-right: $spacing-xs;
    }
    .knowledge-base__response-form__title {
        flex-shrink: 0;
        height: 40px;
        display: flex;
        align-items: center;
        padding-right: $spacing-s;
        input {
            @include kare-h6();
            margin-right: $spacing-s;
        }
    }
    height: 100%;
    .markdown-editor__wrapper {
        display: flex;
        .markdown-editor {
            height: 100%;
            > div {
                height: 100%;
                width: 100%;
            }
        }
    }
    .response-editor__attachment {
        position: relative;
        display: flex;
        overflow: hidden;
        color: $color-grey-1000;
        @include heightLine($attachments-height);
        border-top: $border-light;
        flex-shrink: 0;
    }
    .response-editor__actions {
        position: absolute;
        background-color: $color-primary;
        border-radius: 50%;
        padding: 3px;
        box-sizing: content-box;
        top: 50%;
        transform: translateY(-50%);
        fill: $color-white-1000;
        left: $spacing-xs;
        cursor: pointer;
    }
    .DraftEditor-root {
        height: 100%;
        display: flex;
    }
    .DraftEditor-editorContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .kare-quote-selection {
            order: 1;
        }
        & > .public-DraftEditor-content {
            order: 2;
        }
        .response-form__dialog {
            order: 3;
        }
    }
    .rdw-editor-main {
        height: calc(100% - 40px);
        overflow-y: auto;
    }
    .knowledge-base__response-form__inner-container {
        height: 100%;
        display: flex;
        flex-direction: row;
        & > div.knowledge-base__response-form__main-fields-container {
            position: relative;
            order: 2;
            width: ($knowled-base__portal-main-width / 7) * 4;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
        & > div.knowledge-base__response-form__queries-list {
            border-right: 1px solid $color-grey;
            order: 1;
            position: relative;
            width: ($knowled-base__portal-main-width / 7) * 3;
        }
        .blocks-container {
            padding: $spacing-s $spacing-m $spacing-s
                $spacing-s;
            background-color: $color-surface-dark;
            min-height: 100%;
            .last-block-widget {
                display: flex;
                justify-content: center;
                width: calc(100% - 36px);
                margin-top: $spacing-s;
                &.last-block-widget--is-only-one {
                    width: 100%;
                    margin-top: 0;
                }
            }
        }
    }
    .c-field--input {
        border-bottom: 0;
    }

    .knowledge-base__response-form__queries-list {
        height: 100%;

        .queries-list__input-container {
            padding: $spacing-xs;
            border-bottom: 1px solid $color-purple-500;
            display: flex;
            > input {
                color: $color-purple-500;
                width: 100%;
                padding: 0;
                margin: 0 $spacing-xs 0 0;
                &::placeholder {
                    color: $color-purple-500;
                }
                &:hover,
                &:focus {
                    color: $color-secondary;
                    &::placeholder {
                        color: $color-secondary;
                    }
                }
                &:focus {
                    background-color: $color-background;
                }
            }
            position: relative;
            svg {
                color: $color-secondary;
                cursor: pointer;
            }
        }

        .queries-list__scroll {
            height: calc(100% - 29px);
        }
        .queries-list__container {
            border-bottom: $border-lighter;
            padding: $spacing-xxs $spacing-xs;
            .queries-list__container-top {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                label {
                    margin-right: $spacing-xs;
                }
                label,
                svg {
                    display: none;
                    cursor: pointer;
                    color: $color-black-500;
                    flex-shrink: 0;
                    align-self: flex-start;
                    &:hover {
                        color: $color-black-700;
                    }
                }
                &:hover {
                    label,
                    svg {
                        display: block;
                    }
                }
                input.queries-list__item {
                    display: inline-block;
                    padding: 0;
                    margin: 0 $spacing-xs 0 0;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    height: auto;
                }
            }
            .queries-list__container-bottom {
                display: flex;
                justify-content: flex-end;
                > span {
                    @include kare-overline-3();
                    cursor: pointer;
                    color: $color-secondary;
                }
            }
            &.queries-list__container--is-focused,
            &:hover {
                background-color: $color-blue-50;
            }
            &.queries-list__container--is-focused {
                border-top: 1px solid;
                border-color: $color-secondary;
                .queries-list__container-top {
                    label,
                    svg {
                        display: none;
                    }
                    .queries-list__item {
                        cursor: text;
                    }
                }
                &:first-child {
                    border-top: none;
                }
            }
        }
    }
    .response-portal-submit-button {
        z-index: 99;
        position: absolute;
        bottom: $spacing-xxs;
        right: $spacing-xs;
        @include heightLine(30px);
    }
    .markdown-editor__wrapper-end {
        padding: $spacing-s;
    }
}

.response-form__dialog {
    margin: $spacing-s;
    background-color: $color-secondary-very-light;
    border-left: 5px solid $color-secondary;
    border-radius: 2px;
    padding: $spacing-xs $spacing-xs $spacing-xxs
        $spacing-xs;
    position: relative;
    &__close {
        position: absolute;
        top: $spacing-s;
        right: $spacing-s;
        cursor: pointer;
    }
    &:before {
        content: '';
        display: block;
        width: 1px;
        background-color: #d8d8d8;
        top: 22px;
        bottom: 22px;
        position: absolute;
        left: $spacing-s;
    }
    h2 {
        @include kare-overline-3();
        display: flex;
        align-items: center;
        svg {
            margin-right: $spacing-xxs;
        }
    }
    &-item {
        position: relative;
        margin-left: $spacing-s;
        display: flex;
        align-items: center;
        height: $spacing-m;
        margin-bottom: 0;

        &__text {
            display: flex;
            flex-direction: column;
            span {
                font-weight: bold;
            }
        }
        &:before {
            content: '';
            display: block;
            width: 5px;
            height: 1px;
            background-color: #d8d8d8;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: -#{$spacing-xs};
        }
        svg {
            margin-right: $spacing-xxs;
            fill: $color-secondary;
            align-self: center;
        }
        &.edited {
            svg {
                align-self: flex-start;
                transform: translateY(5px);
            }
            &:before {
                top: 11px;
            }
        }
    }
}
