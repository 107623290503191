@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.knowledge__container {
    h1 {
        width: 100%;
        text-align: center;
        padding: $spacing-l 0;
    }
    .knowledge__filters {
        display: flex;
        padding: $spacing-s 0;
        line-height: 25px;

        > strong {
            font-size: 10px;
            font-weight: 700;
            color: $color-grey;
        }

        ul {
            display: flex;
            margin-left: $spacing-m;
            li {
                display: flex;
                margin-right: $spacing-m;
                align-items: center;
                cursor: pointer;
                color: $color-black-900;

                &:hover {
                    .filterCheckbox {
                        &:before {
                            background-color: $color-grey-light;
                            content: '';
                            position: absolute;
                            display: block;
                            height: 12px;
                            width: 12px;
                            top: 2px;
                            left: 2px;
                        }
                    }
                }
                .knowledge__filterCheckbox {
                    width: 20px;
                    height: 20px;
                    border: 2px solid black;
                    border-radius: 2px;
                    margin-right: $spacing-xs;
                    position: relative;
                    &:before {
                        transition: 0.3s;
                    }
                    &.active {
                        &:before {
                            background-color: black;
                            content: '';
                            position: absolute;
                            display: block;
                            height: 12px;
                            width: 12px;
                            top: 2px;
                            left: 2px;
                        }
                    }
                }
                .knowledge__filterIcon {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: $spacing-xs;
                }
            }
        }
    }
    .knowledge__content {
        margin: 0 -35px;
    }
    ul {
        li.knowledge__clusterItem {
            padding: $spacing-s $spacing-m;
            border-top: 1px solid $color-black-100;
            &:first-of-type {
                margin-top: $spacing-xs;
            }
            cursor: pointer;
            transition: background-color 0.15s ease;
            &:last-of-type {
                border-bottom: 1px solid $color-black-100;
            }
            &:hover {
                background-color: $color-black-25;

                li {
                    background-color: #FFFFFF;
                }
            }
            .knowledge__responseIcon {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: $spacing-xs;
            }
            .knowledge__header {
                display: flex;
                align-items: center;
                text-transform: capitalize;
                font-weight: bold;
                strong {
                    margin-left: auto;
                    color: $color-grey;
                    line-height: 12px;
                    font-size: $font-size-xs;
                }
                span {
                    color: $color-grey;
                    font-size: $font-size-xs;
                    line-height: 12px;
                }
            }
        }
    }
    .knowledge__responseContent {
        display: flex;
        .knowledge__queries {
            width: 60%;
            display: block;
            position: relative;
            ul {
                width: 100%;
                display: block;
                li {
                    line-height: 30px;
                    margin: $spacing-xs 0 0 18px;
                    border-bottom: 1px solid $color-black-100;
                    background-color: $color-black-25;
                    display: block;
                    padding: 0 0 0 $spacing-xs;
                }
            }
            .knowledge__more {
                font-weight: bold;
                text-transform: capitalize;
                position: absolute;
                bottom: 20px;
                right: 0;
                transform: translateX(calc(100% + 10px)) translateY(50%);
            }
        }
        .knowledge__info {
            margin-left: auto;
            .knowledge__improveInfo {
                line-height: 30px;
                strong {
                    font-weight: bold;
                    color: $color-purple-600;
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: $spacing-xs;
                    }
                }
            }
            .knowledge__choicesInfo {
                position: relative;
                span {
                    line-height: 30px;
                    svg {
                        margin-right: $spacing-xs;
                        transform: translateY(3px);
                    }
                    text-align: right;
                    font-weight: bold;
                    color: $color-purple-600;
                    display: block;
                }
                .knowledge__choicesMore {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    text-align: right;
                    font-weight: bold;
                    color: $color-purple-600;
                    transform: translateY(calc(100%));
                }
            }
        }
    }
}
