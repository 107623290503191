@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.Empty__container {
    height: 100vh;
    width: calc(100vw - 160px);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    pointer-events: none;
    svg {
        width: 50px;
        height: 50px;
    }
    & > h2 {
        font-weight: bold;
        margin-top: $spacing-m;
    }
    & > h3 {
        font-size: $font-size-m;
        font-weight: normal;
        margin-top: 0;
    }
}
