/* ==========================================================================
   # DOCUMENT DETAIL
   ========================================================================== */

.knowledge-base__related-document-detail {
    padding: $spacing-s;
}

.knowledge-base__related-document-detail-back-link {
    text-decoration: none;
    margin-bottom: $spacing-m;
    display: block;
}

.knowledge-base__related-document-detail-title {
    @include kare-h5();
}
