.webcrawler-form {
    position: absolute;
    top: 24%;
    left: 35%;
    box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.55);
    z-index: 1000;
    background-color: $color-background;
    width: 485px;
    // transform: translateX(-50%) translateY(-50%);
    padding: $spacing-s $spacing-m;
    section {
        position: relative;
    }
    .webcrawler-form-close-button {
        position: absolute;
        top: 0;
        right: -10px;
        cursor: pointer;
    }
    h1 {
        @include kare-h2();
        margin-bottom: $spacing-xs;
    }
    .webcrawler-form-radio-group {
        display: flex;
        margin-bottom: $spacing-s;
        .radio-input {
            margin-right: $spacing-l;
        }
    }
}
