.c-field--select-wrapper {
    display: flex;
    color: $color-grey-1000;
    border: $border-dark;
    height: $spacing-m;
    padding-right: $spacing-xxs;
    padding-left: $spacing-xxs;
    margin-bottom: $spacing-s;
    .c-field--select {
        border: none;
        // padding-right: 1em;
        cursor: pointer;
        position: relative;
        color: $color-grey-1000;
        width: 100%;
    }
    &.dirty {
        color: $color-text-dark;
        .c-field--select {
            color: $color-text-dark;
        }
    }
}

.c-field--select-arrow {
    display: block;
    right: 0;
    top: 11px;
    cursor: pointer;
}
