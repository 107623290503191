@import 'tooltip-content/index';

.settings__knowledge-management-webcrawler-list-item {
    background-color: $color-surface-light;
    margin-bottom: $spacing-xxs;
    padding: $spacing-xs $spacing-s;
    display: flex;
    justify-content: space-between;
    h1 {
        @include kare-h6();
        margin-right: $spacing-s;
        small {
            font-size: 0.8em;
            color: $color-grey-1000;
        }
    }
    svg {
        cursor: pointer;
        color: $color-black-700;
        user-select: none;
        flex-shrink: 0;
    }
}
