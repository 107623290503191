@import './feedback-list-viewer/index.scss';
@import './filter-menu/index.scss';

.feedback-container {
    padding: $spacing-s;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    .title {
        display: flex;

        h1 {
            font-size: 2rem;
            font-weight: 700;
            text-transform: capitalize;
            margin-bottom: $spacing-s;
            line-height: 24px;
        }
    }

    .filters {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .filters-datepicker {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 50px;
        }

        .filter-menu {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-left: 50px;

            .filter-button {
                background-color: transparent;
                color: $color-black-700;
                border: 2px solid lighten($color-black-700, 20%);
                border-radius: 5px;
                font-size: 12px;
                padding: 5px;

                &:hover {
                    background-color: darken($color-black-700, 10%);
                    border-color: darken($color-black-700, 10%);
                    color: $color-white-1000;
                }

                & > span {
                    display: flex;
                    justify-content: space-evenly;
                    align-content: space-evenly;
                    width: 110px;

                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .label {
                        font-size: 12px;
                    }

                    .filter-count {
                        font-size: 12px;
                    }

                    .divider {
                        margin: 6px 0;
                    }
                }
            }
        }
    }

    & > div {
        flex: 0 1 100%;

        &:first-child {
            max-height: 20vh;
        }

        &:last-child {
            height: 79vh;
        }
    }
}
