@import "./styles/index";
@import "./copyright/index.scss";


.dashboard__container {
    font-family: $global-text-font-stack;
    padding: 20px $spacing-m 0;
    background-color: $color-black-25;
    box-sizing: border-box;
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    .dashboard__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    .dashboard__title {
        font-size: $font-size-xxl;
        color: $color-black-900;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0;
        small {
            font-size: $font-size-xs;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.dashboard__whiteContainer {
    background-color: #fff;
}

.lockdown {
    display: flex;
    font-size: 36px;
    height: 100vh;
    width: 100vw;

    align-items: center;
    justify-content: center;
    line-height: 48px;
    text-align: center;
    flex-direction: column;
    h1 {
        color: $color-black-1000;
        margin-top: $spacing-m;
        margin-bottom: $spacing-s;
    }
    span {
        font-size: 16px;
        max-width: 800px;
    }
    img {
        display: block;
        width: 150px;
        margin-bottom: 40px;
    }
}
.kare-home {
    &__spinner {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        .c-spinner {
            display: block;
            position: relative;
            margin-top: $spacing-l;
            left: auto;
            transform: none;
        }
    }
}
.ReactModal__Overlay {
    z-index: 9999;
}

.grecaptcha-badge {
    visibility: collapse !important;
}

.content-recaptcha-branding {
    position: fixed;
    bottom: $spacing-s;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    color: $color-grey;
    font-size: 10px;
}

.fatal-error-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.ReactTable.muted-query-table .rt-thead .rt-th.-cursor-pointer > div {
    text-align: left;
}
