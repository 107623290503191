.launcher-preview__default-demo {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-m;
    div.console-kare-widget {
        .console-kare-widget-launcher {
            top: auto;
            left: auto;
            right: auto !important;
            bottom: auto !important;
            position: relative !important;
            display: flex;
            align-items: center;
            cursor: pointer;
            border: none;
            outline: none;

            .console-kare-widget-launcher-text {
                font-weight: bold;
                display: block;
            }
        }

        &.console-kare-widget--style-BRANDED {
            .console-kare-widget-launcher {
                background-color: transparent;
                padding: 0;
            }
        }

        &.console-kare-widget--size-small {
            .console-kare-widget-launcher {
                .console-kare-widget-launcher-text {
                    font-size: 10px;
                    margin-right: 4px;
                    margin-left: 11px;
                    line-height: 11px;
                }
            }

            &.console-kare-widget--style-BRANDED {
                svg {
                    width: 46px;
                    height: 46px;
                }
            }
        }
        &.console-kare-widget--style-FLOATING, &.console-kare-widget--style-CUSTOM {
            .console-kare-widget-launcher {
                padding: 3px;
                border-radius: 38px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12),
                0 6px 6px 0 rgba(0, 0, 0, 0.24);
            }
        }
        &.console-kare-widget--size-medium {
            &.console-kare-widget--style-FLOATING, &.console-kare-widget--style-CUSTOM {
                svg {
                    width: 38px;
                    height: 38px;
                }
            }

            &.console-kare-widget--style-BRANDED {
                svg {
                    width: 66px;
                    height: 66px;
                }
            }
            .console-kare-widget-launcher {
                .console-kare-widget-launcher-text {
                    font-size: 15px;
                    margin-right: 8px;
                    margin-left: 14px;
                }
            }
        }
        &.console-kare-widget--size-large {
            .console-kare-widget-launcher {
                .console-kare-widget-launcher-text {
                    font-size: 20px;
                    margin-right: 8px;
                    margin-left: 20px;
                }
            }

            &.console-kare-widget--style-FLOATING, &.console-kare-widget--style-CUSTOM {
                svg {
                    width: 66px;
                    height: 66px;
                }
            }

            &.console-kare-widget--style-BRANDED {
                svg {
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}
