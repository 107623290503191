.WebCrawlerTooltip__container {
    position: relative;
}

.WebCrawlerTooltip__tooltip {
    position: absolute;
    top: -15px;
    right: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    overflow: hidden;
}

.WebCrawlerTooltip__tooltipInner {
    padding: 10px;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border-radius: 3px;
    min-height: 34px;
    min-width: 100px;
    .WebCrawlerTooltip__item:first-child {
        margin: 10px 0;
    }
    .WebCrawlerTooltip__item:last-child {
        margin-bottom: 10px;
    }
}

.WebCrawlerTooltip__item {
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
        background: #eee;
    }
}
