.kare-modal {
    width: 100%;
    max-width: 500px;
    padding: $spacing-s;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    background-color: $color-white-1000;
    .kare-modal-header {
        display: flex;
        justify-content: space-between;
        svg {
            cursor: pointer;
        }
    }
    .kare-modal-form {
        .kare-text-input {
            margin-bottom: $spacing-s;
        }
        .kare-modal-form-buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    .kare-modal-title {
        @include kare-h4();
    }
    &.kare-modal-wide {
        max-width: 960px;
    }
}

.kare-modal-overlay {
    z-index: 9998;
    background-color: rgba(#000, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
