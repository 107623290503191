/**
 * 1. Space out labels when not using the placeholder
 */

.c-label-field {
    padding: 0;
    display: block;
    position: relative;
    margin: 0 0 $spacing-m 0; /* [1] */
    &--empty {
        margin-bottom: 0;
    }
}

.c-label-field__text {
    @include kare-form-label();
    display: inline-block;
}

.label-tooltip-icon {
    color: $color-black-700;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
