.launcher-preview__vertical-demo {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-m;

    button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        border: 0;
        outline: 0;
        border-radius: 5px 0 0 5px;
        font-size: 15px;
        font-weight: bold;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12),
            0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12),
            0 12px 12px 0 rgba(0, 0, 0, 0.24);
        span {
            order: 2;
            writing-mode: vertical-lr !important;
            text-orientation: sideways !important;
            transform: rotate(180deg);
        }
    }
    svg {
        order: 1;
        margin-bottom: $spacing-xs;
        transform: rotate(-90deg);
    }
    &.launcher-preview__vertical-demo--side-left {
        button {
            border-radius: 0 5px 5px 0;
            span {
                transform: rotate(-360deg);
            }
        }
    }
    &.launcher-preview__vertical-demo--size-small {
        button {
            line-height: 24px;
            width: 24px;
            padding: 6px 0;
            span {
                font-size: 10px;
            }
            svg {
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
    &.launcher-preview__vertical-demo--size-medium {
        button {
            line-height: 36px;
            width: 36px;
            padding: 8px 0;
            span {
                font-size: 15px;
            }
        }
    }
    &.launcher-preview__vertical-demo--size-large {
        button {
            line-height: 45px;
            width: 45px;
            padding: 10px 0;
            span {
                font-size: 20px;
            }
            svg {
                width: 20px !important;
                height: 20px !important;
            }
        }
    }
}
.launcher-preview .launcher-preview__window .launcher-preview__vertical-demo {
    &.launcher-preview__vertical-demo--position-top {
        top: 14px;
    }
    &.launcher-preview__vertical-demo--position-middle {
        top: 70%;
        transform: translateY(-50%) scale(0.5);
    }
    &.launcher-preview__vertical-demo--position-bottom {
        bottom: 0;
        transform-origin: bottom right;
    }
}
