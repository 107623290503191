div.console-activity__used-automation {
    border-left: 4px solid $color-warning;
    padding-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    p:first-child {
        color: $color-warning;
        font-weight: bold;
        display: flex;
        align-items: center;
        svg {
            margin-right: 4px;
        }
    }
    p:last-child {
        color: #676f73;
        font-size: 10px;
        line-height: 14px;
        margin-left: 12px;
    }
    & + .console-activity--in {
        margin-top: 10px;
    }
}
.console-activity-message.message-user-choice.message-user-choice__response
    + .console-activity-message-container.console-activity-message.console-activity-message--out {
    margin-top: -15px;
    padding-top: 25px;
}
div.console-activity-message--webext {
    transform: translateX(15px);
    border-right: 4px solid $color-secondary;
    width: 100%;
    padding-right: 8px;
    & > div {
        width: 100%;
        margin-left: 17%;
    }
    .markdown p {
        margin-bottom: 0;
    }
    .webext-call-to-action {
        font-weight: bold;
        cursor: auto;
        background-color: #00c853;
    }

    .webext-call-to-action-container {
        display: flex;
        justify-content: center;
        &.outlined {
            .webext-call-to-action {
                background: none;
                color: #00c853;
            }
            svg {
                margin-left: 0;
                margin-right: 15px;
                size: 20px;
            }
        }
    }
}
.console-activity-messages .message-user-choice.message-user-choice__escalate {
    border-left: 4px solid $color-danger;
    margin-bottom: 10px;
    margin-top: 10px;
}
.webext-call-to-action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 90%;
    color: white;
    background-color: #00c853;
    overflow: hidden;
    border-radius: 2px;
    span {
        display: block;
        width: 90%;
    }

    &:hover {
        cursor: pointer;
        background-color: #a6f1ab;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
}
.message-edit-response {
    z-index: 99999999999999;
    & + time {
        display: none;
    }
}
time {
    display: none !important;
}
