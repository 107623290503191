@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.Checkbox__container {
    display: flex;
}

.Checkbox__checkboxContainer {
    display: flex;
    margin-right: $spacing-s;
    align-items: center;
    cursor: pointer;
    &:hover {
        .checkbox {
            &:before {
                background-color: $color-grey-light;
                content: '';
                position: absolute;
                display: block;
                height: 12px;
                width: 12px;
                top: 2px;
                left: 2px;
            }
        }
    }
}

.Checkbox__checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid black;
    border-radius: 2px;
    margin-right: $spacing-xxs;
    position: relative;
    &:before {
        transition: 0.3s;
    }
    &.active {
        &:before {
            background-color: black;
            content: '';
            position: absolute;
            display: block;
            height: 12px;
            width: 12px;
            top: 2px;
            left: 2px;
        }
    }
}

.Checkbox__helpIcon {
    cursor: pointer;
}

.Checkbox__helpContainer {
    position: relative;
}

.Checkbox__helpText {
    position: absolute;
    background: #263238;
    z-index: 10000;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 200px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    border-radius: 2px;
    overflow: hidden;
    color: white;
    padding: 8px;
}
