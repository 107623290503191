.launcher-preview__selector {
    $selector-border-radius: 2px;
    display: flex;
    margin-top: $spacing-m;
    margin-bottom: $spacing-s;
    button:nth-child(1) {
        border-radius: $selector-border-radius 0 0 $selector-border-radius;
        border-right: 0;
    }
    button:nth-child(2) {
        border-radius: 0;
    }
    button:nth-child(3) {
        border-left: 0;
        border-radius: 0 $selector-border-radius $selector-border-radius 0;
    }
}
