@import 'message-globals';
@import 'message/index';
@import 'message-disambiguation/index';
@import 'message-user-choice/index';
@import 'message-answer/index';
@import 'message-cta/index';
@import 'message-rta/index';
@import 'message-resolution-missing/index';
@import 'message-webext/index';
@import 'message-cx/index';
@import 'message-attachment/index';
@import 'message-quote/index';
@import 'feedback-choice/index';


.console-activity-message {
    display: flex;
    @include kare-body();
    position: relative;
    > div {
        border-radius: $message-border-radius;
        display: flex;
        flex-direction: column;
    }
    span {
        float: left;
    }
    small {
        @include kare-body();
        color: inherit;
        align-self: flex-end;
        a {
            color: $color-purple-500;
            text-decoration: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
            &:visited {
                color: $color-grey-1000;
            }
        }
    }
    .markdown p {
        word-break: break-word;
    }
}

.console-activity-message--in,
.console-activity-message--out {
    > div {
        padding: 8px;
    }
}
.console-activity-message--in {
    > div {
        background-color: #676f73;
        overflow: auto;
        color: $color-text-light;
        margin-right: $message-margin;
        max-width: calc(100% - #{$message-margin});
    }
}
div:not(.console-activity-message--out) + .console-activity-message--out {
    padding-top: 10px;
}
.console-activity-message--out {
    justify-content: flex-end;
    padding-top: 10px;
    &:first-of-type {
        padding-top: 0;
    }
    > div {
        margin-left: $message-margin;
        background-color: $color-background;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
        max-width: 100%;
    }
}
.console-activity-message-resolution-missing {
    border-right: 4px solid $color-error !important;
}
