@import '../constants';


$modal-gutter: 250px;

.open-modal div.query-response__documents-modal {
    display: flex;
    flex-direction: column;
    .modal-title {
        position: relative;
        display: flex;
        flex-flow: column;
        padding-top: $spacing-s;
        div {
            width: 100%;
            display: flex;
            align-items: center;
        }
        .modal-title__first-line {
            margin-bottom: 5px;
            padding: 0 $icon-margin;
            h1 {
                @include kare-h6();
                flex: 1;
                text-align: start;
                margin: 0;
            }
            .modal-title__arrow {
                color: $color-text-dark;
                margin-right: $icon-margin;
            }
            .modal-title-close {
                @include square($icon-size);
            }
            svg {
                cursor: pointer;
                color: $color-grey-500;
            }
        }
        .modal-title__second-line {
            @include kare-caption();
            padding-left: #{$icon-size + 2 * $icon-margin};
            a {
                color: $color-purple-500;
                text-decoration: none;
            }
            span {
                color: $color-grey-1000;
            }
            svg {
                margin-left: $spacing-xs;
                color: $color-secondary;
            }
        }
        .modal-title__separator {
            border: 1px solid $color-grey-700;
            margin-top: $spacing-xs;
            width: 100%;
        }
    }
    .modal-body__content {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        overflow: auto;
        .modal-preview__scrollbar,
        .modal-body__scrollbar {
            height: calc(95vh - #{$modal-gutter});
            padding: 0 $spacing-m;
            width: 100%;
        }
        .modal-preview__scrollbar {
            padding: 0 $spacing-s 0 0;
        }
        .modal-body__scrollbar {
            ::selection {
                background: rgba($color-secondary, 0.2);
            }
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        .modal-body__butons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: $spacing-s $spacing-s $spacing-s 0;
            button {
                margin-right: $spacing-s;
            }
        }
    }
}
.modal-body__tooltip {
    @include kare-caption();
    color: $color-text-light;
    position: fixed;
    padding: $spacing-s $spacing-xs;
    background-color: $color-secondary;
    width: 240px;
    margin-right: 0;
    text-align: left;
    z-index: 99;
    &:after {
        @include triangle(right, 15px, 6px, $color-secondary);
    }
    &.hidden {
        display: none;
    }
}
