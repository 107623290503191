@import "spacings";
@import "colors";
@import "mixins";
@import "typography";
@import "variables";

// Basic overrides for react-table.
.ReactTable {
    border: none;
    overflow-y: auto;
    height: 100%;
    .rt-table {
        flex: 1 1 auto;
        .rt-thead {
            height: $input-height;
        }
        .rt-tr-group {
            height: $input-height;
            cursor: pointer;
            border: none;
            &:hover {
                background-color: $color-black-25;
            }
        }
        .rt-tr.-odd {
            background-color: $color-white !important;
            &:hover {
                background-color: $color-black-25 !important;
            }
        }
        .rt-td,
        .rt-th {
            border: none;
            outline: none;
            padding: 0 0 0 $spacing-xs;
            &:last-child {
                padding-right: $spacing-xs;
            }
        }
        .rt-td {
            > span {
                @include truncateText(100%);
            }
        }
        .rt-th {
            @include kare-overline-3();
            padding-top: 12px;
        }
        .rt-thead.-header {
            box-shadow: none;
        }
    }
    .rt-noData {
        display: none;
    }
}
