.multi-website-input {
    @include kare-body();
    .import-field__container {
        display: flex;
        align-items: center;
        svg {
            opacity: 0;
            transition: opacity 150ms linear, color 150ms linear;
            margin-bottom: $spacing-xs;
            cursor: pointer;
            fill: $color-grey-1000;
            &:hover {
                fill: $color-black-1000;
            }
        }
        &:hover svg {
            opacity: 1;
        }
    }
    .import-field__error {
        color: $color-error;
    }
    input {
        flex-grow: 1;
        border: 0;
        border-bottom: 1px solid $color-grey-700;
        padding: $spacing-xs;
        margin-bottom: $spacing-s;
        margin-right: $spacing-s;
        &:focus {
            border-bottom: 1px solid $color-secondary;
        }
        &.error {
            border-color: $color-error;
        }
    }
    a {
        color: $color-secondary;
        background-color: $color-background;
        outline: 0;
        border: 0;
        display: block;
        text-decoration: none;
        margin-bottom: $spacing-m;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
