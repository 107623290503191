@import "src/app/styles/kss/colors";
@import "src/app/styles/kss/spacings";

.settings__company-form {
    display: flex;
    form {
        flex-grow: 1;
        margin-right: $spacing-m;
        .kare-text-input,
        .kare-multiselect-input,
        .kare-dropdown-container,
        .escalation-settings__entry {
            max-width: 680px;
        }
    }
    .settings__company-form__escalation {
        padding: $spacing-s 0 $spacing-l 0;
    }
    .escalation-settings__warning {
        &__message {
            display: block;
            margin-bottom: $spacing-xs;
            svg {
                color: $color-red-1000;
            }
        }
        button {
            padding-left: 0;
        }
    }
}
