@import './src/app/styles/kss/spacings';

.Performance__treemapLoading {
    padding: $spacing-m 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
        margin-bottom: $spacing-m;
    }
}
