.dashboard-activity__chart {
    position: relative;
    display: inline-block;
    padding-top: 40px;
}

.dashboard-activity__chart-y-axis-title {
    @include kare-button-2-text();
    color: $color-grey-1000;
}
