.styleguide {
    margin: 0;
    .styleguide__component-group {
        display: flex;
        flex-direction: column;
        > * {
            margin: 0 0 $spacing-xs 0;
        }
    }
    .styleguide__component-group-buttons {
        > div {
            display: flex;
            button {
                margin-right: $spacing-s;
            }
        }
    }
    .class-name {
        color: rgba(0, 0, 0, 0.6);
        font-family: 'Courier New', Courier, monospace;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: normal;
        text-transform: lowercase;
    }
}
