.message-quote__container {
    display: flex;
    flex-flow: column;
    margin-left: $message-margin;
    justify-content: flex-end;
    @include kare-body();
    position: relative;
    padding-right: 8px;
    padding-top: 10px;
    margin-bottom: 0;
    margin-right: -15px;
    border-right: 4px solid $color-secondary;
    > div {
        background-color: $color-background;
        border-radius: $message-border-radius;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
    }
}


.message-quote__body {
    padding: $spacing-xs $spacing-s;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > div {
        flex: 1 1 100%;
        a {
            font-weight: bold;
        }
    }
}
