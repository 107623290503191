@import './src/app/styles/kss/spacings';
@import './src/app/styles/kss/colors';

.SourcesChart__content {
    display: flex;
    padding: $spacing-m 0;
    & > div {
        flex: 1;
    }
    .SourcesChart__chartContainer {
        height: 165px;
    }
    .SourcesChart__legend {
        .SourcesChart__labelContainer {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 13px;
            &:first-of-type {
                margin-top: 0;
            }
        }
        .SourcesChart__label {
            display: flex;
            align-items: center;
            margin-bottom: $spacing-xs;
            svg {
                width: 12px;
                height: 12px;
            }
            span {
                color: $color-grey;
                font-size: $font-size-xs;
                margin-left: $spacing-xxs;
                text-transform: capitalize;
            }
        }
        strong {
            margin-left: auto;
            font-size: $font-size-m;
        }
        strong {
            color: $color-black-900;
            font-size: $font-size-xxl;
            margin-left: $spacing-xxs;
            small {
                font-size: $font-size-s;
            }
        }
    }
}
