$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 16px;
$spacing-m: 32px;
$spacing-l: 48px;
$spacing-xl: 64px;

$section-margin-bottom: 16px;

$font-size-xs: 1rem;
$font-size-s: 1.2rem;
$font-size-m: 1.4rem;
$font-size-l: 1.6rem;
$font-size-xl: 1.8rem;
$font-size-xxl: 2.4rem;
$font-size-xxxl: 3.6rem;
$font-size-max: 4.8rem;
