.response-not-found {
    align-self: center;
    justify-content: center;
    display: flex;
    height: 100%;
    .response-not-found-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h1 {
            @include kare-h2();
            margin-top: $spacing-s;
            margin-bottom: $spacing-xs;
        }
        p {
            margin-bottom: $spacing-s;
        }
    }
}
