@import './src/app/styles/kss/colors';
@import './src/app/styles/kss/spacings';

.QueriesBarChart__container {
    .QueriesBarChart__header {
        ul li {
            display: inline-flex;
            flex-direction: column;
            padding: $spacing-m $spacing-l;
            color: $color-grey;
            span {
                font-size: $font-size-xs;
                span {
                    color: $color-grey;
                }
            }
            svg {
                margin-right: $spacing-xs;
            }
            &:first-of-type {
                strong {
                    margin-left: 0;
                }
            }
            strong {
                color: $color-black-900;
                font-size: $font-size-xxl;
                font-weight: bold;
                margin-top: $spacing-xs;
                margin-left: 20px;
                span {
                    font-size: $font-size-l;
                    margin-left: -5px;
                }
            }
            small {
                color: #92989b;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 17px;
                margin-left: 20px;
                margin-top: 5px;
            }
        }
    }
}

.QueriesBarChart__subtitle {
    display: flex;
    align-items: center;
}
