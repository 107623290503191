$checkbox-size: 20px;
$card-title-gutter: 20px;
$checkbox-inner-size: $checkbox-size - 8px;
$subheader-height: 44px;

.kare-multiselect-checkbox {
    cursor: pointer;
    @include square($checkbox-size);
    .kare-multiselect-checkbox__ui-outer {
        cursor: pointer;
        @include square($checkbox-size);
        border: 2px solid $color-black-500;
        background-color: transparent;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .kare-multiselect-checkbox__ui-inner {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.15s linear;
        @include square($checkbox-inner-size);
    }
    &:hover {
        .kare-multiselect-checkbox__ui-outer {
            border-color: $color-black-1000;
        }
    }
    &.kare-multiselect-checkbox--checked {
        .kare-multiselect-checkbox__ui-outer {
            border-color: $color-black-1000;
        }
        .kare-multiselect-checkbox__ui-inner {
            background-color: $color-black-1000;
        }
    }
}
