$global-transition: all 300ms ease-in-out !default;
$topbar-height: 50px;
$container-max-width: 1280px;
// TODO(rupertrutland): work out the best place to keep this.
$response-content-margin-left: 20px;
$input-height: 30px;
$date-input-height: 36px;

// Available colors:

$color-transparent-overlay: rgba(0, 0, 0, 0.2);

$color-black-1200: #1e282c;
$color-black-500: #92989b;

$color-grey-1200: #6b818c;
$color-grey-700: #e0e0e0;
$color-grey-500: #eeeeee;
$color-grey-300: #f6f5f2;

$color-white-1000: #ffffff;

$color-green-1200: #009445;
$color-green-1000: #00b957;
$color-green-50: #f2fdf3;

$color-red-1200: #a81e24;
$color-red-1000: #d3262e;
$color-red-700: #f69370;
$color-red-500: #f9b299;
$color-red-300: #fbd1c1;
$color-red-50: #fef7f5;

$color-blue-1200: #0287c3;
$color-blue-1000: #03a9f4;
$color-blue-700: #4fc3f7;
$color-blue-500: #81d4f9;
$color-blue-300: #b3e5fb;
$color-blue-50: #f2fafe;

$color-yellow-1200: #fbc02d;
$color-yellow-1000: #fbc02d;
$color-yellow-700: #fde371;
$color-yellow-500: #feeb9a;
$color-yellow-300: #fef3c2;
$color-yellow-50: #fffdf5;

// Semantic color variables:

$color-secondary-dark: $color-blue-1200;
$color-secondary-very-light: $color-blue-50;

$color-error: $color-red-1000;
$color-error-dark: $color-red-1200;
$color-error-light: $color-red-500;

$color-warning: $color-yellow-1000;
$color-warning-dark: $color-yellow-1200;
$color-warning-light: $color-yellow-500;

$color-surface-light: $color-grey-300;

$border-lighter: 1px solid $color-grey-500;
$border-secondary: 1px solid $color-blue-1000;

$border-radius-menu: 2px;
