@import "./answer/index";
@import "./feedback/index";

.message-cx__header {
    display: flex;
    flex-direction: row !important;
    span {
        color: $color-yellow-1000;
        font-weight: 800;
    }
    .message-cx__icon {
        height: auto;
        margin-right: 5px;
    }
    .message-cx_title-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        & > span {
            flex: 1;
        }
        span.message-cx__metric {
            font-weight: 400;
            font-size: 12px;
            color: $color-black-700;
            margin-bottom: 10px;
        }
    }
}
.message-cx__body {
    .message-cx__container {
        margin-bottom: 10px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);
        max-width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.message-cx__footer {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    .message-cx__feedback-all {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        color: $color-black-700;
        height: 20px;
        cursor: pointer;
        width: max-content;
        svg {
            margin-right: 5px;
        }
        span {
            margin: 0;
            line-height: 20px;
            font-weight: 600;
            font-size: 11px;
        }
    }
}
