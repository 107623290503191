$search-icon-size: 20px;
$input-search-gutter: 10px;

.queries-response-search-form {
    padding-top: $spacing-xs;
    .queries-response-search-disambiguation {
        @include kare-button-1-text();
        color: $color-secondary;
        display: flex;
        height: 40px;
        align-items: center;
        span {
            margin-left: 15px;
        }
        svg {
            margin-right: $spacing-xs;
        }
        cursor: pointer;
    }
    h2.response-card-title {
        @include kare-overline-3();
        color: $color-grey-1000;
    }
    .queries-response-search-field-container {
        position: relative;
        svg {
            position: absolute;
            left: $input-search-gutter;
            top: 50%;
            transform: translateY(-50%);
            color: $color-grey-1000;
            @include square($search-icon-size);
        }
    }

    input {
        @include kare-body();
        width: 100%;
        padding-left: #{$search-icon-size + 2 * $input-search-gutter};
        border: 0;
        border-bottom: 1px solid $color-grey-700;
        color: $color-grey-1000;
        height: 40px;
    }
    .c-spinner {
        position: absolute;
        right: 10px;
        bottom: 15px;
        left: auto;
        opacity: 0.5;
    }
}
