.list {
    width: 380px;
    border-right: 1px solid $color-black-300;
    overflow: auto;
    .list-item {
        flex: 0 1 100%;
        padding: 20px;
        height: 75px;
        border-bottom: 1px solid $color-black-300;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-content: center;
        .list-item-details {
            flex: 1;
            color: $color-black-1000;
            .list-item-user {
                display: inline-flex;
                align-items: center;
                .icon {
                    height: 16px;
                    width: 16px;
                    flex: 1;
                    margin-right: 5px;
                }
                .user {
                    flex: 1;
                    font-weight: 600;
                    font-size: 13px;
                }
            }
            .list-item-date {
                color: $color-black-500;
            }
        }
        .list-item-score-badge {
            display: flex;
            justify-content: flex-end;
            width: 30px;

            .nps-circle {
                position: relative;
                background: transparent;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                max-width: 20px;
                max-height: 20px;
                cursor: pointer;
                span {
                    box-sizing: border-box;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    font-size: 10px;
                    font-weight: 400;
                    color: $color-white-1000;
                }

                &.danger {
                    background: $color-red-1000;
                }
                &.warning {
                    background: $color-yellow-1000;
                }
                &.success {
                    background: $color-green-1000;
                }
            }

            .ces-icon,
            .csat-icon {
                svg {
                    border-radius: 50%;
                }
                &.selected.danger-dark svg {
                    background-color: $color-red-1200;
                }
                &.selected.danger svg {
                    background: $color-red-1000;
                }
                &.selected.danger-light svg {
                    background-color: #dc5158;
                }
                &.selected.warning svg {
                    background: $color-yellow-1000;
                }
                &.selected.warning-light svg {
                    background-color: #fccd57;
                }
                &.selected.success svg {
                    background: $color-green-1000;
                }
                &.selected.success-light svg {
                    background-color: #33c779;
                }
            }
        }
        &:last-of-type {
            border-bottom: 1px solid transparent;
        }
        &.selected {
            background-color: #f4fbff;
        }
    }
    .feedback-spinner {
        max-width: 380px;
        margin: 0 !important;
        height: 100%;
    }
}
