@import '/src/app/styles/kss/colors';
@import '/src/app/styles/kss/spacings';

.cardTitle__container {
    font-size: $font-size-m;
    line-height: $spacing-s;
    font-weight: 600;
    color: $color-black-900;
    padding: $spacing-s;
    border-bottom: 1px solid $color-black-100;
    margin: 0;
}
