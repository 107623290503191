.c-tags {
    display: flex;
    flex-wrap: wrap;
}

.c-tag {
    background-color: #EAE9EF;
    padding: 0 6px;
    margin-right: $spacing-xxs;
    margin-bottom: $spacing-xxs;
    font-size: $font-size-xs;
    border-radius: 4px;
    color: $color-black-1000;
}
