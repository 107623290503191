@import './src/app/styles/kss/colors';

.ChatterTable__container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    color: $color-black-1000;
    border-bottom: 1px solid $color-black-100;
    margin: 0 24px 0 36px;
    svg {
        width: 1.5em;
        height: 1.5em;
        color: $color-grey;
    }
    > div {
        display: flex;
        align-items: center;
        svg:first-of-type {
            margin-left: 10px;
        }
        > svg {
            margin-left: 2px;
            margin-right: 10px;
        }
    }
}

.ChatterTable__icons {
    svg {
        cursor: pointer;
    }
}

.ChatterTable__noBottomBorder {
    border-bottom: none;
}
