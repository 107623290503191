@import 'webcrawler-list-item/index';
@import 'new-webcrawler-form/index';

.settings__knowledge-management-webcrawler-list {
    > h1 {
        @include kare-form-label();
    }
    p {
        @include kare-body();
        margin-bottom: $spacing-xs;
    }
    .settings-knowledge-management-add-new {
        margin-bottom: $spacing-xs;
    }
}
