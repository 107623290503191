.ExitsAfterAnswer__container {
    &:after {
        display: block;
        content: '';
        clear: both;
    }
    & > div {
        float: left;
        width: 50%;
    }
}

.ExitsAfterAnswer__chartContainer {
    height: 250px;
}

.ExitsAfterAnswer__legendContainer {
    display: block;
    padding: 10px 8px;
    li {
        align-items: center;
        margin-top: 15px;
    }
    .icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
    }
    width: 100%;
    span {
        display: inline-block;
    }
    strong {
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
    }
    strong,
    small {
        padding-left: 15px;
        display: block;
    }
    small {
        color: #92989b;
        font-size: 14px;
    }
}
